import config from '../../config'
import { store } from '../../redux/store'

const { NEW_API_BASE } = config;
const getUserLayout = async () => {
    const __token: any = store.getState()?.auth?.user?.cipher;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('sessionId', __token);

    const requestOptions = {
        method: 'GET', headers: myHeaders,
    };
    const response = await fetch(NEW_API_BASE + '/user/dashboard', requestOptions)
    const ress: any = await response.json();
    return ress?.data
};
const setUserLayout = (userLayout: any) => {
    const __token: any = store.getState()?.auth?.user?.cipher;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('sessionId', __token);

    const requestOptions = {
        method: 'POST', headers: myHeaders, body: JSON.stringify(userLayout)
    };
    return fetch(NEW_API_BASE + '/user/dashboard', requestOptions)
        .then(response => response.json())
        .catch(error => console.log('Error in getUserLayout:', error));
};

const layoutService = {
    getUserLayout, setUserLayout
};

export default layoutService;