import { useState, useLayoutEffect, Dispatch, SetStateAction, useCallback } from 'react';

/**
 * Custom hook that synchronizes a state variable with localStorage.
 * 
 * @template T - The type of the stored value.
 * @param {string} key - The key under which the value is stored in localStorage.
 * @param {T} initialValue - The initial value to be used if nothing is found in localStorage.
 * @returns {[T, SetValue<T>]} - Returns the stored value and a function to update it.
 */

type SetValue<T> = Dispatch<SetStateAction<T>>;

const useLocalStorage = <T>(key: string, initialValue: T): [T, SetValue<T>] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue: SetValue<T> = useCallback((valueOrFunc) => {
    try {
      setStoredValue((prevStoredValue) => {
        const valueToStore = valueOrFunc instanceof Function ? valueOrFunc(prevStoredValue) : valueOrFunc;
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        return valueToStore;
      });
    } catch (error) {
      console.error(error);
    }
  }, [key]);

  useLayoutEffect(() => {
    let item;
    try {
      item = window.localStorage.getItem(key);
      if(item) setStoredValue(JSON.parse(item));
    } catch (error) {
      if(item) setStoredValue(item as any);
      console.error(error);
    }
  }, [key]);

  return [storedValue, setValue];
};

export default useLocalStorage;
