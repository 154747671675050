import { setLocalReplay, setReplayToSync } from "redux/modules/global";
import { AppDispatch, RootState } from "@/redux/store";
import { cloneDeep, debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, PauseCircleIcon, PlayCircleIcon, StopCircleIcon } from "@heroicons/react/24/solid";
import { ILiveFlags } from "@mahindraformulae/rubicon-hooks";
import config from "config";
import { invertColor } from "common";

const { FLAG_MAP } = config;

const RestartCircleIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" fill="currentColor" className="w-6 h-6">
  <path fillRule="evenodd" d="M 21.962 12.917 C 21.962 18.302 17.597 22.667 12.212 22.667 C 6.827 22.667 2.462 18.302 2.462 12.917 C 2.462 7.532 6.827 3.167 12.212 3.167 C 17.597 3.167 21.962 7.532 21.962 12.917 Z M 12.227 8.087 C 13.127 8.087 14.018 8.121 14.902 8.187 C 16.098 8.274 17.05 9.225 17.136 10.42 C 17.169 10.853 17.193 11.289 17.211 11.727 L 15.96 10.476 C 15.668 10.162 15.146 10.283 15.02 10.693 C 14.958 10.896 15.018 11.118 15.173 11.264 L 17.4 13.49 C 17.618 13.707 17.97 13.707 18.187 13.49 L 20.413 11.264 C 20.705 10.949 20.549 10.438 20.131 10.341 C 19.951 10.3 19.763 10.35 19.626 10.476 L 18.326 11.776 C 18.309 11.294 18.282 10.814 18.246 10.337 C 18.117 8.593 16.731 7.207 14.986 7.078 C 13.149 6.938 11.304 6.938 9.466 7.078 C 7.723 7.207 6.336 8.593 6.207 10.337 C 6.193 10.504 6.182 10.669 6.172 10.835 C 6.14 11.264 6.582 11.565 6.968 11.381 C 7.153 11.292 7.274 11.109 7.283 10.904 C 7.294 10.743 7.305 10.581 7.317 10.42 C 7.404 9.225 8.354 8.274 9.551 8.187 C 10.435 8.12 11.326 8.087 12.227 8.087 Z M 7.052 12.703 C 6.835 12.487 6.483 12.487 6.266 12.703 L 4.039 14.93 C 3.726 15.222 3.846 15.744 4.256 15.87 C 4.46 15.932 4.682 15.872 4.827 15.716 L 6.127 14.417 C 6.144 14.9 6.171 15.379 6.207 15.857 C 6.336 17.6 7.723 18.988 9.466 19.117 C 11.304 19.254 13.149 19.254 14.986 19.117 C 16.731 18.988 18.117 17.6 18.246 15.857 C 18.259 15.691 18.269 15.525 18.28 15.357 C 18.3 14.929 17.849 14.64 17.468 14.837 C 17.298 14.926 17.184 15.098 17.169 15.288 C 17.159 15.45 17.149 15.611 17.136 15.772 C 17.05 16.968 16.098 17.919 14.902 18.007 C 13.121 18.14 11.332 18.14 9.551 18.007 C 8.354 17.919 7.404 16.968 7.317 15.772 C 7.283 15.338 7.26 14.902 7.242 14.466 L 8.493 15.716 C 8.807 16.009 9.32 15.852 9.415 15.435 C 9.456 15.254 9.406 15.066 9.279 14.93 L 7.052 12.703 Z" clipRule="evenodd"></path>
</svg>

const PLAYBACK_SPEED_LIMIT = 5
/**
 * @returns UI Element that allows the user to control the state of the Local/Browser Replay
 */
function Replayer({ isPopUp }: any) {
  const dispatch = useDispatch<AppDispatch>();
  const localReplay = useSelector((state: RootState) => state.global.localReplay);
  const flagMessages = useSelector((state: RootState) => state.global.flagMessages);
  const selectedEvent = useSelector((state: RootState) => state.global.selectedEvent);
  const [allFlags, setAllFlags] = useState<ILiveFlags[]>([]);
  const [currTSLocalState, setCurrTSLocalState] = useState(0);
  const [playbackSpeedLocalState, setPlaybackSpeedLocalState] = useState(1);

  const percentagePlacer = useCallback((tsToBePlaced: number) => {
    if (!(localReplay.startTS && localReplay.endTS)) return 0;
    const base = (localReplay.endTS) - (localReplay.startTS);
    const deltaTS = tsToBePlaced - (localReplay.startTS);
    return ((deltaTS * 100) / base);
  }, [localReplay.startTS, localReplay.endTS])

  const playbackSpeedDispatcher = useCallback((playbackSpeed: number, newCurrTS: number) => {
    let newLocalReplayInfo = cloneDeep(localReplay);
    newLocalReplayInfo.currTS = newCurrTS;
    newLocalReplayInfo.playbackSpeed = playbackSpeed;
    newLocalReplayInfo.deltaTS = undefined;
    dispatch(setLocalReplay(newLocalReplayInfo));
  }, [dispatch, localReplay])

  const isPlayingDispatcher = useCallback((isPlaying = false) => {
    if ((localReplay?.isPlaying ?? false) !== isPlaying) {
      let newLocalReplayInfo = cloneDeep(localReplay);
      newLocalReplayInfo.isPlaying = isPlaying;
      newLocalReplayInfo.currTS += newLocalReplayInfo.deltaTS ?? 0;
      newLocalReplayInfo.deltaTS = undefined;
      newLocalReplayInfo.raceEventId = selectedEvent.raceEventId;
      dispatch(setLocalReplay(newLocalReplayInfo));
      dispatch(setReplayToSync(isPlaying));
    }
  }, [dispatch, localReplay, selectedEvent.raceEventId])

  const currTSDispatcher = useCallback((newCurrTS: number) => {
    let newLocalReplayInfo = cloneDeep(localReplay);
    newLocalReplayInfo.currTS = newCurrTS;
    newLocalReplayInfo.deltaTS = undefined
    dispatch(setLocalReplay(newLocalReplayInfo))
  }, [dispatch, localReplay])

  const debouncedCurrTSDispatcher = useMemo(() => debounce(currTSDispatcher, 500), [currTSDispatcher]);
  const debouncedPlaybackSpeedDispatcher = useMemo(() => debounce(playbackSpeedDispatcher, 500), [playbackSpeedDispatcher]);

  let thumbTS = useMemo(() => (currTSLocalState ?? 0) + (localReplay?.deltaTS ?? 0), [currTSLocalState, localReplay.deltaTS]);
  let isAtStart = useMemo(() => (thumbTS <= (localReplay?.startTS ?? 0) + 1000), [thumbTS, localReplay.startTS]);
  let isAtEnd = useMemo(() => (thumbTS >= (localReplay?.endTS ?? 1000)), [thumbTS, localReplay.endTS]);

  useEffect(() => {
    setAllFlags((init) => {
      if (init.length === 0 || flagMessages.length === 0) {
        return flagMessages;
      } else {
        return init;
      }
    })
  }, [flagMessages])

  useEffect(() => {
    setCurrTSLocalState(localReplay.currTS);
  }, [localReplay.currTS])

  useEffect(() => {
    if (!localReplay.playbackSpeed) {
      setPlaybackSpeedLocalState(1);
    }
  }, [localReplay.playbackSpeed])

  const changeHandler = (e: any) => {
    let newCurrTS = +e.target.value;
    if (newCurrTS === localReplay.startTS) {
      newCurrTS = localReplay.startTS + 1000;
    }
    setCurrTSLocalState(newCurrTS - (localReplay?.deltaTS ?? 0));
    debouncedCurrTSDispatcher(newCurrTS);
  }

  const skipHandler = (sign: -1 | 1) => {
    let newCurrTS = thumbTS + sign * 10000;
    if (newCurrTS >= localReplay.startTS && newCurrTS <= localReplay.endTS) {
      currTSDispatcher(newCurrTS);
      setCurrTSLocalState(newCurrTS);
    }
  }

  const isPlayingClickHandler = () => {
    const newState = !localReplay.isPlaying
    currTSDispatcher(thumbTS);
    setCurrTSLocalState(thumbTS);
    isPlayingDispatcher(newState);
  }

  const restartClickHandler = () => {
    currTSDispatcher(localReplay.startTS + 1000);
    setCurrTSLocalState(localReplay.startTS + 1000);
    isPlayingDispatcher(false);
  }

  const stopClickHandler = () => {
    currTSDispatcher(localReplay.endTS);
    setCurrTSLocalState(localReplay.endTS);
    isPlayingDispatcher(false);
  }

  const playbackSpeedHandler = () => {
    let newSpeed: number;
    if (playbackSpeedLocalState < PLAYBACK_SPEED_LIMIT) {
      newSpeed = playbackSpeedLocalState + 1;
    } else {
      newSpeed = 1;
    }
    setPlaybackSpeedLocalState(newSpeed);
    debouncedPlaybackSpeedDispatcher(newSpeed, thumbTS);
  }

  return <>
    {!isPopUp && (<div className="flex items-start gap-2">
      <div className="grow">
        <div className="relative replayer-container">
          <input className="w-full replayer" type="range" value={thumbTS} min={localReplay?.startTS ?? 0} max={(localReplay?.endTS ?? 0) + 1000} step={1000} onChange={changeHandler} style={{ background: `linear-gradient(to right, #fff ${percentagePlacer(thumbTS)}%, #555 ${percentagePlacer(thumbTS)}%)` }} />
          {allFlags.map(flag => <span className="absolute select-none pointer-events-none rounded-full" key={flag.ts} style={{ color: `${FLAG_MAP?.[flag.type]?.bgColor ?? '#000000'}`, backgroundColor: `${FLAG_MAP?.[flag.type]?.bgColor}`, border: `solid 0.5px ${invertColor(FLAG_MAP?.[flag.type]?.bgColor ?? '#000000', true) ?? 'white'}`, left: `${percentagePlacer(flag.ts)}%` }} title={`${flag.type} S${flag.sector}`}>.</span>)}
          <span id="replayer-tooltip" className="bg-black opacity-0 rounded-sm absolute text-xs whitespace-nowrap select-none pointer-events-none" style={{ top: '0px', left: `${percentagePlacer(thumbTS)}%`, marginLeft: '10px' }}>{moment(thumbTS).local().format("HH:mm:ss")}</span>
        </div>
        <div className="w-full flex justify-between">
          <label className="text-xs">{moment(localReplay?.startTS).local().format("MMM Do 'YY, HH:mm:ss")}</label>
          <label className="text-xs">{moment(thumbTS).local().format("HH:mm:ss")}</label>
          <label className="text-xs text-right">{moment(localReplay?.endTS).local().format("MMM Do 'YY, HH:mm:ss")}</label>
        </div>
      </div>
      <div className="flex gap-1 desktop:gap-2 replayer-actions">
        <button title="Revert 10s" disabled={isAtStart} onClick={() => skipHandler(-1)}><ChevronDoubleLeftIcon className="small-icon" /></button>
        <button title={localReplay.isPlaying && !isAtEnd ? 'Pause' : 'Play'} disabled={isAtEnd} onClick={isPlayingClickHandler}>{localReplay.isPlaying && !isAtEnd ? <PauseCircleIcon className="small-icon" /> : <PlayCircleIcon className="small-icon" />}</button>
        <button title="Skip 10s" disabled={isAtEnd} onClick={() => skipHandler(+1)}><ChevronDoubleRightIcon className="small-icon" /></button>
        <button title="Restart" disabled={isAtStart} onClick={restartClickHandler}>{RestartCircleIcon}</button>
        <button title="Stop" disabled={isAtEnd} onClick={stopClickHandler}><StopCircleIcon className="small-icon" /></button>
        <button title="Playback Speed" onClick={playbackSpeedHandler}><span className="font-bold">{playbackSpeedLocalState}X</span></button>
      </div>
    </div>)}

  </>
}

export default Replayer;