import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import config from "./config";
import { setAttackModeData, setLapData, setLiveFlagData, setLocalReplay, setLocalReplayGlobalData, setLoopData, setPitInOutData, setPowerModeData, setRaceControlData, setSectorData } from "./redux/modules/global";
import { IAttackMode, ILap, ILiveFlags, ILoop, IPitInfo, IPowerMode, IRaceControl, ISector } from "@mahindraformulae/rubicon-hooks";
import LoadingScreen from "./components/LoadingScreen";
import { IResponse } from "./types";

const ONDEMAND_DATA: (keyof IOnDemand)[] = [
  "LiveLap",
  "LiveFlags",
  "RaceControll",
  "LiveSector",
  "LiveLoop",
  "LivePitInfo",
  "AttackMode",
  "PowerMode",
];
const onDemandActions = {
  LiveLap: setLapData,
  LiveFlags: setLiveFlagData,
  RaceControll: setRaceControlData,
  LiveSector: setSectorData,
  LiveLoop: setLoopData,
  LivePitInfo: setPitInOutData,
  AttackMode: setAttackModeData,
  PowerMode: setPowerModeData,
}
interface IOnDemand {
  LiveLap: ILap[];
  LiveFlags: ILiveFlags[];
  RaceControll: IRaceControl[];
  LiveSector: ISector[];
  LiveLoop: ILoop[];
  LivePitInfo: IPitInfo[];
  AttackMode: IAttackMode[];
  PowerMode: IPowerMode[];
}

const DEFAULT_ONDEMAND = ONDEMAND_DATA.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {} as IOnDemand)
function timeSeriesSlicer(index: number, obj: IOnDemand, key: keyof IOnDemand) {
  const arr = obj[key];
  if (index !== -1) {
    return arr.slice(0, index)
  } else {
    return arr
  }
}
function subsequenceTimeFilter(currIndex: number, filterTS: number, array: { ts: number }[],) {
  let nextIndex = currIndex;
  for (let i = currIndex; i < array.length && array[i].ts <= filterTS; i++) {
    nextIndex = i + 1;
  }
  return nextIndex;
}

export default function LocalReplay(props: any) {
  const dispatch = useDispatch<AppDispatch>();
  const selectedEvent = useSelector((state: RootState) => state.global.selectedEvent);
  const [onDemandData, setOnDemandData] = useState<IOnDemand>({ ...DEFAULT_ONDEMAND });
  const localReplay = useSelector((state: RootState) => state.global.localReplay);
  const replayToSync = useSelector((state: RootState) => state.global.replayToSync);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOnDemandData = async (raceEventId: number, isCurrent: boolean) => {
      const postBody = ONDEMAND_DATA;

      let _api = `${config.NEW_API_BASE}/on-demand/${raceEventId}`;
      if (isCurrent) {
        _api = `${config.NEW_API_BASE}/on-demand/live`;
      }
      try {
        await fetch(`${_api}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postBody),
        }).then(resp => resp.json())
          .then(async ({ success, data: { LiveLap = [], LiveFlags = [], RaceControll = [], LiveSector = [], LiveLoop = [], LivePitInfo = [], AttackMode = [], PowerMode = [] } }: IResponse<IOnDemand>) => {
            if (success) {
              LiveLap.sort((a, b) => a.ts - b.ts);

              const detectedIDs = new Set<number | undefined>();
              LiveFlags = LiveFlags.filter(({ ts }) => {
                if (detectedIDs.has(ts)) {
                  return false;
                }
                detectedIDs.add(ts)
                return true
              });
              detectedIDs.clear() // duplication removal

              LiveFlags.sort((a, b) => a.ts - b.ts);

              RaceControll = RaceControll.filter(({ id }) => {
                if (detectedIDs.has(id)) {
                  return false;
                }
                detectedIDs.add(id)
                return true
              });
              detectedIDs.clear() // duplication removal

              RaceControll.sort((a, b) => a.ts - b.ts);

              LiveSector.sort((a, b) => a.ts - b.ts);
              LiveLoop.sort((a, b) => a.ts - b.ts);
              LivePitInfo.sort((a, b) => a.ts - b.ts);
              AttackMode.sort((a, b) => a.ts - b.ts);
              PowerMode.sort((a, b) => a.ts - b.ts);

              setOnDemandData({ LiveLap, LiveFlags, RaceControll, LiveSector, LiveLoop, LivePitInfo, AttackMode, PowerMode });
            }
          }).catch((err) => {
            console.log("err", err);
          });
      } catch (e) {
        console.log(e);
      }
    };
    if (selectedEvent) {
      const { raceEventId, isCurrent } = selectedEvent;
      if (raceEventId) {
        fetchOnDemandData(raceEventId, !!isCurrent).then();
      }
    }
  }, [dispatch, selectedEvent])

  useEffect(() => {
    let {
      LiveFlags = [],
      LiveLoop = [],
    } = onDemandData;

    if (LiveFlags.length !== 0 || LiveLoop.length !== 0) {
      let endTS = (LiveLoop?.[LiveLoop.length - 1]?.ts ?? LiveFlags?.[LiveFlags.length - 1]?.ts) + 30 * 1000;
      let startTS = LiveFlags?.[0]?.ts ?? LiveLoop?.[LiveLoop.length - 1]?.ts;
      let currTS = endTS;
      let playbackSpeed = 1;

      dispatch(setLocalReplay({ endTS, startTS, currTS, playbackSpeed }))
    }
  }, [onDemandData, dispatch])

  const currIndices = useMemo(() => {
    if (localReplay.currTS) {
      return ONDEMAND_DATA.reduce((currIndices_, label) => ({
        ...currIndices_,
        [label]: onDemandData[label].findIndex((o: { ts: number }) => o.ts >= localReplay.currTS)
      }), {} as Record<keyof IOnDemand, number>)
    }
  }, [onDemandData, localReplay.currTS])

  const timeFilteredDispatch = useCallback((deltaTS: number, timer: NodeJS.Timeout | undefined = undefined) => {
    if (currIndices) {
      if (localReplay.endTS <= localReplay.currTS + deltaTS) {
        clearInterval(timer);
        dispatch(setLocalReplay({ ...localReplay, currTS: localReplay.endTS, deltaTS: undefined }));
      }
      dispatch(setLocalReplay({ deltaTS }));
      Object.entries(onDemandData).forEach(([key, timeSeriesArray]) => {
        const currIndex = currIndices[key as keyof IOnDemand]
        const action = onDemandActions[key as keyof IOnDemand]
        if (currIndex === -1) {
          dispatch(action(timeSeriesArray))
        } else {
          let nextIndex = subsequenceTimeFilter(currIndex, deltaTS + localReplay.currTS, timeSeriesArray);
          let dispatchArray = timeSeriesArray.slice(currIndices[key as keyof IOnDemand], nextIndex);
          if (dispatchArray.length === 0) return;
          dispatch(action(dispatchArray))
        }
      })
    }
  }, [dispatch, onDemandData, localReplay.currTS, localReplay.endTS, subsequenceTimeFilter, currIndices])

  useEffect(() => {
    if (currIndices) {

      const filteredOnDemandData = { ...onDemandData }

      ONDEMAND_DATA.forEach((key) => {
        filteredOnDemandData[key] = timeSeriesSlicer(currIndices[key], onDemandData, key) as any;
      })

      dispatch(setLocalReplayGlobalData(filteredOnDemandData));
      setIsLoading(false);

      return () => {
        setIsLoading(true);
      }
    } else {
      let timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000)
      return () => {
        setIsLoading(true);
        clearTimeout(timer);
      }
    }
  }, [dispatch, onDemandData, currIndices])

  useEffect(() => {
    let deltaTS = 0;
    let timer: NodeJS.Timeout;

    if (localReplay.isPlaying && replayToSync) {
      timer = setInterval(() => {
        deltaTS += localReplay.playbackSpeed * 1000;
        timeFilteredDispatch(deltaTS, timer);
      }, 1000);
    }

    return () => {
      clearInterval(timer)
    }
  }, [localReplay.isPlaying, replayToSync, localReplay.playbackSpeed, timeFilteredDispatch])

  useEffect(() => {
    if (localReplay.deltaTS && !replayToSync) {
      timeFilteredDispatch(localReplay.deltaTS)
    }
  }, [localReplay.deltaTS, replayToSync, timeFilteredDispatch])

  return <>
    {props.children}
    {isLoading && <LoadingScreen />}
  </>
}