import {useEffect,} from 'react';
import {useDispatch,} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {AppDispatch} from 'redux/store';
import {logout} from 'redux/modules/auth';
import {logUserOut} from 'redux/modules/global';
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';

// const MySwal = withReactContent(Swal);

function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {

    const getUserConfirmation = async () => {
      // return MySwal.fire({
      //     title: 'Are you sure?',
      //     text: 'You want to logout!',
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonColor: '#3085d6',
      //     cancelButtonColor: '#d33',
      //     confirmButtonText: 'Yes, log me out!'
      // });
      return ({isConfirmed: true});
    };

    getUserConfirmation().then(({isConfirmed}: any) => {
      if (isConfirmed) {
        dispatch(logUserOut())
        dispatch(logout()).then(() => {
          navigate('/login');
        });
      } else {
        // navigate(-1, {replace: true});
      }
    });
  }, [dispatch, navigate]);

  return null;
}

export default Logout;