import config from "config";
import { RootState } from "redux/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


const { FLAG_MAP } = config;

function FlagIndicator() {
  const [flagInfo, setFlagInfo] = useState<string | null>(null);
  const flagMessages = useSelector((state: RootState) => state.global.flagMessages);

  useEffect(() => {
    let sector0Flags = flagMessages.filter(d => d.sector === 0);
    if (sector0Flags.length > 0) {
      setFlagInfo(sector0Flags[sector0Flags.length - 1]?.type);
    } else {
      setFlagInfo(null)
    }
  }, [flagMessages]);

  return <>
    <div className='text-center w-fit desktop:min-w-28'>
      {flagInfo && <span className="border-2 border-slate-300 rounded-full w-full inline-block font-bold px-2" style={{ backgroundColor: FLAG_MAP[flagInfo]?.bgColor, color: FLAG_MAP[flagInfo]?.color, }}>
        {flagInfo}
      </span>}
    </div>
  </>
}

export default FlagIndicator;