import { Fragment, memo, useEffect, useRef, useState, useMemo, useCallback } from "react";
import * as d3 from "d3";
import html2canvas from "html2canvas";
import { Scrollbars } from "rc-scrollbars";
import { sortBy } from "lodash";

import { AXIS_SCALES, SHAPE_WIDTH_TYPES, THEMES, ALEA_CHART_TYPES } from "./aleaConstants";
import { tooltipHandlers } from "./aleaEventHandlers/tooltipHandler";
import { brushZoomHandler, panHandler } from "./aleaEventHandlers/zoomHandlers";
import { createTickValues } from "./aleaUtils";
import icons from "./aleaAssets/icons";
import Loader from "./aleaComponents/Loader";

import "./AleaCharts.css";

const MODES = ["PAN", "ZOOM"];
const margin = { top: 20, right: 30, bottom: 30, left: 40 };
function AleaCharts(props) {
  //#region state and varialbles
  const { data, title, marginY, types, xyConfig, width, height, legendWidth, theme, shapes, isLegends, marginX, isJitter, jitter, isConnected } = props;

  const LEGEND_WIDTH = useMemo(() => legendWidth || 180, [legendWidth])
  const HEIGHT = useMemo(() => height || 500, [height])
  const CHART_TITLE = useMemo(() => title || "My Custom Chart Title", [title])
  const MARGINY = useMemo(() => marginY || 5, [marginY])
  const MARGINX = useMemo(() => marginX || 5, [marginX])
  const CHART_TYPES = useMemo(() => types, [types])
  const PADDING = useMemo(() => 20, [])
  const X_AXIS_SCALE = useMemo(() => xyConfig?.xScale || AXIS_SCALES.BAND, [xyConfig?.xScale])
  const Y_AXIS_SCALE = useMemo(() => xyConfig?.yScale || AXIS_SCALES.LINEAR, [xyConfig?.yScale])
  const X_TICKS = useMemo(() => xyConfig?.xTicks || null, [xyConfig?.xTicks])
  const X_TICK_LINES = useMemo(() => xyConfig?.xTickLine || false, [xyConfig?.xTickLine])
  const Y_TICK_LINES = useMemo(() => xyConfig?.yTickLine || false, [xyConfig?.yTickLine])
  const IS_RIGHT_YAXIS = useMemo(() => xyConfig?.isRightYaxis || false, [xyConfig?.isRightYaxis])
  const Y_MAX_VALUE = useMemo(() => xyConfig?.yMaxValue || null, [xyConfig?.yMaxValue])
  const Y_MIN_VALUE = useMemo(() => xyConfig?.yMinValue || null, [xyConfig?.yMinValue])
  const X_TICK_VALUES = useMemo(() => xyConfig?.xTickValues || null, [xyConfig?.xTickValues])
  const Y_SCALE_REVERSE = useMemo(() => xyConfig?.yScaleReverse || false, [xyConfig?.yScaleReverse])
  const Y_FORMAT_AFTER = useMemo(() => xyConfig?.yFormatAfter || null, [xyConfig?.yFormatAfter])
  const Y_TICKS = useMemo(() => xyConfig?.yTicks || null, [xyConfig?.yTicks])
  const IS_LEGENDS = useMemo(() => isLegends === false ? false : true, [isLegends])
  const IS_JITTER = useMemo(() => isJitter || false, [isJitter])
  const JITTER = useMemo(() => jitter >= 0 || jitter <= 100 ? 100 - jitter : 100, [jitter])

  const THEME = theme || (window.matchMedia("(prefers-color-scheme: dark)").matches ? THEMES.DARK : THEMES.LIGHT);
  const myId = useMemo(() => Date.now().toString(), [])
  const chartRef = useRef();
  const chartContainerRef = useRef();
  const chartMainRef = useRef(null);
  const [activeLables, setActiveLables] = useState([]);
  const [labels, setLabels] = useState([]);
  const [clickTimeout, setClickTimeout] = useState(0);
  const [activeChartTypes, setActiveChartTypes] = useState(CHART_TYPES);
  const [mode, setMode] = useState(null);
  const [xyScaling, setXYScaling] = useState(null);
  const [dataXY, setDataXY] = useState([]);
  const [aleaTheme, setAleaTheme] = useState(THEMES.DARK);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [WIDTH, SET_WIDTH] = useState((IS_LEGENDS ? 0.95 * (width || 800) : (width || 800)));
  const [innerWidth, setInnerWidth] = useState(WIDTH - margin.left - margin.right);
  const [innerHeight] = useState(HEIGHT - margin.top - margin.bottom);
  const [isHorizontLock, setIsHorizontLock] = useState(false);
  const [isVerticalLock, setIsVerticalLock] = useState(() => {
    if (xyConfig.isVerticalLockByDefault === false) {
      return false
    }
    return true
  });
  //#endregion
  //#region reset=================================
  const resetChart = useCallback((emitEvent = true) => {
    if (emitEvent) {
      const scalingEvent = new CustomEvent("aleachartScalingChange", { detail: { sharedScaling: null, id: myId } });
      window.dispatchEvent(scalingEvent);
    }
    setXYScaling(null);
    setMode(null);
    setLabels(labels_ => {
      setActiveLables(labels_.map(({ name }) => name));
      return labels_;
    })
    setActiveChartTypes(CHART_TYPES);
  }, [CHART_TYPES]);
  //#endregion
  //#region handle layout height width
  useEffect(() => {
    const conWidth = chartMainRef.current.clientWidth;
    setInnerWidth((IS_LEGENDS ? (0.95 * (conWidth || 800) - margin.left - margin.right) : (conWidth - margin.left - margin.right)));
    SET_WIDTH((IS_LEGENDS ? 0.95 * (conWidth || 800) : (conWidth || 800)));

    // const onChartResize = () => {
    //   const conCurrWidth = chartMainRef.current.clientWidth;
    //   setInnerWidth(conCurrWidth - margin.left - margin.right);
    //   SET_WIDTH(conCurrWidth || 800 - (isLegends ? LEGEND_WIDTH : 0));
    // };
    // window.addEventListener("resize", onChartResize);
  }, []);
  //#endregion

  //#region handle system theme
  useEffect(() => {
    const fn = ({ matches }) => {
      if (matches) {
        setAleaTheme(THEMES.DARK);
      } else {
        setAleaTheme(THEMES.LIGHT);
      }
    };

    if (!theme) {
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", fn);

      return () => {
        window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", fn);
      };
    }
  }, [theme]);
  //#endregion

  //#region handle legend data
  useEffect(() => {
    let allLables = [];
    data.forEach(({ name, color, types, isDottedLine }) => {
      if (allLables.findIndex((l) => l.name === name) === -1) {
        allLables.push({ name, color, types, isDottedLine });
      }
    });
    allLables = sortBy(allLables, [(o) => o.name]);
    if (allLables.length) {
      setLabels((init) => {
        if (init.length !== allLables.length) {
          setActiveLables(allLables.map(({ name }) => name));
          return allLables;
        }
        return init;
      });
    }
  }, [data]);
  //#endregion

  //#region create SVG elements
  useEffect(() => {
    const containerRef = chartRef.current;
    // console.log("w", WIDTH, HEIGHT);
    const svg = d3
      .select(containerRef)
      .style("position", "relative")
      // .style("width", `${WIDTH}px`)
      // .style("height", `${HEIGHT}px`)
      .append("svg")
      .attr("id", "alea-chart-svg")
      .attr("viewBox", `0 0 ${WIDTH} ${HEIGHT}`);

    svg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", 13)
      .attr("text-anchor", "middle")
      .text(CHART_TITLE)
      .attr('font-size', '15px');

    const chartArea = svg.append("g").attr("id", "alea-chart-main-g").attr("transform", `translate(${margin.left}, ${margin.top})`);
    chartArea.append("g").attr("id", "alea-y-axis");
    // chartArea.append("g").attr("id", "alea-x-axis");
    // chartArea.append("g").attr("id", "alea-y2-axis");

    chartArea
      .append("defs")
      .append("svg:clipPath")
      .attr("id", "clip")
      .append("svg:rect")
      .attr("width", WIDTH)
      .attr("height", innerHeight)
      .attr("x", 0)
      .attr("y", 0);

    chartArea
      .append("defs")
      .append("svg:clipPath")
      .attr("id", "clip-x")
      .append("svg:rect")
      .attr("width", WIDTH)
      .attr("height", innerHeight + margin.bottom)
      .attr("x", 0)
      .attr("y", 0);

    chartArea
      .append("defs")
      .append("svg:clipPath")
      .attr("id", "clip-y2")
      .append("svg:rect")
      .attr("width", innerWidth + margin.right)
      .attr("height", innerHeight + margin.bottom)
      .attr("x", 0)
      .attr("y", 0);

    chartArea.append("g").attr("id", "alea-cliping-x-area").attr("clip-path", "url(#clip-x)").append("g").attr("id", "alea-x-axis");
    chartArea.append("g").attr("id", "alea-cliping-y2-area").attr("clip-path", "url(#clip-y2)").append("g").attr("id", "alea-y2-axis");
    const chartingArea = chartArea.append("g").attr("id", "alea-cliping-area").attr("clip-path", "url(#clip)");
    chartingArea.append("g").attr("id", "alea-chart-shape-rect");
    chartingArea.append("g").attr("id", "alea-chart-shape-line");
    chartingArea.append("g").attr("id", "alea-chart-line");
    chartingArea.append("g").attr("id", "alea-chart-rect");
    chartingArea.append("g").attr("id", "alea-chart-dot");
    chartingArea.append("g").attr("id", "alea-chart-text-layout-circle");
    chartingArea.append("g").attr("id", "alea-chart-text-layout-rect");
    chartingArea.append("g").attr("id", "alea-chart-text");
    chartingArea.append("g").attr("id", "alea-chart-brush");

    return () => {
      svg.remove();
    };
  }, [WIDTH, HEIGHT]);
  //#endregion

  //#region panning
  const panning = useMemo(() => {
    let panning = panHandler(setXYScaling);
    return panning;
  }, []);
  //#endregion
  //#region handle zoom and pan
  useEffect(() => {
    const chartRefCurrent = chartRef.current;
    const context = d3.select(chartRefCurrent).select("svg#alea-chart-svg");
    if (mode === "PAN") {
      context.style("cursor", "move");
      context.call(panning);
      context.on("dblclick.zoom", null);
      return () => {
        context.style("cursor", "auto");
        context.call(d3.zoom().on("zoom", null));
      };
    } else if (mode === "ZOOM") {
      // Apply the brush to the SVG
      let brushContainer = context.append("g").attr("transform", `translate(${margin.left},${margin.top})`).attr("class", "brush").attr("opacity", 0);

      let brush = brushZoomHandler(setXYScaling, brushContainer, innerWidth, innerHeight, context, aleaTheme, myId);
      brushContainer.call(brush);

      return () => {
        brushContainer.remove();
      };
    }
  }, [mode, panning, innerWidth, aleaTheme, myId]);
  //#endregion

  //#region handle scaling at initiation and reset
  useEffect(() => {
    if (data.length === 0 || activeLables.length === 0) return;
    const activeData = data.filter((d) => activeLables.includes(d.name));
    const allData = activeData.reduce((prev, curr) => {
      return prev.concat(curr.values);
    }, []);

    setDataXY(allData);
    const minY = Y_MIN_VALUE || d3.min(allData, (d) => d.y);
    const maxY = Y_MAX_VALUE || d3.max(allData, (d) => d.y);
    const minX = d3.min(allData, (d) => d.x);
    const maxX = d3.max(allData, (d) => d.x);
    const gap = ((maxY - minY) / 100) * MARGINY;
    const gapX = ((maxX - minX) / 100) * MARGINX;

    setXYScaling((init) => {
      if (!init) {
        let xScale, yScale, y2Scale;

        //#region scale x
        if (X_AXIS_SCALE === AXIS_SCALES.BAND) {

          xScale = d3.scaleBand().range([0, innerWidth]);
        } else if (X_AXIS_SCALE === AXIS_SCALES.DATETIME) {
          const timeDifference = new Date(maxX).getTime() - new Date(minX).getTime();
          const tenPercent = (timeDifference / 100) * PADDING;
          const minTime = new Date(new Date(minX).getTime() - tenPercent / 3);
          const maxTime = new Date(new Date(maxX).getTime() + tenPercent);

          xScale = d3.scaleTime().domain([minTime, maxTime]).range([0, WIDTH]);
        } else if (X_AXIS_SCALE === AXIS_SCALES.LINEAR) {
          xScale = d3
            .scaleLinear()
            .domain([Number(minX) - gapX, Number(maxX) + gapX])
            .range([0, innerWidth]);
        }
        // const xScale = d3.scaleLinear().domain([dataX[0], dataX[dataX.length - 1] + 5]).range([0, width]);
        //#endregion

        //#region scale y
        if (Y_AXIS_SCALE === AXIS_SCALES.BAND) {
          let dataY = [...new Set(allData.map((d) => d.y).sort((a, b) => a - b))];
          yScale = d3
            .scaleBand()
            .domain(dataY)
            .range(Y_SCALE_REVERSE ? [0, innerHeight] : [innerHeight, 0]);
        } else {
          if (maxY || minY) {
            const domain = Y_SCALE_REVERSE ? [maxY + gap, minY - gap] : [minY - gap, maxY + gap];
            yScale = d3.scaleLinear().domain(domain).range([innerHeight, 0]);
          } else {
            return undefined;
          }
        }
        //#endregion

        //#region scale y right
        if (IS_RIGHT_YAXIS) {
          const minY2 = d3.min(allData, (d) => d.y2);
          const maxY2 = d3.max(allData, (d) => d.y2);
          const gap2 = ((maxY2 - minY2) / 100) * MARGINY;
          y2Scale = d3
            .scaleLinear()
            .domain([minY2 - gap2, maxY2 + gap2])
            .range([innerHeight, 0]);
        }
        // }
        //#endregion
        return { xScale, yScale, y2Scale };
      } else {
        let { xScale, yScale, y2Scale } = init;
        let newXScale = xScale.copy();
        let newYScale = yScale.copy();
        let newY2Scale = y2Scale?.copy();
        if (!isHorizontLock) {
          if (X_AXIS_SCALE === AXIS_SCALES.BAND) {
            let dataX = [...new Set(allData.map((d) => d.x).sort((a, b) => a - b))];
            newXScale = xScale.copy().domain(dataX);
          } else if (X_AXIS_SCALE === AXIS_SCALES.DATETIME) {
            const timeDifference = new Date(maxX).getTime() - new Date(minX).getTime();
            const tenPercent = (timeDifference / 100) * PADDING;
            const minTime = new Date(new Date(minX).getTime() - tenPercent / 3);
            const maxTime = new Date(new Date(maxX).getTime() + tenPercent);
            newXScale = xScale.copy().domain([minTime, maxTime]);
          } else if (X_AXIS_SCALE === AXIS_SCALES.LINEAR) {
            newXScale = xScale.copy().domain([Number(minX) - gapX, Number(maxX) + gapX]);
          }
        }

        if (!isVerticalLock) {
          if (Y_AXIS_SCALE === AXIS_SCALES.BAND) {
            let dataY = [...new Set(allData.map((d) => d.y).sort((a, b) => a - b))];
            newYScale = yScale.copy().domain(dataY);
          } else {
            newYScale = yScale.copy().domain([minY - gap, maxY + gap]);
          }
        }

        return { xScale: newXScale, yScale: newYScale, y2Scale: newY2Scale };
      }
    });
  }, [data, activeLables, innerWidth, WIDTH, isHorizontLock, isVerticalLock]);
  //#endregion

  const scalingChangeHandler = useCallback(function ({ detail: { sharedScaling, id } }, isConnected) {
    if (id !== myId) {
      if (sharedScaling) {
        let { xScale: xScaleShared, yScale: yScaleShared, y2Scale: y2ScaleShared } = sharedScaling;
        setXYScaling(init => {
          if (init) {
            let { xScale, yScale, y2Scale } = init;
            let newXScale = isConnected.x ? xScale.copy().range(xScaleShared.range()) : xScale;
            let newYScale = isConnected.y ? yScale.copy().domain(yScaleShared.domain()) : yScale;
            let newY2Scale;
            if (y2Scale && y2ScaleShared) {
              newY2Scale = isConnected.y2 ? y2Scale.copy().domain(y2ScaleShared.domain()) : y2Scale;
            }
            return { xScale: newXScale, yScale: newYScale, y2Scale: newY2Scale };
          }
          return init;
        })
      } else {
        resetChart(false);
      }
    }
  }, [myId, resetChart, setXYScaling])

  useEffect(() => {
    if (isConnected && (isConnected.x || isConnected.y || isConnected.y2)) {
      window.addEventListener("aleachartScalingChange", (e) => scalingChangeHandler(e, isConnected))
      return () => {
        window.removeEventListener("aleachartScalingChange", (e) => scalingChangeHandler(e, isConnected))
      }
    }
  }, [isConnected, scalingChangeHandler])

  //#region handle chart
  useEffect(() => {
    // check if scaling has been generated
    if (!xyScaling) return;
    // Create an SVG element
    const activeData = data.filter((d) => activeLables.includes(d.name));

    //#region initialize SVG
    const chartRefCurrent = chartRef.current;
    // const svg = d3.select(chartRefCurrent).select("svg").append("g").attr("transform", `translate(${margin.left},${margin.top})`);
    const svgOuter = d3.select(chartRef.current).select("svg#alea-chart-svg").select("g#alea-chart-main-g");
    const svg = svgOuter.select("g#alea-cliping-area");
    // const chartingArea = svg.select("g#alea-cliping-area");
    // const svg = svgViewBox.select("g#rc-chart-container-g");
    //#endregion

    //#region tooltiphandler
    let tooltip;
    // check for existence of labels in data
    let containsLabels = activeData.map((d) => d.values).some((d) => d.some((d) => d.label));
    if (containsLabels) {
      tooltip = tooltipHandlers(d3.select(chartRefCurrent), {
        backGroundColor: "red",
        textColor: "white",
      });
    }
    //#endregion

    // get scaling from the state object
    const { xScale, yScale } = xyScaling;
    if (X_AXIS_SCALE === AXIS_SCALES.BAND) {
      let dataX = [...new Set(dataXY.map((d) => d.x))];
      if (typeof dataX[0] === 'number') {
        dataX.sort((a, b) => a - b)
      }
      xScale.domain(dataX)
    }
    //#region  render Charting area group that contains all data-binded elements
    // let chartingArea = svg;
    //#endregion

    //#region append x and y axes to SVG
    //#region create x scale
    const xAxis = d3.axisBottom(xScale).tickSize(0);
    if (X_TICKS) {
      if (X_AXIS_SCALE === AXIS_SCALES.LINEAR) {
        xAxis.ticks(X_TICKS);
      } else {
        const dataX = [...new Set(dataXY.map((d) => d.x))];
        const tickValues = createTickValues(dataX, X_TICKS);
        if (tickValues.length > 0) {
          xAxis.tickValues(tickValues);
        }
      }
    }

    if (X_TICK_VALUES) {
      xAxis.tickFormat((d, i) => X_TICK_VALUES[i]);
    }

    const xAxisSvg = svgOuter.select("g#alea-cliping-x-area").select("g#alea-x-axis").attr("transform", `translate(0, ${innerHeight})`).call(xAxis);

    if (X_TICK_LINES) {
      xAxisSvg.call((g) => g.selectAll(".tick line").attr("y2", -innerHeight).attr("stroke-width", 1).attr("stroke-opacity", 0.2));
    }
    if (xyConfig?.tickLabelFontSize?.x) {
      xAxisSvg.call((g) => g.selectAll(".tick text").attr("font-size", xyConfig?.tickLabelFontSize?.x));
    }

    let xScaleCal;
    if (X_AXIS_SCALE === AXIS_SCALES.BAND) {
      xScaleCal = (x) =>
        (xScale(x) ?? 0) +
        (IS_JITTER || JITTER
          ? (xScale.bandwidth() / 100) * (JITTER / 2) + Math.floor(Math.random() * (xScale.bandwidth() - Math.round((xScale.bandwidth() / 100) * JITTER)))
          : xScale.bandwidth() / 2);
    } else if (X_AXIS_SCALE === AXIS_SCALES.DATETIME) {
      xScaleCal = xScale.copy();
    } else if (X_AXIS_SCALE === AXIS_SCALES.LINEAR) {
      xScaleCal = xScale.copy();
    }
    //#endregion

    //#region create y scale
    const yAxis = d3.axisLeft(yScale).tickSize(0);
    if (Y_TICKS) {
      yAxis.ticks(Y_TICKS);
    }

    if (Y_FORMAT_AFTER) {
      yAxis.tickFormat((d) => `${d}${Y_FORMAT_AFTER}`);
    }

    const yAxisSvg = svgOuter.select("g#alea-y-axis").call(yAxis);
    if (Y_TICK_LINES) {
      yAxisSvg.call((g) => g.selectAll(".tick line").attr("x2", innerWidth).attr("stroke-width", 1).attr("stroke-opacity", 0.2));
    }

    if (xyConfig.majorYGridLines) {
      // yAxisSvg.call((g) => g.selectAll(".tick:nth-child(5n) line").attr("stroke-width", 5))
      yAxisSvg.call((g) => g.selectAll(".tick").each(function (d) {
        if (d === 0) {
          d3.select(this).select("line").attr("stroke-width", 5).attr("stroke-opacity", 0.7)
        } else if (d % 10 === 0) {
          d3.select(this).select("line").attr("stroke-width", 5)
        }
      }))
    }

    if (xyConfig?.tickLabelFontSize?.y) {
      yAxisSvg.call((g) => g.selectAll(".tick text").attr("font-size", xyConfig?.tickLabelFontSize?.y));
    }
    if (xyConfig?.duplicateYAxis) {
      const yAxisDuplicate = d3.axisRight(yScale).tickSize(0);
      if (Y_TICKS) {
        yAxisDuplicate.ticks(Y_TICKS);
      }

      if (Y_FORMAT_AFTER) {
        yAxisDuplicate.tickFormat((d) => `${d}${Y_FORMAT_AFTER}`);
      }

      const yAxisSvg = svgOuter
        .select("g#alea-cliping-y2-area")
        .select("g#alea-y2-axis")
        .attr("class", "y-axis")
        .attr("transform", `translate(${innerWidth},0)`).call(yAxisDuplicate);
      if (Y_TICK_LINES) {
        yAxisSvg.call((g) => g.selectAll(".tick line").attr("x2", innerWidth).attr("stroke-width", 1).attr("stroke-opacity", 0.2));
      }

      if (xyConfig?.tickLabelFontSize?.y) {
        yAxisSvg.call((g) => g.selectAll(".tick text").attr("font-size", xyConfig?.tickLabelFontSize?.y));
      }
    }
    //#endregion

    //#region create y right scale
    const { y2Scale } = xyScaling;
    if (IS_RIGHT_YAXIS) {
      svgOuter
        .select("g#alea-cliping-y2-area")
        .select("g#alea-y2-axis")
        .attr("class", "y-axis")
        .attr("transform", `translate(${innerWidth},0)`)
        .call(d3.axisRight(y2Scale).tickSize(0));
    }
    //#endregion

    const getY = (d) => {
      if (IS_RIGHT_YAXIS && !isNaN(d.y2)) {
        return y2Scale(d.y2);
      } else if (Y_AXIS_SCALE === AXIS_SCALES.BAND) {
        return yScale(d.y) + yScale.bandwidth() / 2;
      } else {
        return yScale(d.y);
      }
    };
    //#endregion

    //#region render shapes to the chart
    if (shapes?.length > 0) {
      //#region rect shape
      svg
        .select("g#alea-chart-shape-rect")
        .selectAll("rect")
        .data(shapes.filter((shape) => shape.type === ALEA_CHART_TYPES.RECT))
        .join("rect")
        .attr("class", "shape-rectangle")
        .attr("x", (shape) => xScaleCal(shape.x0))
        .attr("y", (shape) => shape.y0 * innerHeight)
        .attr("width", (shape) => xScaleCal(shape.x1) - xScaleCal(shape.x0))
        .attr("height", (shape) => (shape.y1 - shape.y0) * innerHeight)
        .style("fill", (shape) => shape.fillcolor)
        .style("stroke-width", 0)
        .style("opacity", (shape) => shape.opacity)
        .on("mouseover", tooltip?.mouseover)
        .on("mousemove", (e, d) => tooltip?.mousemove(e, d.label))
        .on("mouseout", tooltip?.mouseleave);
      //#endregion

      //#region line shape
      const lineShapes = shapes
        .filter((shape) => shape.type === ALEA_CHART_TYPES.LINE)
        .map((d) => {
          const { xType, yType, path, fillcolor, size, opacity, isDottedLine } = d;
          return path.map((pathItem) => ({
            ...pathItem,
            xType,
            yType,
            fillcolor,
            size,
            opacity,
            isDottedLine,
          }));
        });

      svg
        .select("g#alea-chart-shape-line")
        .selectAll("path")
        .data(lineShapes)
        .join("path")
        .attr("class", "shape-line")
        .attr("fill", "none")
        .attr(
          "d",
          d3
            .line()
            .x((d) => (d.xType === SHAPE_WIDTH_TYPES.WIDTH ? d.x * innerWidth : xScaleCal(d.x)))
            .y((d) => (d.yType === SHAPE_WIDTH_TYPES.WIDTH ? d.y * innerHeight : getY(d)))
        )
        .attr("stroke", (shape) => shape?.[0]?.fillcolor ?? (aleaTheme === THEMES.DARK ? "white" : "black"))
        .attr("stroke-width", (shape) => shape?.[0]?.size)
        .style("opacity", (shape) => shape?.[0]?.opacity)
        .attr("stroke-dasharray", (shape) => (shape?.[0]?.isDottedLine ? "3,3" : "0"));
      //#endregion
    }
    //#endregion

    //#region render data
    //#region data for line, dot and rect
    const filterVals = [null, undefined];

    const dataLine = activeData
      .filter((dataItem) => dataItem.type !== ALEA_CHART_TYPES.TEXT && (dataItem.types === undefined || dataItem.types?.includes(ALEA_CHART_TYPES.LINE)))
      .map((lineDataItem) => {
        const { values, color, name, size, isDottedLine, opacity, type, types } = lineDataItem;
        return values.filter(v => !filterVals.includes(v.y) || !filterVals.includes(v.y2)).map((v) => ({ ...v, color, name, size, isDottedLine, opacity, type, types }));
      });
    const dataDot = activeData
      .filter((dataItem) => dataItem.type !== ALEA_CHART_TYPES.TEXT)
      .map((lineDataItem) => {
        const { values, color, name, size, isDottedLine, opacity, type, types, radius } = lineDataItem;
        let opc = 0;
        if (lineDataItem.types === undefined || lineDataItem.types?.includes(ALEA_CHART_TYPES.DOT)) {
          opc = opacity;
        }
        return values.filter(v => !filterVals.includes(v.y) || !filterVals.includes(v.y2)).map((v) => ({ ...v, color, name, size, isDottedLine, opacity: opc, type, types, radius }));
      });
    const dataRect = activeData
      .filter((dataItem) => dataItem.type !== ALEA_CHART_TYPES.TEXT && (dataItem.types === undefined || dataItem.types?.includes(ALEA_CHART_TYPES.RECT)))
      .map((lineDataItem) => {
        const { values, color, name, size, isDottedLine, opacity, type, types, radius } = lineDataItem;
        return values.filter(v => !filterVals.includes(v.y) || !filterVals.includes(v.y2)).map((v) => ({ ...v, color, name, size, isDottedLine, opacity, type, types, radius }));
      });
    //#endregion

    //#region Add lines to the chart
    const linePath = d3
      .line()
      .x((d) => {
        return xScaleCal(d.x);
      })
      .y((d) => getY(d));

    svg
      .select("g#alea-chart-line")
      .selectAll("path")
      .data(dataLine)
      .join(
        (enter) => enter.append("path"),
        (update) => update,
        (exit) => exit.remove()
      )
      .attr("d", linePath)
      .attr("fill", "none")
      .attr("stroke", (dataItem) => {
        return dataItem?.[0]?.color || "black";
      })
      .attr("stroke-width", (dataItem) => dataItem?.[0]?.size || 1)
      .style("opacity", (dataItem) => (activeChartTypes.includes(ALEA_CHART_TYPES.LINE) ? dataItem?.[0]?.opacity || 1 : 0))
      .attr("stroke-dasharray", (dataItem) => (dataItem?.[0]?.isDottedLine ? "3,3" : 0));
    //#endregion

    //#region Add the rectangle to the chart
    const rectSize = 10;
    svg
      .select("g#alea-chart-rect")
      .selectAll("g")
      .data(dataRect)
      .join("g")
      .selectAll("rect")
      .data(
        (dataItem) => {
          if ([ALEA_CHART_TYPES.TEXT, ALEA_CHART_TYPES.LINE].includes(dataItem?.[0]?.type)) {
            return [];
          }
          return dataItem;
        },
        (d) => `${d.x}-${d.y}`
      )
      .join("rect")
      .attr("class", "rectangle")
      .attr("x", (d) => xScaleCal(d.x) - ((d.radius * 2 || rectSize) / 2))
      .attr("y", (d) => getY(d) - (d.radius * 2 || rectSize) / 2)
      .attr("width", d => d.radius * 2 || rectSize)
      .attr("height", d => d.radius * 2 || rectSize)
      .style("fill", (d) => d.color)
      .style("opacity", (d) => (activeChartTypes.includes(ALEA_CHART_TYPES.RECT) ? d.opacity || 0.5 : 0))
      .style("stroke-width", 0.5)
      .style("stroke", (aleaTheme === THEMES.DARK ? "white" : "black"));
    //#endregion

    //#region add dot shapes
    // const star = d3.symbol().type(d3.symbolStar).size(500);
    // svg
    //   .select("g#alea-chart-line")
    //   .append("path")
    //   .attr("d", star)
    //   .attr("fill", "green")
    //   .attr("stroke", "green")
    //   .attr("stroke-width", "5px")
    //   .attr("transform", "translate(100, 50)");
    //#endregion

    // #region Add the dots to the chart
    svg
      .select("g#alea-chart-dot")
      .selectAll("g")
      .data(dataDot)
      .join("g")
      .selectAll("circle")
      .data(
        (dataItem) => {
          if ([ALEA_CHART_TYPES.TEXT, ALEA_CHART_TYPES.LINE].includes(dataItem.type)) {
            return [];
          }
          return dataItem;
        },
        (d) => `${d.x}-${d.y}`
      )
      .join("circle")
      .attr("cx", (d) => xScaleCal(d.x)) // (Math.random() - (width / dataX.length))
      .attr("cy", (d) => getY(d))
      .attr("r", d => d.radius || 5) // Radius of the dots
      .style("fill", (d) => d.color)
      .style("opacity", (d) => (activeChartTypes.includes(ALEA_CHART_TYPES.DOT) ? (d.opacity >= 0 ? d.opacity : 0.5) : 0))
      .style("stroke-width", 0.5)
      .style("stroke", (aleaTheme === THEMES.DARK ? "white" : "black"))
      .on("mouseover", tooltip?.mouseover)
      .on("mousemove", (e, d) => tooltip?.mousemove(e, d.label))
      .on("mouseout", tooltip?.mouseleave);
    //#endregion

    //#region Add custom text to the chart
    const textData = activeData
      .filter((dataItem) => dataItem.type === ALEA_CHART_TYPES.TEXT)
      .map((dataItem) => {
        const { text, values } = dataItem;
        return values.filter(v => !filterVals.includes(v.y) || !filterVals.includes(v.y2)).map((v) => ({
          ...v,
          content: v.text,
          text,
        }));
      });
    //#region create round layout on text
    const circleLayoutContainsLabels = textData.filter((d) => d[0]?.text?.layout?.type === ALEA_CHART_TYPES.DOT).some((d) => d.some(x => x.label));
    const circleLayout = svg
      .select("g#alea-chart-text-layout-circle")
      .selectAll("g")
      .data(textData.filter((d) => d[0]?.text?.layout?.type === ALEA_CHART_TYPES.DOT))
      .join("g")
      .selectAll("circle")
      .data(
        (d) => d,
        (d) => `${d.x}-${d.y}`
      )
      .join("circle")
      .attr("class", "text-layout")
      .attr("cx", (d) => xScaleCal(d.x))
      .attr("cy", (d) => getY(d))
      .attr("r", (d) => d.text.layout.radius)
      .style("fill", (d) => d.text.layout.fill)
      .style("stroke", (d) => d.text.layout.color)
      .style("opacity", (d) => d.text.layout.opacity)
      .style("stroke-width", 0.5)

    if (circleLayoutContainsLabels) {
      circleLayout
        .on("mouseover", tooltip?.mouseover)
        .on("mousemove", (e, d) => tooltip?.mousemove(e, d.label))
        .on("mouseout", tooltip?.mouseleave);
    }
    //#endregion
    const rectLayoutContainsLabels = textData.filter((d) => d[0]?.text?.layout?.type === ALEA_CHART_TYPES.RECT).some((d) => d.some(x => x.label));
    //#region create box shape layout on text
    const rectLayout = svg
      .select("g#alea-chart-text-layout-rect")
      .selectAll("g")
      .data(textData.filter((d) => d[0]?.text?.layout?.type === ALEA_CHART_TYPES.RECT))
      .join("g")
      .selectAll("rect")
      .data(
        (d) => d,
        (d) => `${d.x}-${d.y}`
      )
      .join("rect")
      .attr("class", "text-layout-rect")
      .attr("x", (d) => xScaleCal(d.x) - d.text.layout.width / 2)
      .attr("y", (d) => getY(d) - d.text.layout.width / 2 + (d.text.layout.offsetY || 0))
      .attr("width", (d) => d.text.layout.width)
      .attr("height", (d) => d.text.layout.height)
      .style("fill", (d) => d.text.layout.fill)
      .style("stroke", (d) => d.text.layout.color)
      .style("opacity", (d) => d.text.layout.opacity)
      .style("stroke-width", 0.5)
    //#endregion
    if (rectLayoutContainsLabels) {
      rectLayout
        .on("mouseover", tooltip?.mouseover)
        .on("mousemove", (e, d) => tooltip?.mousemove(e, d.label))
        .on("mouseout", tooltip?.mouseleave);
    }

    //#region create text
    svg
      .select("g#alea-chart-text")
      .selectAll("g")
      .data(textData)
      .join("g")
      .selectAll("text")
      .data(
        (d) => d,
        (d) => `text-${d.x}-${d.y}`
      )
      .join("text")
      .attr("class", "text-item")
      .attr("x", (d) => xScaleCal(d.x) + (d.text.offsetX || 0))
      .attr("y", (d) => getY(d) + (d.text.offsetY || 0))
      .text((d) => d.content)
      .style("cursor", "auto")
      .style("user-select", "none")
      .style("pointer-events", "none")
      .style("text-anchor", "middle")
      .style("dominant-baseline", "middle")
      .style("fill", (d) => d.text.color || (aleaTheme === THEMES.DARK ? "white" : "black"))
      .style("font-size", (d) => d.text.size)
      .style("border", "1px solid white")
      .style("font-style", (d) => (d.text.isItalic ? "italic" : "normal"))
      .style("font-weight", (d) => (d.text.isBold ? "bold" : "normal"));
    //#endregion
    //#endregion
    //#endregion
  }, [data, activeLables, labels, activeChartTypes, xyScaling, shapes, aleaTheme]);
  //#endregion



  //#region click ================================
  const handleLagend = (e, label) => {
    if (e.detail > 1) {
      clearTimeout(clickTimeout);
      handleLegendDblClick(label);
      return;
    }
    const timer = setTimeout(() => {
      handleLegendClick(label);
    }, 300);
    setClickTimeout(timer);
  };

  const handleLegendDblClick = (label) => {
    // console.log('test', activeLables.length === 1 && label === activeLables[0]);
    if (activeLables.length === 1 && label === activeLables[0]) {
      // console.log("activate all", labels);
      setActiveLables(labels.map(({ name }) => name));
      return;
    }
    setActiveLables([labels.find((x) => x.name === label)?.name]);
  };

  const handleLegendClick = (label) => {
    const cuurActivelabels = [...activeLables];
    if (cuurActivelabels.includes(label)) {
      setActiveLables(cuurActivelabels.filter((l) => l !== label));
      return;
    }
    cuurActivelabels.push(label);
    setActiveLables(cuurActivelabels);
  };
  //#endregion ===================================

  // #region download chart========================
  useEffect(() => {
    if (isImageLoading) {
      setTimeout(() => {
        html2canvas(chartContainerRef.current, { allowTaint: true }).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg");
          // Create a link to download the image
          const link = document.createElement("a");
          link.href = imgData;
          link.download = "chart.jpeg";
          link.click();
          setIsImageLoading(false);
        });
      }, 0);
    }
  }, [isImageLoading]);
  // #endregion

  //#region chart type ===========================
  const handleChartType = (selectedType) => {
    const currTypes = [...activeChartTypes];
    if (currTypes.includes(selectedType)) {
      setActiveChartTypes(currTypes.filter((t) => t !== selectedType));
      return;
    }
    currTypes.push(selectedType);
    setActiveChartTypes(currTypes);
  };
  //#endregion

  //#region get Legend svg =======================
  const getLegendIcon = (types, color, isDottedLine) => {
    if (!types) {
      return (
        <svg height="10" width="40">
          {CHART_TYPES.includes(ALEA_CHART_TYPES.DOT) && <circle cx="20" cy="5" r="3" stroke="none" fill={color} />}
          {CHART_TYPES.includes(ALEA_CHART_TYPES.RECT) && <rect x="15" y="0" width="6" height="6" stroke="none" fill={color} />}
          {CHART_TYPES.includes(ALEA_CHART_TYPES.LINE) && <path d="M 0 5 l 50 0" stroke={color} strokeWidth="2" fill="none" strokeDasharray={isDottedLine ? '3,3' : '0'} />}
        </svg>
      );
    }
    return (
      <svg height="10" width="40">
        {types.includes(ALEA_CHART_TYPES.DOT) && <circle cx="20" cy="5" r="3" stroke="none" fill={color} />}
        {types.includes(ALEA_CHART_TYPES.RECT) && <rect x="15" y="0" width="6" height="6" stroke="none" fill={color} />}
        {types.includes(ALEA_CHART_TYPES.LINE) && <path d="M 0 5 l 50 0" stroke={color} strokeWidth="2" fill="none" strokeDasharray={isDottedLine ? '3,3' : '0'} />}
      </svg>
    );
  };
  //#endregion ===================================

  //#region logs
  // console.log("labels", labels);
  // console.log("aleaTheme", aleaTheme);
  //#endregion

  //#region render
  return (
    <div ref={chartMainRef} className={`main-chart-block ${aleaTheme}`}>
      {/* BEGIN Chart Action Buttions */}
      <div className="chart-controls-block">
        <div className="chart-controls">
          <button className="chart-type-btn active" onClick={() => resetChart(true)}>
            <img src={icons[aleaTheme].home} alt="" />
            {/* Reset */}
          </button>
          &nbsp;
          {isImageLoading === true ? (
            <button className="chart-type-btn active">
              <Loader />
            </button>
          ) : (
            <button className="chart-type-btn active" onClick={() => setIsImageLoading(true)}>
              <img src={icons[aleaTheme].dowloand} alt="" />
            </button>
          )}
          &nbsp;
          {MODES.map((modeOpt) => (
            <button key={modeOpt}
              className={`mr-1 chart-type-btn${modeOpt === mode ? " active" : ""}`}
              onClick={() => setMode(init => modeOpt === init ? null : modeOpt)}
            >
              <img src={modeOpt === MODES[0] ? icons[aleaTheme].pan : icons[aleaTheme].zoom} alt="" />
              {/* {modeOpt} */}
            </button>
          ))}
          <button className={`chart-type-btn${isVerticalLock ? " active" : ""}`} onClick={() => setIsVerticalLock(!isVerticalLock)}>
            <img src={icons[aleaTheme].verticalLock} alt="" />
          </button>
          <button className={`chart-type-btn${isHorizontLock ? " active" : ""}`} onClick={() => setIsHorizontLock(!isHorizontLock)}>
            <img src={icons[aleaTheme].hrizontalLock} alt="" />
          </button>
          {CHART_TYPES.length > 1 &&
            CHART_TYPES.map((chartType) => (
              <Fragment key={chartType}>
                <button onClick={() => handleChartType(chartType)} className={`chart-type-btn${activeChartTypes.includes(chartType) ? " active" : ""}`}>
                  <img src={icons[aleaTheme][chartType]} alt="" />
                  {/* {chartType} */}
                </button>
                &nbsp;
              </Fragment>
            ))}
        </div>
        <div className="chart-theme-handler">
          <div className="hayer-toggle-switch inline-block">
            <label>
              <input type="checkbox" checked={aleaTheme === THEMES.LIGHT} onChange={(e) => setAleaTheme(!e.target.checked ? THEMES.DARK : THEMES.LIGHT)} />
              <span className="hayer-slider"></span>
            </label>
          </div>
        </div>
      </div>
      {/* END Chart Action Buttions */}
      <div ref={chartContainerRef} className={`w-full flex flex-row chart-Block chart-${aleaTheme}`}>
        <div ref={chartRef} className="flex-grow" id="chart-container"></div>

        {IS_LEGENDS && (
          <div className="flex-none overflow-y-auto min-w-min narrow-scroll-bar" style={{ width: `10%`, height: HEIGHT }}>
            {/* <Scrollbars style={{ width: `${LEGEND_WIDTH}px`, height: HEIGHT }}> */}
            <ul className="list-none legend-list">
              {labels.map((l) => (
                <li key={l.name}>
                  <div
                    className={`flex flex-col items-center legend ${CHART_TYPES.join(" ")}${activeLables.includes(l.name) ? " active" : ""}`}
                    onClick={(e) => handleLagend(e, l.name)}
                    style={{
                      borderColor: l.color,
                    }}
                  >
                    {getLegendIcon(l.types, l.color, l.isDottedLine)}
                    <span style={{ fontSize: '10px' }}>{l.name}</span>
                  </div>
                </li>
              ))}
            </ul>
            {/* </Scrollbars> */}
          </div>
        )}
      </div>
    </div>
  );
  //#endregion
}

export default AleaCharts
