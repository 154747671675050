import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { updateGivePropInArray } from '../../common'
import layoutService from '../../services/layout';
import { cloneDeep } from 'lodash';
import { IPages } from '@mahindraformulae/rubicon-hooks';
import uuid4 from 'uuid4';

export const getUserLayout = createAsyncThunk('layout/getUserLayout', async (args, thunkAPI) => {
    try {
        const userLayout = await layoutService.getUserLayout();
        if (userLayout && userLayout.length) {
            const foundLayout = userLayout.find((l: any) => l.name === 'userLayout');
            if (foundLayout) {
                return foundLayout.meta;
            }
        }
    } catch (error: any) {
        console.log('errr', error);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const setUserLayout = createAsyncThunk('layout/setUserLayout', async (_userLayout: any, thunkAPI) => {
    try {
        const _payload = {
            'name': 'userLayout',
            'isDefault': true,
            'meta': _userLayout
        }
        const userLayout = await layoutService.setUserLayout(_payload);
        return userLayout.data;
    } catch (error: any) {
        console.log(error);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const MainTabsSlice = createSlice({
    name: 'mainTabs', initialState: () => {
        const pages: IPages[] = []
        return { pages };
    },
    reducers: {
        addPage: (state, action) => {
            const pages: IPages = action.payload;
            state.pages = updateGivePropInArray(state.pages, 'active', false);
            state.pages.push({ ...pages, active: true });
        }, removePage: (state, action) => {
            const pages: IPages = action.payload;
            const { id, active } = pages;
            const pageToRemoveIndex = state.pages.findIndex(p => p.id === id);
            if (pageToRemoveIndex !== -1) {
                state.pages.splice(pageToRemoveIndex, 1);
                if (active && state.pages.length) {
                    state.pages[0].active = true
                }
            }
        }, setCurrPage: (state, action) => {

            state.pages = updateGivePropInArray(state.pages, 'active', false);
            const currPage = state.pages.find(p => p.id === action.payload);
            if (currPage) {
                currPage.active = true;
            }
        }, addCompToPage: (state, action) => {

            const pages = cloneDeep(state.pages);
            if (pages.length > 0) {
                pages.forEach(p => {
                    if (p.active === true) {
                        p.components.push(action.payload);
                        p.layout.push({
                            x: 0,
                            y: 0,
                            key: action.payload.name,
                            width: action.payload.width,
                            height: action.payload.height,
                        })
                    }
                });
                state.pages = pages;
            } else {
                state.pages = updateGivePropInArray(state.pages, 'active', false);
                state.pages.push({
                    id: uuid4(),
                    name: `Tab 1`,
                    components: [action.payload],
                    layout: [{
                        x: 0,
                        y: 0,
                        key: action.payload.name,
                        width: action.payload.width,
                        height: action.payload.height,
                    }],
                    active: true
                });
            }
        }, removeCompFromPage: (state, action) => {
            const currPage = state.pages.find(p => p.active === true);
            if (currPage) {
                currPage.components.splice(action.payload, 1);
                currPage.layout.splice(action.payload, 1);
            }
        }, updateLayoutForThePage: (state, action) => {
            const currPage = state.pages.find(p => p.active === true);
            if (currPage) {
                currPage.layout = action.payload;
            }
        }, updateTab: (state, action) => {
            const currPage = state.pages.find(p => p.active === true);
            if (currPage) {
                currPage.name = action.payload
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserLayout.fulfilled, (state, action) => {
            console.log('getUserLayout.fulfilled called')

            state.pages = action.payload || [];
        });
        builder.addCase(getUserLayout.rejected, (state) => {
            console.log('getUserLayout.rejected called')

            state.pages = [];
        });
        builder.addCase(setUserLayout.fulfilled, (state, action) => {
            console.log('setUserLayout.fulfilled called')

            // state.pages = action.payload;
        });
        builder.addCase(setUserLayout.rejected, (state,) => {
            console.log('setUserLayout.rejected called')

            // state.pages = [];
        });
    }
});

export const {
    addPage,
    removePage,
    setCurrPage,
    addCompToPage,
    removeCompFromPage,
    updateLayoutForThePage,
    updateTab
} = MainTabsSlice.actions;
export default MainTabsSlice.reducer;