import * as d3 from "d3";
import { LAYOUT, THEMES } from "../aleaConstants";

const offset = 35;
function panHandler(setXYScaling) {
  let panStart = { x: 0, y: 0 };
  function handlePan(event) {
    setXYScaling((init) => {
      let { xScale, yScale, y2Scale } = init;

      let currXRange = xScale.range();
      let newXScale = xScale.copy().range([currXRange[0] + event.transform.x - panStart.x, currXRange[1] + event.transform.x - panStart.x]);

      // let currXDom = xScale.domain()
      // let deltaX = xScale.invert(event.transform.x) - xScale.invert(panStart.x)
      // let newXScale = xScale.copy().domain([currXDom[0] - deltaX, currXDom[1] - deltaX])

      let currYDom = yScale.domain();
      let deltaY = yScale.invert(event.transform.y) - yScale.invert(panStart.y);
      let newYScale = yScale.copy().domain([currYDom[0] - deltaY, currYDom[1] - deltaY]);
      let newY2Scale;
      if (y2Scale) {
        let deltaY2 = y2Scale.invert(event.transform.y) - y2Scale.invert(panStart.y);
        let currY2Dom = y2Scale.domain();
        newY2Scale = y2Scale.copy().domain([currY2Dom[0] - deltaY2, currY2Dom[1] - deltaY2]);
      }
      panStart.x = event.transform.x;
      panStart.y = event.transform.y;
      return { xScale: newXScale, yScale: newYScale, y2Scale: newY2Scale };
    });
  }
  let panning = d3
    .zoom()
    .filter((event) => {
      // Allow panning (mouse drag) events, disable zoom events
      return !(event.ctrlKey || event.type === "wheel") && !event.button;
    })
    .on("zoom", handlePan)
    .on("start", (event) => {
      panStart.x = event.transform.x;
      panStart.y = event.transform.y;
    });

  return panning;
}

function rectangularZoomHandler(context, xScale, yScale, xAxis, yAxis) {
  let width = context.attr("width");
  let height = context.attr("height");
  const origXScale = xScale.copy();
  const origYScale = yScale.copy();
  let mouseDownTime = 0;
  let mouseUpTime = 0;
  const delay = 100;
  const minDim = 20;
  let zoomListenerRect = context
    .append("rect")
    .attr("id", "zoomListener")
    .attr("width", width)
    .attr("height", height)
    .style("fill", "none")
    .style("pointer-events", "all")
    .attr("transform", `translate(${LAYOUT.margin.left},${LAYOUT.margin.top})`)
    .style("cursor", "crosshair");

  let selectionRect = null;
  let selectionStart = { x: 0, y: 0 };
  let isCancelled = false;
  zoomListenerRect.on("mousedown", function (event) {
    mouseDownTime = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - mouseUpTime > delay) {
        context.node().append(this);
        zoomListenerRect.style("fill", "lightgray").style("fill-opacity", 0.4);
        selectionStart = { x: event.offsetX, y: event.offsetY };
        selectionRect = context.append("rect").attr("class", "selection-rect").attr("x", selectionStart.x).attr("y", selectionStart.y);
      }
    }, delay);
  });

  zoomListenerRect.on("mousemove", function (event) {
    if (selectionRect) {
      const x = Math.min(selectionStart.x, event.offsetX);
      const y = Math.min(selectionStart.y, event.offsetY);
      const selectedWidth = Math.abs(event.offsetX - selectionStart.x);
      const selectedHeight = Math.abs(event.offsetY - selectionStart.y);

      if (selectedWidth > minDim) {
        if (selectedHeight > minDim) {
          selectionRect.attr("x", x).attr("y", y).attr("width", selectedWidth).attr("height", selectedHeight);
        } else {
          selectionRect.attr("x", x).attr("y", LAYOUT.margin.top).attr("width", selectedWidth).attr("height", height);
        }
        isCancelled = false;
      } else {
        if (selectedHeight > minDim) {
          isCancelled = false;
          selectionRect.attr("x", LAYOUT.margin.left).attr("y", y).attr("width", width).attr("height", selectedHeight);
        } else {
          // selectionRect.remove();
          // selectionRect = null;
          isCancelled = true;
        }
      }
    }
  });

  zoomListenerRect.on("mouseup", function (event) {
    mouseUpTime = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - mouseDownTime > delay) {
        zoomListenerRect.style("fill", "none");
        context.node().insertBefore(this, context.node().firstChild);
        if (selectionRect && !isCancelled) {
          const x1 = selectionStart.x;
          const x2 = event.offsetX;
          if (Math.abs(selectionStart.x - event.offsetX) >= minDim) {
            let currDom = xScale.domain();
            let colWidth = width / currDom.length;
            const newDomainX = currDom.slice(Math.floor(Math.min(x1, x2) / colWidth), Math.ceil(Math.max(x1, x2) / colWidth));
            xScale.domain(newDomainX);
          }
          const y1 = yScale.invert(selectionStart.y);
          const y2 = yScale.invert(event.offsetY);
          if (Math.abs(selectionStart.y - event.offsetY) >= minDim) {
            const newDomainY = [Math.min(y1, y2), Math.max(y1, y2)];
            yScale.domain(newDomainY);
          }

          // Update scales and axes with transition
          xAxis.call(d3.axisBottom(xScale));
          yAxis.call(d3.axisLeft(yScale));
          context
            .selectAll("circle")
            .attr("cx", function (d) {
              return xScale(d.x);
            })
            .attr("cy", (d) => yScale(d.y));

          // Remove the selection rectangle
          selectionRect.remove();
          selectionRect = null;
        }
        if (isCancelled) {
          selectionRect.remove();
          selectionRect = null;
        }
        isCancelled = false;
      }
    }, delay);
  });

  zoomListenerRect.on("dblclick", () => {
    xScale = origXScale.copy();
    yScale = origYScale.copy();
    xAxis.call(d3.axisBottom(origXScale));
    yAxis.call(d3.axisLeft(origYScale));
    context
      .selectAll("circle")
      .attr("cx", function (d) {
        return origXScale(d.x);
      })
      .attr("cy", (d) => origYScale(d.y));
  });
}

function brushZoomHandler(setXYScaling, brushContainer, width, height, context, theme, myId) {
  let selection;

  function brushed(event) {
    if (!event.selection) return;

    // Get the selected area
    let [[x0, y0], [x1, y1]] = event.selection;
    context.select("g").select("g#alea-cliping-area").select("g#alea-chart-brush").selectAll("rect").remove();

    if (Math.abs(x1 - x0) < offset) {
      x0 = 0;
      x1 = width;
    } else if (Math.abs(y1 - y0) < offset) {
      y0 = 0;
      y1 = height;
    }
    // console.log('x y', x0, y0, x1, y1);
    // return;

    // Call the setter to set new scales
    setXYScaling((init) => {
      let { xScale, yScale, y2Scale } = init;
      // Calculate the new x range and y domain based on brush selection
      // Update xScale range
      let [xStart, xEnd] = xScale.range();
      let newXScale = xScale.copy().range([((xStart - x0) * width) / (x1 - x0), ((xEnd - x0) * width) / (x1 - x0)]);
      // Update yScale domain
      let newYScale = yScale.copy().domain([yScale.invert(y1), yScale.invert(y0)]);
      //if second yScale exists update its domain
      let newY2Scale;
      if (y2Scale) {
        newY2Scale = y2Scale.copy().domain([y2Scale.invert(y1), y2Scale.invert(y0)]);
      }
      // Clear the brush selection
      brushContainer.call(brush.move, null);
      let newXYScaling = { xScale: newXScale, yScale: newYScale, y2Scale: newY2Scale }
      const scalingEvent = new CustomEvent("aleachartScalingChange", { detail: { sharedScaling: newXYScaling, id: myId } });
      window.dispatchEvent(scalingEvent);
      return newXYScaling;
    });


  }

  function move(event) {
    if (!event.selection) {
      return;
    }
    const [[x0, y0], [x1, y1]] = event.selection;
    // selection = event.selection;
    const [[sx0, sy0]] = selection;
    if (x0 === x1 || y0 === y1) {
      context.select("g").select("g#alea-cliping-area").select("g#alea-chart-brush").selectAll(".rectangle").remove();
      return;
    }

    let x = 0;
    let y = 0;
    let h = height;
    let w = width;
    let arr = [1];
    if (Math.abs(x1 - x0) < offset) {
      y = y0;
      h = y1 - y0;
      arr = [1, 2, 3]
    } else if (Math.abs(y1 - y0) < offset) {
      x = x0;
      w = x1 - x0;
      arr = [1, 2, 3]
    } else {
      x = x0;
      y = y0;
      w = x1 - x0;
      h = y1 - y0;
    }

    context
      .select("g")
      .select("g#alea-cliping-area")
      .select("g#alea-chart-brush")
      .selectAll("rect")
      .data(arr)
      .join("rect")
      .attr("class", "rectangle")
      .attr("x", (d) => {
        if (d === 2 && x !== 0) {
          if (x0 < sx0) {
            return sx0 + 2
          }
          return sx0 - 4;
        } else if (d === 3 && x !== 0) {
          if (x0 < sx0) {
            return sx0 - (w + 4)
          }
          return sx0 + w;
        } else if ((d === 2 || d === 3) && y !== 0) {
          return sx0 - offset;
        }
        return x;
      })
      .attr("y", (d) => {
        if ((d === 2 || d === 3) && x !== 0) {
          return sy0 - offset
        } else if (d === 2 && y !== 0) {
          if (y0 < sy0) {
            return sy0 + 2
          }
          return sy0 - 4;
        } else if (d === 3 && y !== 0) {
          if (y0 < sy0) {
            return sy0 - (h + 4)
          }
          return sy0 + h;
        }
        return y;
      })
      .attr("width", d => d === 1 ? w : x !== 0 ? 2 : offset * 2)
      .attr("height", d => d === 1 ? h : x !== 0 ? offset * 2 : 2)
      .style("fill", theme === THEMES.LIGHT ? "skyblue" : "white")
      .style("fill-opacity", 0.2)
      .style("stroke", theme === THEMES.LIGHT ? "black" : "white")
      .style("stroke-width", 1)
      .style("stroke-opacity", 1);
  }

  function start(event) {
    selection = event.selection;
  }

  const brush = d3
    .brush()
    .extent([
      [0, 0],
      [width, height],
    ])
    .on("start", start)
    .on("brush", move)
    .on("end", brushed, false);
  return brush;
}
export { panHandler, rectangularZoomHandler, brushZoomHandler };
