import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReminderData, setInterventionData, setLapsDash, setAddedLapsData } from "redux/modules/global";
import config from "../../config";
import { RootState } from "@/redux/store";
import { ICar, IInterventions } from "@mahindraformulae/rubicon-hooks";
import { BoltIcon, CheckBadgeIcon, ClockIcon, ExclamationCircleIcon, FlagIcon, ForwardIcon, HandRaisedIcon, PlusCircleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { IInterventionsWorkerRequest } from "worker/interventionsScript";
import { useWebWorker } from "worker/useWebWorker";
import useAudioNotifications from "./useAudioNotifications";
import { ILapsRemainingWorkerRequest } from "worker/lapsRemainingScript";
import { useNotifications } from "worker/hooks";
import { INotificationMsg } from "worker/notificationsScript";

const { OUR_CARS, NEW_API_BASE } = config
const DO_NOT_POP_MINUTES = 5 * 60 * 1000;

const ICON_MAP: Record<string, any> = {
  XCircle: <XCircleIcon className="small-icon" />,
  Bolt: <BoltIcon className="small-icon" />,
  Forward: <ForwardIcon className="small-icon" />,
  Clock: <ClockIcon className="small-icon" />,
  PlusCircle: <PlusCircleIcon className="small-icon" />,
  CheckBadge: <CheckBadgeIcon className="small-icon" />,
  HandRaised: <HandRaisedIcon className="small-icon" />,
  Flag: <FlagIcon className="small-icon" />,
}

const CONFIG_KEY = 'ATTACK_CONFIG';
const ATTACK_MODE_TAKEN_REF_DEFAULT = OUR_CARS.reduce((obj: Record<string, [boolean, boolean]>, d: ICar) => ({ ...obj, [`${d.number}`]: [false, false] }), {})
function Notifications() {
  const [showToasts, setShowToasts] = useState<Record<string, boolean>>({});
  const [messages, setMessages] = useState<INotificationMsg[]>([]);
  const [interventionState, setInterventionState] = useState<IInterventions>();
  const [lapsAddedFromDB, setLapsAddedFromDB] = useState<number>(0);
  const [AMLapThreshold, setAMLapThreshold] = useState<{ lap: number, remAM: number }[]>([]);

  const dispatch = useDispatch();
  const selectedEvent = useSelector((state: RootState) => state.global.selectedEvent);
  const flagMessages = useSelector((state: RootState) => state.global.flagMessages);
  const attackSummary = useSelector((state: RootState) => state.global.attackSummary);
  const laps = useSelector((state: RootState) => state.global.laps);
  const loops = useSelector((state: RootState) => state.global.loops);
  const localReplay = useSelector((state: RootState) => state.global.localReplay);
  const __driverMap = useSelector((state: RootState) => state.global.sessionInfo);
  const selectedCarsGlobal = useSelector((state: RootState) => state.global.selectedCars);
  const OUR_CARS = useSelector((state: RootState) => state.global.ourCars);

  const attackModeTaken = useRef({ ...ATTACK_MODE_TAKEN_REF_DEFAULT });
  const localReplayCurrTSRef = useRef(localReplay.currTS);

  const workerInterventions = useMemo(() => new Worker(new URL('../../worker/interventionsScript.ts', import.meta.url)), []);
  const interventionsFromWorker = useWebWorker<IInterventions, IInterventionsWorkerRequest>(workerInterventions);
  const interventionsProcessRef = useRef(interventionsFromWorker.startProcessing);

  const workerLapsRemaining = useMemo(() => new Worker(new URL('../../worker/lapsRemainingScript.ts', import.meta.url)), []);
  const lapsRemainingFromWorker = useWebWorker<any, ILapsRemainingWorkerRequest>(workerLapsRemaining);
  const lapsRemainingProcessRef = useRef(lapsRemainingFromWorker.startProcessing);

  const selectedCars = useMemo<number[]>(() => {
    if (!selectedCarsGlobal?.length) return [];
    return selectedCarsGlobal.filter(c => c.checked === true).map(c => c.number) ?? [];
  }, [selectedCarsGlobal]);

  useAudioNotifications(messages, __driverMap);

  const [notifications] = useNotifications({ laps, selectedEvent, attackSummary, interventionState, lapsAddedFromDB, OUR_CARS, attackModeTaken: attackModeTaken.current, AMLapThreshold });

  useEffect(() => {
    localReplayCurrTSRef.current = localReplay.currTS + (localReplay.deltaTS ?? 0);
  }, [localReplay]);

  useEffect(() => {
    if (selectedEvent.isCurrent) {
      interventionsProcessRef.current({ _laps: laps, _sessionFlags: flagMessages, raceInfo: selectedEvent, _loops: loops, date: Date.now() });
    } else {
      interventionsProcessRef.current({ _laps: laps, _sessionFlags: flagMessages, raceInfo: selectedEvent, _loops: loops, date: localReplayCurrTSRef.current });
    }
  }, [interventionsProcessRef, laps, loops, selectedEvent, flagMessages]);

  useEffect(() => {
    if (interventionsFromWorker.result) {
      dispatch(setInterventionData(interventionsFromWorker.result))
      setInterventionState(interventionsFromWorker.result)
    }
  }, [dispatch, interventionsFromWorker.result])

  useEffect(() => {
    setShowToasts({});
    setMessages([])
    let attackModeTaken_: Record<string, boolean[]> = {}
    OUR_CARS.forEach((d: ICar) => {
      attackModeTaken_[`${d.number}`] = [false, false]
    })

    attackModeTaken.current = attackModeTaken_
  }, [selectedEvent, OUR_CARS])

  useEffect(() => {
    async function fetchCurrentRace() {
      try {
        if (selectedEvent?.raceEventId) {
          const response = await fetch(`${NEW_API_BASE}/master/races/${selectedEvent.raceEventId}`).then((res) => res.json());
          setLapsAddedFromDB(response.data[0]?.lapsAdded);
          dispatch(setAddedLapsData(response.data[0]?.lapsAdded))
        }
      } catch (error) {
        console.error(error);
      }
    }

    let timer: NodeJS.Timeout;
    fetchCurrentRace();
    if (selectedEvent?.isCurrent) {
      timer = setInterval(() => fetchCurrentRace(), 5000);
    }
    return () => {
      clearInterval(timer);
    }
  }, [dispatch, selectedEvent])

  useEffect(() => {
    async function fetchAMLapThreshold(selectedEventID: number) {
      if (!selectedEventID) return;
      let params = new URLSearchParams({ 'configKey': CONFIG_KEY, 'raceEventId': selectedEventID.toString(), 'driverNo': '0' })
      let savedStateAPI = `${NEW_API_BASE}/master/config/generic?${params.toString()}`;
      try {
        const { data: savedStateResponse } = await fetch(savedStateAPI).then(data => data.json());

        if (savedStateResponse?.config?.attackModeConfig?.lapRemainData?.[2]) {
          const { lapRemain1, lapRemain2 } = savedStateResponse.config.attackModeConfig.lapRemainData[2]
          const AMLapThreshold_ = [{ lap: lapRemain1, remAM: 2 }, { lap: lapRemain2, remAM: 1 }]
          setAMLapThreshold(AMLapThreshold_);
        } else {
          setAMLapThreshold([])
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAMLapThreshold(selectedEvent?.raceEventId).then()
  }, [selectedEvent])

  useEffect(() => {
    lapsRemainingProcessRef.current({ laps, raceInfo: { ...selectedEvent, lapsAdded: lapsAddedFromDB }, drivers: OUR_CARS.map(car => car.number) })
  }, [laps, selectedEvent, lapsAddedFromDB, OUR_CARS, selectedCars, lapsRemainingProcessRef]); //Rem laps calc

  useEffect(() => {
    let lapRemaining: { [key: number]: { rem: number; lapsDash: number; totalLaps: number; } };
    lapRemaining = lapsRemainingFromWorker?.result ?? {};
    dispatch(setLapsDash(lapRemaining))
  }, [dispatch, lapsRemainingFromWorker?.result])

  useEffect(() => {
    setShowToasts(initObj =>
      notifications
        .filter(m => !Object.keys(initObj).includes(m.id))
        .reduce((obj, curr) => ({ ...obj, [curr.id]: (moment.now() - curr.dayTime < DO_NOT_POP_MINUTES) && (selectedCars.includes(curr.car) || curr.car === 0) }), initObj))
    setMessages(notifications)
  }, [notifications, selectedCars])

  useEffect(() => {
    dispatch(setReminderData(messages));
  }, [messages, dispatch]);

  const makeToasts = useCallback((msg: INotificationMsg) => {
    const closeHandler = (index: string) => {
      setShowToasts(init => {
        let showToasts_ = { ...init };
        showToasts_[index] = false;
        return showToasts_;
      })
    }
    return <div key={msg.id} className={`w-96 m-2 rounded-md text-black pointer-events-auto ms-auto ${showToasts[msg.id] ? '' : '!hidden'}`} role="alert" style={{ backgroundColor: msg.backgroundColor }}>
      <div className="rounded-t-md border flex items-center" style={{ backgroundColor: msg.color }}>
        <ExclamationCircleIcon className="small-icon" />&nbsp;
        <strong className="me-auto"> {__driverMap?.[`${msg.car}`]?.shortName ?? ''} Reminder</strong>
        <small className="ms-auto italic">{moment(msg.dayTime).fromNow()}</small>
        <button type="button" className="text-black" onClick={() => closeHandler(msg.id)}> <XMarkIcon className="small-icon" /></button>
      </div>
      <div className="p-3">
        {ICON_MAP[msg.icon]}&nbsp;
        <b>{msg.text}</b>
      </div>
    </div>
  }, [showToasts, __driverMap]);

  return (
    <div className="w-full fixed bottom-0 end-0 p-3 pointer-events-none z-[100]" >
      {messages.map(d => makeToasts(d))}
    </div>
  );
}

export default Notifications;