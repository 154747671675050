import { IResponse } from '@/types';
import config from '../../config/index';
import { IRaceEvent, ISeason } from '@mahindraformulae/rubicon-hooks';

const { NEW_API_BASE } = config;
const getSessionDrivers = async (selectedEvent: IRaceEvent) => {
  const { raceEventId, isCurrent } = selectedEvent;
  const postBody = ["SessionEntry"];
  let _api = isCurrent
    ? `${NEW_API_BASE}/on-demand/live`
    : `${NEW_API_BASE}/on-demand/${raceEventId}`;
  return fetch(_api, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postBody),
  })
    .then((data) => data.json())
    .then((apiResponse = []) => {
      let { SessionEntry = [] } = apiResponse?.data;
      return SessionEntry;
    });
};

const getSessionInfoDetails = async (selectedEvent: IRaceEvent) => {
  const { raceEventId, isCurrent } = selectedEvent;
  const postBody = ["SessionInfo"];
  let _api = isCurrent
    ? `${NEW_API_BASE}/on-demand/live`
    : `${NEW_API_BASE}/on-demand/${raceEventId}`;
  return fetch(_api, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postBody),
  })
    .then((data) => data.json())
    .then((apiResponse = []) => {
      let { SessionInfo = [] } = apiResponse?.data;
      return SessionInfo;
    });
};

const getCurrentSeason = async () => {
  let _api = `${NEW_API_BASE}/master/seasons/current`
  return fetch(_api)
    .then((resp) => resp.json())
    .then(({ data: currentSeason }: IResponse<ISeason>) => {
      return currentSeason;
    });
}
const sessionService = {
  getSessionDrivers,
  getSessionInfoDetails,
  getCurrentSeason,
};

export default sessionService;