import {useState, useRef, useEffect} from 'react';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/24/outline';
import Icon from 'components/svg';

function Collapse(props: any) {
  const contentRef = useRef<HTMLDivElement>(null);
  let [expanded, setExpanded] = useState(false);
  const toggle = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    setExpanded(props.activeSuperMenu);
  }, [props.activeSuperMenu]);

  return (<div title={props.title} key={props._key}>
    <div onClick={toggle} className="side-item flex flex-col relative">
      <div className="flex items-center">
        <Icon type={props.icon.type}/>
        <div className="absolute right-3 top-1/2 -translate-y-1/2">
          {expanded ? <ChevronUpIcon className="w-3 h-3 text-white sub-icon"/> :
              <ChevronDownIcon className="w-3 h-3 text-white sub-icon"/>}
        </div>
      </div>
      <span className="linkText text-xs select-none">{props.label}</span>
    </div>
    <div className="superMenu" ref={contentRef} style={expanded ? {height: `${contentRef?.current?.scrollHeight}px`} : {height: 0}}>
      {props.children}
    </div>
  </div>);
}

export default Collapse;