import config from 'config';

const getAllEvents = async () => {
  // return fetch(`${config.API_BASE}/master/race/all`).then((resp) => {
  return fetch(`${config.NEW_API_BASE}/master/races`).then((resp) => {
    return resp.json();
  }).catch(err => {
    console.log(`Error in getAllEvents`, err);
    return [];
  });
};

const eventService = {
  getAllEvents,
};

export default eventService;