import React, { useState, useEffect } from "react";
import config from "../../config";
import { store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setDriver, updateUserPreferences } from "../../redux/modules/global";
import { cloneDeep } from "lodash";
const { NEW_API_BASE } = config;
function DriverSelector({
  parentName,
  onDriversSelectionChange,
  isRadioButton = false,
  className
}) {
  const dispatch = useDispatch();
  const userAuthInfo = useSelector((state) => state.global.userAuthData);
  const selectedDriver = useSelector((state) => state.global.selectedDriver);
  const OUR_CARS = useSelector((state) => state.global.ourCars);
  const [selectedCars, markSelectedCars] = useState([]);

  useEffect(() => {
    markSelectedCars([...OUR_CARS].map((e) => {
      const savedPref = userAuthInfo?.preferences?.preferences || [];
      let checked = false;
      const oldPref = savedPref.find((p) => p.module === parentName);
      // console.log(oldPref)
      if (oldPref) checked = !!oldPref?.preferences?.includes(e.number);
      return { ...e, checked };
    }))
  }, [userAuthInfo, OUR_CARS, parentName])

  const saveModuleWiseDriverPreferences = async (parentName, preferences) => {
    const __token = store.getState()?.auth?.user?.cipher;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("sessionId", __token);
    const _preferences = preferences
      .filter((d) => d.checked === true)
      .map((d) => d.number);
    const savedPref = cloneDeep(userAuthInfo?.preferences?.preferences) || [];
    const oldPref = savedPref.find((p) => p.module === parentName);
    if (oldPref) {

      oldPref.preferences = //_preferences;
        [...new Set([..._preferences,
        ...(oldPref.preferences?.length ? oldPref.preferences : [])
        ])].filter(pref => !(OUR_CARS.map(d => d.number).filter(car => !_preferences.includes(car)).includes(pref)));
    } else {
      savedPref.push({ module: parentName, preferences: _preferences });
    }
    const postBody = { preferences: savedPref };
    dispatch(updateUserPreferences(postBody));
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(postBody),
    };
    try {
      const response = await fetch(
        NEW_API_BASE + '/user/preferences',
        requestOptions
      );
      await response.json();
    } catch (error) {
      console.log("Error in Saving preferences:", error);
      return;
    }
  };

  const onCarCheck = (ev, car, isRadioButton) => {
    if (isRadioButton) {
      dispatch(setDriver(car.number));
    } else {
      const _selectedCars = [...selectedCars];
      const checkedCar = _selectedCars.find((c) => c.number === car.number);
      checkedCar.checked = ev.target.checked;
      markSelectedCars(_selectedCars);
      saveModuleWiseDriverPreferences(parentName, _selectedCars)?.then();
    }

  };

  useEffect(() => {
    if (typeof onDriversSelectionChange === `function`) {
      onDriversSelectionChange(selectedCars);
    }
  }, [onDriversSelectionChange, selectedCars]);

  return (
    <>
      {/*selectedCars.map((car) => <div key={car.number} className="form-check form-check-inline">
            <input className="form-check-input" type={isRadioButton ? 'radio' : 'checkbox'} id={`${parentName}${car.number}`} value={car.number} checked={car.checked}
                   onChange={(ev) => onCarCheck(ev, car)}/>
            <label className="form-check-label user-select-none" role="button" htmlFor={`${parentName}${car.number}`}>{car.shortName}</label>
        </div>)*/}
      <div className={`flex min-w-min gap-1 flex-row text-center ${className}`}>
        {selectedCars.map((car) => (
          <label
            key={car.number}
            className="driverSelector py-1"
            style={
              (isRadioButton ?
                (car.number === selectedDriver)
                : car.checked)
                ? { color: "white", borderColor: "white" }
                : { color: "dimgray", borderColor: "dimgray" }
            }
          >
            {car.shortName}
            <input
              className="form-check-input"
              type={isRadioButton ? "radio" : "checkbox"}
              id={`${parentName}${car.number}`}
              value={car.number}
              checked={isRadioButton ? (car.number === selectedDriver) : car.checked}
              onChange={(ev) => onCarCheck(ev, car, isRadioButton)}
            />
            <span
              className="driverMark"
              style={{ backgroundColor: car.color }}
            ></span>
          </label>
        ))}
      </div>
    </>
  );
}

export default DriverSelector;
