import React, { useCallback, useMemo, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";
import classNames from "classnames";
import { RootState, store } from "redux/store";

import RubiconRoutes from "./RubiconRoutes";
import MainSocket from "./MainSocket";
import config from "config";
import Header from "components/layout/Header";
import Nav from "components/layout/Nav";

import "./index.css";
import Notifications from "./pages/Notifications";
import LocalReplay from "./LocalReplay";
import useOutsideAlerter from "./components/Common/useOutsideAlerter";
import { StatusBorder } from "./components/StatusBorder/StatusBorder";
import useLocalStorage from "./utils/useLocalStorage";

const container = document.getElementById("root")!;
const root = createRoot(container);

function App() {
  const location = useLocation();
  const userInfo = useSelector((state: RootState) => state.auth.user);
  const [isSearching, setisSearching] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useLocalStorage('__rubicon_is_menu_open', true);
  const navRef = useRef<HTMLDivElement>(null);

  const isPopUp = useMemo(() => new URLSearchParams(location.search).get('isPopOut'), [location.search]);
  const mainClassNames = useMemo(() => {
    return classNames('main-bg-color laptop:h-[calc(100vh-48px)] overflow-auto transition-all duration-300 ease-in-out pt-1 px-1 desktop:pt-2 desktop:px-2', {
      'rub-main-page-block': !userInfo?.cipher,
      'w-[100vw] h-[100vh]': isPopUp,
      'w-[calc(100vw-6rem)] h-[calc(100vh-38px)]': !isPopUp && isMenuOpen,
      'w-[100vw] h-[calc(100vh-38px)]': !isMenuOpen && !isSearching,
    })
  }, [userInfo?.cipher, isPopUp, isMenuOpen, isSearching]);

  const handleMenuStateChange = useCallback(() => setIsMenuOpen((prevIsAutoMenu) => !prevIsAutoMenu), [setIsMenuOpen]);
  const handleOutsideClick = useCallback(() => setisSearching(false), []);

  useOutsideAlerter(navRef, handleOutsideClick);

  return (
    <div className="dark:bg-black dark:text-white relative flex flex-col">
      <Header isPopUp={isPopUp} />
      <div className="flex flex-row">
        {!isPopUp && (<div ref={navRef}>
          <Nav isSearching={isSearching} setisSearching={setisSearching} isMenuOpen={isMenuOpen} handleMenuStateChange={handleMenuStateChange} />
        </div>)}
        <StatusBorder>
          <main className={mainClassNames}>
            <RubiconRoutes />
          </main>
        </StatusBorder>
      </div>
      {/* <div className="w-[35vw] h-[35vh] overflow-hidden fixed top-1/3 right-0 border border-slate-300 p-1 rounded-lg bg-[#272832] bg-opacity-80" >
        <RaceMap />
      </div> */}
      <Notifications />
    </div>
  );
}

root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.BASE_PATH}>
      <LocalReplay>
        <MainSocket>
          <App />
        </MainSocket>
      </LocalReplay>
    </BrowserRouter>
  </Provider>
);
