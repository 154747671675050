import config from "config";
import { store } from "redux/store";

const login = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const tokenObj = {
    token: token,
  };
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(tokenObj),
  };
  try {
    const response = await fetch(
      config.NEW_API_BASE + "/auth/microsoft",
      requestOptions
    );
    let response_1 = await response.json();
    response_1 = response_1?.data[0];
    if (response_1.cipher) {
      localStorage.setItem(config.LS_KEY, JSON.stringify(response_1));
    }
    return response_1;
  } catch (error) {
    return console.log("Error in Login:", error);
  }
};

const accessInfo = async () => {
  const __token = store.getState()?.auth?.user?.cipher;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  if (__token) myHeaders.append("sessionId", __token);

  const requestOptions = { headers: myHeaders };
  try {
    const response = await fetch(
      config.NEW_API_BASE + "/user/access",
      requestOptions
    );
    let resp= await response.json();
    return resp?.data[0];
  } catch (error) {
    return console.log("Error in Login:", error);
  }
};

const logout = () => {
  localStorage.removeItem(config.LS_KEY);
};

const index = {
  login,
  logout,
  accessInfo,
};

export default index;
