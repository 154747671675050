import {createSlice} from '@reduxjs/toolkit'

export const qualiSlice = createSlice({
    name: 'quali', initialState: {
        currPage: 'home',
    }, reducers: {
        setPage: (state, action) => {
            state.currPage = action.payload
        },
    },
});

export const {setPage} = qualiSlice.actions;
export default qualiSlice.reducer;