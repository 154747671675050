// import "./CustomButton.css";

import { cloneDeep } from "lodash";
import { useEffect } from "react";
import MENUS from 'config/compo-config';
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { IComponent } from "@/types";

function Search({ setFoundItems, value, setValue, inputRef }: { setFoundItems: React.Dispatch<React.SetStateAction<IComponent[]>>, value: string, setValue: React.Dispatch<React.SetStateAction<string>>, inputRef: React.RefObject<HTMLInputElement> }) {
  const userAuthInfo = useSelector((state: RootState) => state.global.userAuthData);
  let _menus = cloneDeep(MENUS.filter(m => m.hideInMainNav !== true));
  _menus = _menus.filter(menu => {
    if (menu.subMenu) {
      const _subMenu = menu.subMenu.filter(m => userAuthInfo?.pages?.includes(m.toUpperCase()));
      if (_subMenu.length) {
        menu.subMenu = _subMenu;
        return true;
      }
    }
    return userAuthInfo?.pages?.includes(menu.key.toUpperCase());
  });

  useEffect(() => {
    if (value !== '') {
      const items = _menus.filter((menu) => menu?.label.toLowerCase().includes(value.toLowerCase()) || menu?.label == 'Search');
      setFoundItems(items);
    } else {
      setFoundItems([])
    }
  }, [value]);

  return (
    <input
      ref={inputRef}
      type="text"
      className="bg-black rounded-lg w-32 focus:ring-green-300"
      placeholder="Search"
      value={value}
      onChange={(ev) => setValue(ev?.target?.value)}
    />
  );
}

export default Search;
