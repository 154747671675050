import { useLayoutEffect, useRef, useCallback, useEffect, useMemo } from "react";
import useLocalStorage from "utils/useLocalStorage";

const generateUniqueId = (): string => {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 11)}`;
};

function useLeaderTab(): boolean {
  const [leader, setLeader] = useLocalStorage<string>('__rubicon_audio_alert_leader', '');
  const leaderRef = useRef<string>('');
  const channelRef = useRef<BroadcastChannel>(new BroadcastChannel("leader-election-channel"));
  const idRef = useRef<string>(generateUniqueId());
  const timeoutRef = useRef<Record<any, number|undefined>>({});
  const isParticipatingRef = useRef<boolean>(false);
  const isRejectedRef = useRef<boolean>(false);
  const isLeader = useMemo(() => (idRef.current === leader), [leader]);

  const initiateElection = useCallback(() => {
    if (timeoutRef.current?.election) {
      clearTimeout(timeoutRef.current?.election);
      timeoutRef.current.election = undefined;
    }
    if (isParticipatingRef.current) return;
    isParticipatingRef.current = true;
    channelRef.current?.postMessage({ type: "ELECTION", id: idRef.current });
    if(timeoutRef.current?.selfLeader) {
      clearTimeout(timeoutRef.current.selfLeader);
      timeoutRef.current.selfLeader = undefined;
    }
    timeoutRef.current.selfLeader = window.setTimeout(() => {
      isParticipatingRef.current = false;
      if(!isRejectedRef.current) {
        setLeader(idRef.current);
        channelRef.current?.postMessage({ type: "LEADER", id: idRef.current });
      }
    }, 1000);
  }, [setLeader]);

  const handleMessage = useCallback((event: MessageEvent) => {
    const { type, id, rejectedId } = event.data;

    switch (type) {
      case 'IS_LEADER':
        if (leaderRef.current === idRef.current) channelRef.current?.postMessage({ type: 'LEADER', id: idRef.current });
        break;
      case "LEADER":
        if(timeoutRef.current?.election) {
          clearTimeout(timeoutRef.current.election);
          timeoutRef.current.election = undefined;
        }
        if(leaderRef.current !== id) setLeader(id);
        break;
      case "ELECTION":
        if (rejectedId === idRef.current) isRejectedRef.current = true;
        if (isRejectedRef.current === false && id < idRef.current) {
          channelRef.current?.postMessage({ type: "ELECTION", id: idRef.current, rejectedId: id });
          isRejectedRef.current = false;
        }
        break;
      case "RESIGN":
        setLeader('');
        isRejectedRef.current = false;
        channelRef.current?.postMessage({ type: 'IS_LEADER', id: idRef.current });
        if(timeoutRef.current?.election) clearTimeout(timeoutRef.current.election);
        timeoutRef.current.election = window.setTimeout(initiateElection, 2000);
        break;
      default:
        break;
    }
  }, [initiateElection, setLeader]);

  const resign = useCallback(() => {
    channelRef.current?.postMessage({ type: "RESIGN", id: idRef.current });
    if(channelRef.current) {
      channelRef.current.removeEventListener('message', handleMessage);
    }
    setLeader('');
  }, [handleMessage, setLeader]);

  useLayoutEffect(() => {
    leaderRef.current = leader;
  }, [leader]);

  useLayoutEffect(() => {
    if(!channelRef.current) channelRef.current = new BroadcastChannel("audio-alert-leader-election-channel");
    channelRef.current.addEventListener("message", handleMessage);
    channelRef.current?.postMessage({ type: 'IS_LEADER', id: idRef.current });
    if(timeoutRef.current?.election) {
      clearTimeout(timeoutRef.current.election);
      timeoutRef.current.election = undefined;
    }
    timeoutRef.current.election = window.setTimeout(initiateElection, 2000);

    return () => {
      channelRef.current?.removeEventListener("message", handleMessage);
      if (timeoutRef.current?.election) {
        clearTimeout(timeoutRef.current.election); // eslint-disable-next-line
        timeoutRef.current.election = undefined;
      }
      if (timeoutRef.current?.selfLeader) {
        clearTimeout(timeoutRef.current.selfLeader); // eslint-disable-next-line
        timeoutRef.current.selfLeader = undefined;
      }
    };
  }, [handleMessage, initiateElection]);

  useEffect(() => {
    if(!isLeader) return;
    window.addEventListener('beforeunload', resign);
    return () => {
      window.removeEventListener('beforeunload', resign);
      setLeader('');
    }
  }, [isLeader, resign]);

  return isLeader;
}

export default useLeaderTab;
