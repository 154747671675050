import { useMemo } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import _ from "lodash";
import { IPitInfo, ISessionDriver, ISessionDrivers } from "@mahindraformulae/rubicon-hooks";
import { RootState } from "redux/store";

interface TableDataType {
  driverNo: number,
  name: string,
  inPit: boolean,
};

interface DriversStatusInfoPropTypes {
  isPopUp?: boolean;
}

const DriversStatusInfo: React.FC<DriversStatusInfoPropTypes> = ({ isPopUp = false }) => {
  const pitInOutData: IPitInfo[] = useSelector((state: RootState) => state.global.pitInOut);
  const __driverMap: ISessionDrivers | null = useSelector((state: RootState) => state.global.sessionInfo);
  
  const tableData: TableDataType[] = useMemo(() => {
    if(!__driverMap || !pitInOutData.length || isPopUp) return [];
    const groupedPitInOutWithoutSort = _(pitInOutData).groupBy("driverNo");
    const groupedPitInOutData = groupedPitInOutWithoutSort.mapValues(group => _.orderBy(group, ["ts"], ["desc"])).value();
    const driversInPit = _.map(groupedPitInOutData, group => _.head(group)).reduce((driverNos: number[], driver) => (driver && driver?.pitType === 'PIT_IN' ? [...driverNos, driver?.driverNo] : driverNos), []);
    return Object.values(__driverMap).map((driverObj: ISessionDriver) => {
      const driverNo = driverObj.driverNo;
      const name = driverObj.name;
      const inPit = driversInPit.includes(driverNo);
      return { driverNo, name, inPit };
    });
  }, [pitInOutData, __driverMap, isPopUp]);

  if(isPopUp) return null;

  return (
    <div className="h-10 grid grid-flow-col grid-rows-2 gap-y-1 items-center py-0.5">
      {tableData.length ? tableData.map((row) => 
        <span className="mr-0.5 text-xs" key={row.driverNo}>
          {row.name}
          <span className={classNames("text-xs leading-none ml-0.5 px-0.5 border bg-opacity-25", { "border-green-800 bg-green-600": !row.inPit, "border-red-800 bg-red-600": row.inPit })}>
            {row.inPit ? "P" : "T"}
          </span>
        </span>
      ) : <span>No data found!</span>}
    </div>
  );
};

export default DriversStatusInfo;
