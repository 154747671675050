import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import { RootState } from "@/redux/store";
import { ICar } from "@mahindraformulae/rubicon-hooks";

function Reminders() {
  const reminders = useSelector((state: RootState) => state.global.reminders);
  const selectedCars = useSelector((state: RootState) => state.global.selectedCars);
  const [carToShow, setCarToShow] = useState<ICar[]>([]);
  const OUR_CARS =useSelector((state:RootState)=>state.global.ourCars);
  
  useEffect(() => {
    const checkedCar = selectedCars.filter((c: any) => c.checked);
    if (checkedCar) {
      setCarToShow(checkedCar.map((d: any) => d.number));
    }
  }, [selectedCars]);
  return (
    <div className="container h-100">
      {/* <div className="row text-center justify-content-center mb-1">
            <div className="col-xl-6 col-lg-8">
                <DriverSelector parentName="Reminders" onDriversSelectionChange={onDriversSelectionChange}/>
            </div>
        </div> */}
      <h4 className="font-bold border-b border-gray-600 mb-1 pb-1 text-sm">
        Reminders
      </h4>
      <div className="over-y" style={{ height: "calc(100% - 36px)" }}>
        <ul className="text-sm">
          {reminders
            .filter((d: any) => carToShow.includes(d.car) || d.car === 0)
            .map(
              ({ id, backgroundColor, dayTime, text, highlight, color, car, }: any) => {
                const cNames = classNames("list-group-item text-break", { highlight: !!highlight, });
                let styleObj: any = {
                  borderLeft: `10px solid ${backgroundColor}`,
                };
                if (highlight) styleObj.backgroundColor = color;
                return (
                  <li key={id} className={cNames} style={styleObj}>
                    <span className="block p-1 pl-4 border-b border-gray-600">
                      {moment(dayTime).fromNow()}
                      &nbsp;|&nbsp;
                      {`${OUR_CARS.find((d: any) => d.number === car)
                        ?.shortName ?? ""
                        } ${text}`}{" "}
                    </span>
                  </li>
                );
              }
            )
            .reverse()}
        </ul>
      </div>
    </div>
  );
}

export default Reminders;
