import { configureStore, ThunkAction, Action, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import authReducer from 'redux/modules/auth';
import globalReducer from 'redux/modules/global';
import qualiReducer from '../components/QualiTimer/qualiReducer';
import mainTabsReducer, { updateLayoutForThePage, addPage, removeCompFromPage, removePage, setUserLayout, setCurrPage, addCompToPage, updateTab } from '../components/CustomLayout/customLayoutReducer';
import {
  createStateSyncMiddleware,
  initMessageListener,
  initStateWithPrevTab
} from 'redux-state-sync'
const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: isAnyOf(updateLayoutForThePage, addPage, removeCompFromPage, removePage, setCurrPage, addCompToPage, updateTab), effect: async (action, listenerApi: any) => {
    const { mainTabs } = listenerApi.getState();
    listenerApi.dispatch(setUserLayout(mainTabs.pages));
  },
});

export const isLinkedSession = !localStorage.getItem('linkedSessionFlag');

const stateSyncMiddleWare = isLinkedSession
  ? createStateSyncMiddleware(
    {
      whitelist: [
        'global/setSelectedEvent',
        'global/setLocalReplay',
        'global/flushGlobalData',
        'global/updateUserPreferences',
        'global/reloadPage',
      ],
    })
  : createStateSyncMiddleware(
    {
      whitelist: [
        'global/reloadPage',
      ],
    })
export const store = configureStore({
  reducer: {
    auth: authReducer,
    global: globalReducer,
    quali: qualiReducer,
    mainTabs: mainTabsReducer,
  }, middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
    .concat(listenerMiddleware.middleware)
    .concat(stateSyncMiddleWare),
});
initMessageListener(store); // If unexpected behaviour is observed try this
// initStateWithPrevTab(store); // this is better because it initializes redux state with values from previous tab, initial page load times expected to decrease

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
