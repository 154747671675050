export const createTickValues = (data, ticks) => {
  const num = Math.round(data.length / ticks);
  // console.log("data", data, data.length, num);
  const arr = [];
  if (num > 0) {
    arr.push(data[0]);
    for (let i = num; i < data.length; i = i + num) {
      if (data[i]) {
        arr.push(data[i]);
      }
    }
    // console.log('arr', arr)
    // arr.push(data[data.length - 1]);
  }
  return arr;
  // return [];
};
