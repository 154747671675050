import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import "./Weather.css";

function TrackWeather() {
  const weatherInfo = useSelector(
    (state: RootState) => state.global.weatherInfo
  );
  const displayDate = (date: number) => {
    const _momentObj = moment(date);
    const isPastDate: boolean = _momentObj.isBefore(Date.now(), "day");
    if (isPastDate) {
      return _momentObj.format("MMM Do YYYY, HH:mm:ss");
    } else {
      return _momentObj.format("HH:mm:ss");
    }
  };

  return (
    <div>
      <h4 className="font-bold border-b border-gray-600 mb-4 pb-2">Weather Summary</h4>
      <table className="wt wt-striped w-full">
        <tbody>
          <tr>
            <th>Last updated</th>
            <td>
              {weatherInfo && weatherInfo?.dayTime
                ? displayDate(weatherInfo?.dayTime)
                : "-"}
            </td>
          </tr>
          <tr>
            <th>Ambient Temperature</th>
            <td>{weatherInfo?.ambientTemperature?.toFixed(3) ?? `-`}</td>
          </tr>
          <tr>
            <th>Humidity</th>
            <td colSpan={2}>{weatherInfo?.humidity?.toFixed(3) ?? `-`}</td>
          </tr>
          <tr>
            <th>Pressure</th>
            <td colSpan={2}>{weatherInfo?.pressure?.toFixed(3) ?? `-`}</td>
          </tr>
          <tr>
            <th>Track Temperature</th>
            <td colSpan={2}>
              {weatherInfo?.trackTemperature?.toFixed(3) ?? `-`}
            </td>
          </tr>
          <tr>
            <th>Wind Speed</th>
            <td colSpan={2}>{weatherInfo?.windSpeed?.toFixed(3) ?? `-`}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TrackWeather;
