import { useCallback, useEffect, useState } from "react";

export interface IBaseWorkerResponse<T> {
  result: T;
  error?: any;
}

export const useWebWorker = <TResult, TWorkerPayload>(worker: Worker) => {
  const [running, setRunning] = useState(false);
  const [error, setError] = useState<string>();
  const [result, setResult] = useState<TResult>();

  const startProcessing = useCallback((data: TWorkerPayload) => {
    setRunning(true);
    worker.postMessage(data);
  }, [worker]);

  useEffect(() => {
    const onMessage = (event: MessageEvent<IBaseWorkerResponse<TResult>>) => {
      // console.log(event);
      if (event.data.error) {
        console.error(event.data.error)
      }
      setRunning(false);
      setError(event.data.error?JSON.stringify(event.data.error):undefined);
      setResult(event.data.result);
    };
    worker.onmessage = onMessage;
    return () => worker.terminate();
  }, [worker]);


  return {
    startProcessing,
    running,
    error,
    result,
  };
};