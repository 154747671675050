import {useSelector} from 'react-redux';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {RootState} from 'redux/store';

const ProtectedRoute = () => {
  const userInfo = useSelector((state: RootState) => state.auth.user);
  const location = useLocation();
  if (!userInfo) {
    return (<Navigate to="/login" state={{path: location.pathname}}/>);
  }
  return <Outlet/>;
};
export default ProtectedRoute;