const TOOLTIP_DEFAULTS = {
  style: {
    textColor: "black",
    backGroundColor: "white",
    border: "black",
  },
};

const ALEA_CHART_TYPES = {
  DOT: "dot",
  LINE: "line",
  RECT: "rect",
  TEXT: "text",
};

const SHAPE_WIDTH_TYPES = {
  VALUE: "value",
  WIDTH: "width",
};

const AXIS_SCALES = {
  LINEAR: "linear",
  DATETIME: "dateTime",
  ORDINAL: "ordinal",
  BAND: "band",
};

const THEMES = {
  DARK: "dark",
  LIGHT: "light",
};

const margin = { top: 10, right: 30, bottom: 30, left: 60 };
const width = 460 - margin.left - margin.right;
const height = 400 - margin.top - margin.bottom;

const LAYOUT = {
  margin,
  width,
  height,
};

const ALEA_COLOR = ['#5687F4', '#884DE3', '#ADD37C', '#E8DB9C', '#CD2318']

export {
  ALEA_CHART_TYPES,
  SHAPE_WIDTH_TYPES,
  AXIS_SCALES,
  THEMES,
  TOOLTIP_DEFAULTS,
  LAYOUT,
  ALEA_COLOR
};