import { useState, useEffect } from "react";
import { getDisplayHMSTime } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { RootState } from "@/redux/store";
import { useMemo } from "react";

function RaceControlMessages({ enableDriverSelection }: any) {
  const raceControlMessages = useSelector(
    (state: RootState) => state.global.raceControlMessages
  );
  const selectedCars = useSelector(
    (state: RootState) => state.global.selectedCars
  );

  const [messages, setMessages] = useState(raceControlMessages);

  // let _raceControlMessages = raceControlMessages.slice(MSG_COUNT);
  // _raceControlMessages = _raceControlMessages.reverse();

  useEffect(() => {
    let _raceControlMessages = [...raceControlMessages];
    _raceControlMessages = _raceControlMessages.reverse();
    const checkedCars = selectedCars.filter((c: any) => c.checked === true);
    if (checkedCars.length) {
      _raceControlMessages = _raceControlMessages.map((msg) => {
        for (let i = 0; i < checkedCars.length; i++) {
          if (
            msg.text
              .toLowerCase()
              .includes(checkedCars[i].shortName.toLowerCase())
          ) {
            return { ...msg, highlight: true, ...checkedCars[i] };
          }
        }
        return { ...msg };
      });
    }
    setMessages(_raceControlMessages);
  }, [raceControlMessages, selectedCars]);
  return (
    <div className="container h-100">
      {/* {!enableDriverSelection && <DragMe />} */}
      {/*{enableDriverSelection ? <div className="row text-center justify-content-center mb-1">
            <div className="col-xl-6 col-lg-8">
                <DriverSelector parentName="RaceControlMessages" onDriversSelectionChange={onDriversSelectionChange}/>
            </div>
        </div> : null}*/}
      <h4 className="font-bold border-b border-gray-600 mb-1 pb-1 text-sm">
        Race Control Messages
      </h4>
      <div className="over-y" style={{ height: "calc(100% - 36px)" }}>
        <ul className="text-sm">
          {(enableDriverSelection ? messages : [...raceControlMessages].reverse()).map(
            ({ id, backgroundColor, ts, dayTime, text, highlight, color }: any) => {
              const cNames = classNames("text-break border-b border-gray-800 last:border-b-0", {
                highlight: !!highlight,
              });
              let styleObj: any = {
                borderLeft: `10px solid ${backgroundColor}`,
                padding: "5px 0px",
                paddingLeft: '15px'
              };
              if (highlight) styleObj.backgroundColor = color;
              return (
                <li key={id} className={cNames} style={styleObj}>
                  <span
                    style={{
                      width: "100px",
                      display: "inline-block",
                      paddingRight: "15px",
                    }}
                  >
                    {getDisplayHMSTime(ts||dayTime, "HH:mm:ss")}
                  </span>
                  <span>{text}</span>
                </li>
              );
            }
          )}
        </ul>
      </div>
    </div>
  );
}

export default RaceControlMessages;
