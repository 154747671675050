import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "services/auth";
import sessionService from "services/session";
import eventService from "services/event";
import config from "config";
import {
  ISessionInfo, ISessionEntry, IPitInfo, IAttackSummary,
  ICar,
  ILap,
  ILiveFlags,
  ILoop,
  IRaceControl,
  IRaceEvent,
  IRadioMessage,
  IWeather,
  ISessionDrivers,
  IReminders,
  ISector,
  IUserAuthData,
  IAttackMode, IInterventions, IYellowFlag, modifySessionEntry, IPowerMode, IPreferencesEntity, createDriverMap
} from "@mahindraformulae/rubicon-hooks";
import {
  IResponse,
  LocalReplayInfo,
} from "@/types";
import { cloneDeep } from "lodash";
import { getUniqueTSByDriver, mapSectorsToLaps } from "common";
import { REMEMBERED_EVT_PREFERENCE_MODULE_KEY } from "components/layout/Header/EventSelector";

const SINGLE_SELECTED_DRIVER_LOCALSTORAGE = "rubicon_singleSelectedDriver";

const getSingleSelectedDriver = (seasonId: string) => {
  try {
    const driverObjItem = window.localStorage.getItem(SINGLE_SELECTED_DRIVER_LOCALSTORAGE);
    const driverObj: Record<string, number> = driverObjItem ? JSON.parse(driverObjItem) : {};
    return Object.hasOwn(driverObj, seasonId) ? driverObj[seasonId] : 0;
  } catch (error) {
    console.error(error);
    return 0;
  }
}

const setSingleSelectedDriver = (seasonId: string, driverNo: number) => {
  try {
    const driverObjItem = window.localStorage.getItem(SINGLE_SELECTED_DRIVER_LOCALSTORAGE);
    const driverObj: Record<string, number> = driverObjItem ? JSON.parse(driverObjItem) : {};
    driverObj[seasonId] = driverNo;
    return window.localStorage.setItem(SINGLE_SELECTED_DRIVER_LOCALSTORAGE, JSON.stringify(driverObj));
  } catch (error) {
    console.error(error);
    return false;
  }
}

const selectedCars = (userAuthInfo: IUserAuthData | null, OUR_CARS: ICar[]) => {
  return OUR_CARS.map((e: ICar) => {
    const savedPref = userAuthInfo?.preferences?.preferences || [];
    let checked = false;
    const oldPref = savedPref.find(
      (p) => p.module === config.EP_GLOBAL_PARENT_NAME
    );
    if (oldPref) checked = !!oldPref?.preferences?.includes(e.number);
    return { ...e, checked };
  });
};

export const getUserAccessInfo = createAsyncThunk<IUserAuthData, undefined>(
  "global/accessInfo",
  async (arg, thunkAPI) => {
    try {
      return await authService.accessInfo();
    } catch (error: any) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSessionEntry = createAsyncThunk(
  "global/sessionEntry",
  async (selectedEvent: IRaceEvent, thunkAPI) => {
    try {
      const input: ISessionEntry[] = await sessionService.getSessionDrivers(
        selectedEvent
      );
      return input;
    } catch (error: any) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const setSessionEntryFromSocket = createAsyncThunk(
  "global/sessionEntryFromSocket",
  async (socketData: ISessionEntry[], thunkAPI) => {
    try {
      const { seasonColors = [] } = await sessionService.getCurrentSeason() ?? {}
      return modifySessionEntry(socketData, seasonColors);
    } catch (error: any) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSessionInfoDetails = createAsyncThunk(
  "global/sessionInfoDetails",
  async (selectedEvent: IRaceEvent, thunkAPI) => {
    try {
      const input: ISessionInfo[] = await sessionService.getSessionInfoDetails(
        selectedEvent
      );
      return input;
    } catch (error: any) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllEvents = createAsyncThunk(
  "global/allEvents",
  async (arg, thunkAPI) => {
    try {
      const { data }: IResponse<IRaceEvent[]> =
        await eventService.getAllEvents();
      return data;
    } catch (error: any) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const manageAllEventsData = (state: any, allEvents: IRaceEvent[]) => {
  try {
    const parentEvents = allEvents.filter((el) => el.parentId === null);
    for (let i = 0, iLen = parentEvents.length; i < iLen; i++) {
      const { raceEventId } = parentEvents[i];
      parentEvents[i].events = allEvents.filter(
        (el) => el.parentId === raceEventId
      );
    }
    state.allEventsPrimary = parentEvents;
    state.allEvents = parentEvents;
  } catch (err) {
    console.log("err", err);
  }
};

const setDefaultSelectedEvent = (state: globalState, allEvents: IRaceEvent[], preferences: IPreferencesEntity[]) => {
  try {
    const defaultSelectedEventId = preferences.find((preference) => preference.module === REMEMBERED_EVT_PREFERENCE_MODULE_KEY)?.settings?.defaultEventId;
    const rememberSelectedEvent = preferences.find((preference) => preference.module === REMEMBERED_EVT_PREFERENCE_MODULE_KEY)?.settings?.rememberSelectedEvent;
    const event = rememberSelectedEvent && defaultSelectedEventId ? allEvents.find((ev) => ev.raceEventId === defaultSelectedEventId) : allEvents.find((ev) => ev.isCurrent);
    if (event?.raceEventId && event?.seasonId) {
      state.selectedEvent = event;
      state.currentSeason = event.seasonId;
    }
  } catch (err) {
    console.log("err", err);
  }
}
export const globalSlice = createSlice({
  name: "global",
  initialState: {
    addedLaps: 0,
    allEventsPrimary: [] as IRaceEvent[],
    allEvents: [] as IRaceEvent[],
    allSelectableEvents: [] as IRaceEvent[],
    attackMode: [] as IAttackMode[],
    attackSummary: [] as IAttackSummary[],
    currentSeason: 'S09' as string,
    driverDDState: false,
    flagMessages: [] as ILiveFlags[],
    interventions: {} as IInterventions,
    laps: [] as ILap[],
    lapsDash: {},
    lapsWithSectors: [],
    localReplay: {} as LocalReplayInfo,
    loops: [] as ILoop[] | [],
    ourCars: [] as ICar[],
    pitInOut: [] as IPitInfo[],
    powerMode: [] as IPowerMode[],
    raceControlMessages: [] as IRaceControl[],
    reminders: [] as IReminders[],
    replayToSync: false,
    selectedCars: selectedCars(null, config.OUR_CARS) as ICar[],
    sectors: [] as ISector[],
    selectedMasterEvent: {} as IRaceEvent,
    selectedEvent: {} as IRaceEvent,
    selectedDriver: 0,
    sessionEntry: [] as ISessionEntry[],
    sessionInfo: null as ISessionDrivers | null,
    sessionInfoDetails: [] as ISessionInfo[],
    userAuthData: {} as IUserAuthData | null,
    weatherInfo: {} as IWeather,
    yellowFlags: [] as IYellowFlag[],
  },
  reducers: {
    logUserOut: (state) => {
      state.userAuthData = null;
    },
    updateUserPreferences: (state, action) => {
      if (state.userAuthData) {
        state.userAuthData.preferences = action.payload;
        state.selectedCars = selectedCars(state.userAuthData, state.ourCars);
      }
    },
    setSelectedEvent: (state, action) => {
      state.sessionInfo = null;
      state.sessionEntry = [];
      state.selectedEvent = action.payload;
      state.currentSeason = action.payload.seasonId;
      state.selectedDriver = getSingleSelectedDriver(action.payload.seasonId);
    },
    getFilteredEvents: (state, action) => {
      const searchStr = action.payload.trim().toLowerCase();
      if (searchStr) {
        const filteredEvents = [];
        const allEventsPrimary = cloneDeep(state.allEventsPrimary);
        for (const event of allEventsPrimary) {
          const filteredChildEvents = event.events?.filter((childEvent) =>
            childEvent.raceEventName.toLowerCase().includes(searchStr)
          );
          if (filteredChildEvents && filteredChildEvents.length > 0) {
            event.events = filteredChildEvents;
            filteredEvents.push(event);
          }
        }
        state.allEvents = filteredEvents;
      } else {
        state.allEvents = state.allEventsPrimary;
      }
    },
    setAttackSummary: (state, action) => {
      state.attackSummary = action.payload;
    },
    setRaceControlData: (state, action) => {
      const socketData = action.payload;
      let _raceControlMessages: IRaceControl[] = cloneDeep(
        state.raceControlMessages
      );
      for (const newMsg of socketData) {
        _raceControlMessages.push(newMsg);
      }
      _raceControlMessages = _raceControlMessages.filter(
        (el, index) =>
          _raceControlMessages.findIndex(
            (innerElem) => innerElem.id === el.id
          ) === index
      );
      state.raceControlMessages = _raceControlMessages.sort(
        (a, b) => a.ts - b.ts
      );
    },
    setLiveFlagData: (state, action) => {
      const socketData = action.payload;
      let _flagMessages: ILiveFlags[] = cloneDeep(state.flagMessages);
      for (const newMsg of socketData) {
        _flagMessages.push(newMsg);
      }
      _flagMessages = _flagMessages.filter(
        (el, index) =>
          _flagMessages.findIndex((innerElem) => innerElem.ts === el.ts) ===
          index
      );
      state.flagMessages = _flagMessages.sort((a, b) => a.ts - b.ts);
    },
    setReminderData: (state, action) => {
      const curr_reminders = action.payload;
      let _reminders: IReminders[] = cloneDeep(curr_reminders);
      // _reminders=[...curr_reminders,..._reminders]
      // _reminders=_reminders.filter((el, index) => _reminders.findIndex(innerElem => innerElem.id === el.id) === index);
      if (_reminders.length !== 0) {
        state.reminders = _reminders.sort(
          (a, b) => a["dayTime"] - b["dayTime"]
        );
      } else {
        state.reminders = [];
      }
    },
    setInterventionData: (state, action) => {
      state.interventions = action.payload;
    },
    setYellowFlags: (state, action) => {
      state.yellowFlags = action.payload;
    },
    setLapsDash: (state, action) => {
      state.lapsDash = action.payload;
    },
    setLapData: (state, action) => {
      const socketData = action.payload;
      const _laps: ILap[] = cloneDeep(state.laps);
      for (const newMsg of socketData) {
        _laps.push(newMsg);
      }
      let sortedLaps = _laps.sort((a, b) => a.ts - b.ts);
      state.laps = getUniqueTSByDriver(sortedLaps);
      const { sectors, laps } = mapSectorsToLaps(state.sectors, state.laps);
      state.lapsWithSectors = laps;
      state.sectors = sectors;
    },
    setSessionInfoDetails: (state, action) => {
      state.sessionInfoDetails = [...state.sessionInfoDetails, ...action.payload]
    },
    setSectorData: (state, action) => {
      const socketData = action.payload;
      const _sectors: ISector[] = cloneDeep(state.sectors);
      for (const newMsg of socketData) {
        _sectors.push(newMsg);
      }
      let sortedSectors = _sectors.sort((a, b) => a.ts - b.ts);
      const __sectors = getUniqueTSByDriver(sortedSectors);
      const { sectors, laps } = mapSectorsToLaps(__sectors, state.laps);
      state.lapsWithSectors = laps;
      state.sectors = sectors;
    },
    setLoopData: (state, action) => {
      const socketData = action.payload;
      const _loops: ILoop[] = cloneDeep(state.loops);
      for (const newMsg of socketData) {
        _loops.push(newMsg);
      }
      let sortedLoops = _loops.sort((a, b) => a.ts - b.ts);
      state.loops = getUniqueTSByDriver(sortedLoops);
    },
    setPitInOutData: (state, action) => {
      const pitData = action.payload;
      const _pitInOutData: IPitInfo[] = cloneDeep(state.pitInOut);
      for (const newMsg of pitData) {
        _pitInOutData.push(newMsg);
      }
      state.pitInOut = _pitInOutData.sort((a, b) => a.ts - b.ts);
    },
    setAttackModeData: (state, action) => {
      const attackData = action.payload;
      const attackModeData: IAttackMode[] = cloneDeep(state.attackMode);
      for (const newMsg of attackData) {
        attackModeData.push(newMsg);
      }
      state.attackMode = attackModeData.sort((a, b) => a.ts - b.ts);
    },
    setPowerModeData: (state, action) => {
      const attackData = action.payload;
      const powerModeData: IPowerMode[] = cloneDeep(state.powerMode);
      for (const newMsg of attackData) {
        powerModeData.push(newMsg);
      }
      state.powerMode = powerModeData.sort((a, b) => a.ts - b.ts);
    },
    setWeatherData: (state, action) => {
      const socketData = action.payload;
      if (socketData.length) {
        state.weatherInfo = socketData[0];
      }
    },
    setAddedLapsData: (state, action) => {
      state.addedLaps = action.payload;
    },
    setLocalReplay: (state, action) => {
      if (!action.payload.deltaTS) {
        state.localReplay = { ...action.payload };
      } else {
        state.localReplay.deltaTS = action.payload.deltaTS;
      }
    },
    setReplayToSync: (state, action) => {
      state.replayToSync = action.payload;
    },
    setOurCars: (state, action) => {
      if (action.payload.length > 0) {
        if (!state.selectedDriver) {
          let selectedDriver = getSingleSelectedDriver(state.selectedEvent.seasonId);
          if (!selectedDriver) {
            selectedDriver = action.payload[0].number;
            setSingleSelectedDriver(state.selectedEvent.seasonId, selectedDriver);
          }
          state.selectedDriver = selectedDriver;
        }

        let _ourCars: ICar[] = cloneDeep(config.OUR_CARS);

        _ourCars.forEach((driver, index) => {
          driver.number = action.payload?.[index].number;
          driver.shortName = action.payload?.[index].shortName;
          driver.name = action.payload?.[index].name;
        });

        state.ourCars = _ourCars;
        state.selectedCars = selectedCars(state.userAuthData, _ourCars);
      }

    },
    setLocalReplayGlobalData: (state, action) => {
      let { LiveLap = [], LiveFlags = [], RaceControll = [], LiveSector = [], LiveLoop = [], LivePitInfo = [], AttackMode = [], PowerMode = [] } = action.payload;

      state.raceControlMessages = RaceControll;
      state.flagMessages = LiveFlags;
      state.pitInOut = LivePitInfo;
      state.attackMode = AttackMode;
      state.powerMode = PowerMode;

      state.laps = getUniqueTSByDriver(LiveLap);

      const __sectors = getUniqueTSByDriver(LiveSector);
      const { sectors, laps } = mapSectorsToLaps(__sectors, state.laps);
      state.sectors = sectors;
      state.lapsWithSectors = laps;

      state.loops = getUniqueTSByDriver(LiveLoop);
    },
    flushGlobalData: (state) => {
      // console.log("cleared")
      state.raceControlMessages = [];
      state.flagMessages = [] as ILiveFlags[];
      state.reminders = [];
      state.interventions = {} as IInterventions;
      state.laps = [];
      state.loops = [];
      state.sectors = [];
      state.attackSummary = [];
      state.attackMode = [];
      state.powerMode = [];
      state.addedLaps = 0;
      state.ourCars = [];
      state.localReplay = {} as LocalReplayInfo;
      state.yellowFlags = [];
    },
    enableDriverDD: (state, action) => {
      state.driverDDState = action.payload;
    },
    setDriver: (state, action) => {
      setSingleSelectedDriver(state.selectedEvent.seasonId, action.payload);
      state.selectedDriver = action.payload;
    },
    reloadPage: () => {
      window.location.reload();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAccessInfo.fulfilled, (state, { payload }) => {
      state.userAuthData = payload;
      state.selectedCars = selectedCars(payload, state.ourCars);
      const allEvents = state.allSelectableEvents.length ? state.allSelectableEvents : undefined;
      const preferences = payload?.preferences?.preferences?.length ? payload?.preferences?.preferences : undefined;
      if (allEvents && preferences) setDefaultSelectedEvent(state, allEvents, preferences);
    });
    builder.addCase(getUserAccessInfo.rejected, (state) => {
      state.userAuthData = null;
    });
    builder.addCase(getSessionEntry.fulfilled, (state, { payload }) => {
      state.sessionEntry = payload;
      state.sessionInfo = createDriverMap(payload)
    });
    builder.addCase(getSessionEntry.rejected, (state) => {
      state.sessionEntry = [];
      state.sessionInfo = null;
    });
    builder.addCase(setSessionEntryFromSocket.fulfilled, (state, { payload }) => {
      state.sessionEntry = [...state.sessionEntry, ...payload];
      state.sessionInfo = createDriverMap(state.sessionEntry)
    });
    // missing rejected case is intentional
    builder.addCase(getSessionInfoDetails.fulfilled, (state, { payload }) => {
      state.sessionInfoDetails = payload;
    });
    builder.addCase(getSessionInfoDetails.rejected, (state) => {
      state.sessionInfoDetails = [];
    });
    builder.addCase(getAllEvents.fulfilled, (state, { payload }) => {
      state.allSelectableEvents = payload;
      manageAllEventsData(state, payload);
      const allEvents = payload.length ? payload : undefined;
      const preferences = state?.userAuthData?.preferences?.preferences?.length ? state?.userAuthData?.preferences?.preferences : undefined;
      if (allEvents && preferences) setDefaultSelectedEvent(state, allEvents, preferences);
    });
    builder.addCase(getAllEvents.rejected, (state) => {
      state.allSelectableEvents = []
      state.allEvents = [];
    });
  },
});

export const {
  logUserOut,
  updateUserPreferences,
  setSelectedEvent,
  getFilteredEvents,
  setAttackSummary,
  setRaceControlData,
  setLiveFlagData,
  setReminderData,
  setInterventionData,
  setLapData,
  setSectorData,
  setWeatherData,
  enableDriverDD,
  setDriver,
  flushGlobalData,
  setLoopData,
  setPitInOutData,
  setAttackModeData,
  setPowerModeData,
  setSessionInfoDetails,
  setAddedLapsData,
  setLocalReplay,
  setOurCars,
  setLocalReplayGlobalData,
  setReplayToSync,
  setLapsDash,
  setYellowFlags,
  reloadPage,
} = globalSlice.actions;

export type globalState = ReturnType<typeof globalSlice.getInitialState>;
export default globalSlice.reducer;
