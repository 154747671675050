import { Suspense, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { Login, Logout } from "pages";
import ProtectedRoute from "components/ProtectedRoute";
import COMPONENTS from "config/compo-config";
import { RootState } from "redux/store";
import { IComponent } from "types";
import LoadingScreen, { RotatingWheel } from "./components/LoadingScreen";
import { ErrorBoundary } from "react-error-boundary";
import useWindowZoom from "./utils/useWindowZoom";

function RubiconRoutes() {
  const windowZoom = useWindowZoom();
  const userAuthInfo = useSelector((state: RootState) => state.global.userAuthData);
  const AUTHORISED_PAGES = useMemo(() => COMPONENTS.filter((c: IComponent) => c.href && c.component).filter((menu: IComponent) => userAuthInfo?.pages?.includes(menu.key.toUpperCase())), [userAuthInfo]);
  const NOT_AUTHORISED_PAGES = useMemo(() => COMPONENTS.filter((c: IComponent) => c.href && c.component).filter((menu: IComponent) => !userAuthInfo?.pages?.includes(menu.key.toUpperCase())), [userAuthInfo]);

  const location = useLocation();
  
  useEffect(() => {
    const pageLabel = AUTHORISED_PAGES.find(p => p.href === location.pathname)?.label;
    const hasAudio = document.title.endsWith('🔊');
    document.title = (pageLabel ?? 'Rubicon') + (hasAudio && pageLabel ? " 🔊" : '');
  }, [location, AUTHORISED_PAGES])

  return (<Routes>
    <Route path="/login" element={<Login />}></Route>
    <Route path="/logout" element={<Logout />}></Route>
    {userAuthInfo === null
      ? <Route path="*" element={<WelcomeUser />} />
      : <>
        <Route element={<ProtectedRoute />}>
          {AUTHORISED_PAGES.map(({ key, href, component: Comp, label }: IComponent) => <Route key={key} path={href} element={
            <Suspense fallback={<LoadingScreen />}>
              <ErrorBoundary FallbackComponent={ErrorPage}>
                {Comp &&
                  <div className="h-full w-full" style={{ zoom: windowZoom }}>
                    <Comp />
                  </div>}
              </ErrorBoundary>
            </Suspense>
          }></Route>)}
          {NOT_AUTHORISED_PAGES.map(({ key, href }: IComponent) => <Route key={key} path={href} element={<UnauthorisedAccess />}></Route>)}
        </Route>
        <Route path="*" element={<DoesNotExistPage />} />
      </>
    }
  </Routes>);
}
function ErrorPage({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: () => void }) {
  return <>
    <div className="flex flex-col justify-center items-center h-full select-none gap-2">
      <h2>Oops! Something Went Wrong</h2>
      <p><i>We apologize for the inconvenience. Kindly:</i></p>
      <p>
        <a className="underline" onClick={resetErrorBoundary}>Try Again?</a>
      </p>
      <p>
        Or Contact the developer team to have this issue fixed.
      </p>
      <p>Email: <a className="underline" href="mailto:somyaneel.sinha@mahindrafe.com">somyaneel.sinha@mahindrafe.com</a></p>

      <p>{error.message}</p>
    </div>
  </>
}
export function WelcomeUser(props: any) {
  return <>
    <div className="flex flex-col justify-center items-center h-full select-none">
      <div>{RotatingWheel}</div>
      <b className="text-[100px]">Welcome to Rubicon!</b>
      <h2 className="mb-5"><i>Let the die be cast...</i></h2>
      {
        props.children ||
        <>
          <p>Kindly contact Admin to be granted access</p>
          <p>or Email: <a className="underline" href="mailto:somyaneel.sinha@mahindrafe.com">somyaneel.sinha@mahindrafe.com</a></p>
        </>
      }
    </div>
  </>
}

function DoesNotExistPage() {
  return <>
    <div className="flex flex-col justify-center items-center h-full select-none">
      <b className="text-[100px]">404</b>
      <h2><i>No such page exists... yet.</i></h2>
      <p>Feel free to contact developer team if you have an Idea!</p>
      <p>Email: <a className="underline" href="mailto:somyaneel.sinha@mahindrafe.com">somyaneel.sinha@mahindrafe.com</a></p>
    </div>
  </>
}

function UnauthorisedAccess() {
  return <>
    <div className="flex flex-col justify-center items-center h-full select-none">
      <b className="text-[100px]">401</b>
      <h2><i>You are not authorised to access this page!</i></h2>
      <p>Contact the developer team to be granted relevant access.</p>
      <p>Email: <a className="underline" href="mailto:somyaneel.sinha@mahindrafe.com">somyaneel.sinha@mahindrafe.com</a></p>
    </div>
  </>
}

export default RubiconRoutes;