import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

function ChartWindrose(props) {
  const { data = {} } = props;
  const mainBlockRef = useRef();
  const ref = useRef();
  const [isDarkTheme, setTheme] = useState(window.matchMedia("(prefers-color-scheme: dark)").matches);

  let { width = 400, height = 558 } = props;
  if (mainBlockRef.current) {
    width = mainBlockRef?.current?.clientWidth || 400;
    height = mainBlockRef?.current?.clientHeight || 400;
  }

  //#region handle system theme
  useEffect(() => {
    const fn = ({ matches }) => {
      if (matches) {
        setTheme(true);
      } else {
        setTheme(false);
      }
    };

    // if (!theme) {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", fn);

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", fn);
    };
    // }
  }, []);
  //#endregion

  useEffect(() => {
    const conciseData = data.map((x) => [
      x.weatherOutdoor.windDirAvgLast2Min,
      x.weatherOutdoor.windSpeedAvgLast2Min,
    ]);

    const cx = width / 2;
    const cy = height / 2;
    const r = Math.min(cx, cy) * 0.9;
    const rText = 0.88 * r;
    const hText = r / 10;
    const rInner = r * 0.76;

    const svgElement = d3.select(ref.current).style("background", isDarkTheme ? "#1f2937" : "white");

    svgElement
      .append("circle")
      .attr("cx", cx)
      .attr("cy", cy)
      .attr("r", r)
      .attr("stroke", isDarkTheme ? "white" : "#1f2937")
      .attr("fill", isDarkTheme ? "white" : "#1f2937");

    svgElement
      .append("circle")
      .attr("cx", cx)
      .attr("cy", cy)
      .attr("r", rInner)
      .attr("stroke", isDarkTheme ? "#1f2937" : "white")
      .attr("fill", isDarkTheme ? "#1f2937" : "white");

    svgElement
      .selectAll("xyz")
      .data(["E", "NE", "N", "NW", "W", "SW", "S", "SE"])
      .join("text")
      .attr("x", (d, i) => Math.cos((i * 2 * Math.PI) / 8) * rText + cx)
      .attr(
        "y",
        (d, i) => -Math.sin((i * 2 * Math.PI) / 8) * rText + cy + hText / 2
      )
      .attr("text-anchor", "middle")
      .style("font-size", `${hText}px`)
      .style("fill", isDarkTheme ? "black" : "white")
      .text((d) => d);

    conciseData.forEach((d, i) => {
      svgElement
        .append("path")
        .datum([
          [cx, cy],
          [
            cx +
              ((rInner * d[1]) / 10) *
                Math.cos((2 * Math.PI * (90 - d[0])) / 360),
            cy -
              ((rInner * d[1]) / 10) *
                Math.sin((2 * Math.PI * (90 - d[0])) / 360),
          ],
        ])
        .attr("stroke", "#884DE3")
        .attr("stroke-width", 4)
        .attr("opacity", `${(0.2 * i) / conciseData.length}`)
        .attr(
          "d",
          d3
            .line()
            .x(function (d) {
              return d[0];
            })
            .y(function (d) {
              return d[1];
            })
        );
    });

    // Clean up functions erases svg contents
    return () => {
      svgElement.selectAll("*").remove();
    };
  }, [data, height, width, isDarkTheme]);

  return (
    <div ref={mainBlockRef}>
      <svg width={width} height={height} ref={ref} />
    </div>
  );
}

export default ChartWindrose;
