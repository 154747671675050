import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  setLapData,
  setLiveFlagData,
  setRaceControlData,
  setWeatherData,
  getUserAccessInfo,
  setSectorData,
  setLoopData,
  setPitInOutData,
  setAttackModeData,
  setAttackSummary,
  setOurCars,
  setYellowFlags,
  setSessionInfoDetails,
  setSessionEntryFromSocket,
  setPowerModeData,
  reloadPage,
  updateUserPreferences
} from "redux/modules/global";
import {
  IRaceControl,
  ILiveFlags,
  ILap,
  ISector,
  ILoop,
  IPitInfo,
  IRadioMessage,
  IWeather,
  IAttackSummary,
  IAttackMode,
  IYellowFlag,
  ISessionEntry,
  ISessionInfo,
  IPowerMode,
} from "@mahindraformulae/rubicon-hooks";
import { socket } from "context/socket";
import { RootState, AppDispatch } from "redux/store";
import {
  IResponse,
} from "types";
import config from "./config";
import { useWebWorker } from "./worker/useWebWorker";
import { IAttackSummaryWorkerRequest } from "./worker/attackSummaryScript";
import { IYellowFlagsWorkerRequest } from "./worker/getYellowFlagsScript";
import { throttle } from "lodash";
import { saveRememberedEvent } from "./components/layout/Header/EventSelector";
const { ATTACK_MODE_BLUE, NEW_API_BASE, OUR_CARS } = config;
const CONFIG_KEY = 'ATTACK_CONFIG';

const MySwal = withReactContent(Swal);
function bufferedDispatch<T>(dispatchFn: (x: T[]) => void) {
  let buffer: T[] = []
  const throttledDispatch = throttle((buffer: T[]) => {
    dispatchFn(buffer);
    buffer.length = 0
  }, 1000, { trailing: true })
  return (socketData: T[]) => {
    buffer.push(...socketData)
    throttledDispatch(buffer)
  }
}
const onReConnect = () => {
  const reloadConfirmed = window.confirm(
    "Socket connection restored, click on `OK` to sync with the live updates!"
  );
  if (reloadConfirmed) {
    window.location.reload();
  }
};
function MainSocket(props: any) {
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useSelector((state: RootState) => state.auth.user);
  const selectedEvent = useSelector((state: RootState) => state.global.selectedEvent);
  const sessionEntry = useSelector((state: RootState) => state.global.sessionEntry);
  const attackMode = useSelector((state: RootState) => state.global.attackMode);
  const loops = useSelector((state: RootState) => state.global.loops);
  const messages = useSelector((state: RootState) => state.global.raceControlMessages);
  const laps: ILap[] = useSelector((state: RootState) => state.global.laps);
  const userAuthInfo = useSelector((state: RootState) => state.global.userAuthData);
  const [configVal, setConfigVal] = useState<any>([]);
  const workerAttackSummary = useMemo(() => new Worker(new URL('./worker/attackSummaryScript.ts', import.meta.url)), []);
  const attackSummaryFromWorker = useWebWorker<IAttackSummary[], IAttackSummaryWorkerRequest>(workerAttackSummary);

  const attackSummaryProcessRef = useRef(attackSummaryFromWorker.startProcessing)

  const yellowFlags = useMemo(() => new Worker(new URL('./worker/getYellowFlagsScript.ts', import.meta.url)), []);
  const yellowFlagsFromWorker = useWebWorker<IYellowFlag[], IYellowFlagsWorkerRequest>(yellowFlags);
  const yellowFlagsProcessRef = useRef(yellowFlagsFromWorker.startProcessing);

  useEffect(() => {
    userInfo !== null && dispatch(getUserAccessInfo());
  }, [dispatch, userInfo]);

  useEffect(() => {
    attackSummaryProcessRef.current({ raceInfo: selectedEvent, attackModeData: attackMode, sessionEntry: sessionEntry, _loops: loops, config: configVal });
  }, [attackSummaryProcessRef, selectedEvent, attackMode, sessionEntry, loops, configVal])

  useEffect(() => {
    yellowFlagsProcessRef.current({ laps: laps, _loops: loops, raceInfo: selectedEvent, raceControl: messages });
  }, [yellowFlagsProcessRef, messages, laps, loops, selectedEvent]);

  useEffect(() => {
    dispatch(setAttackSummary(attackSummaryFromWorker.result ?? []));
  }, [dispatch, attackSummaryFromWorker.result])

  useEffect(() => {
    const result = yellowFlagsFromWorker?.result;
    if (result && result?.length > 0) {
      dispatch(setYellowFlags(result));
    }
  }, [dispatch, yellowFlagsFromWorker.result])

  useEffect(() => {
    const fetchDrivers = async (seasonId: string) => {
      const api = `${config.NEW_API_BASE}/master/seasons`
      fetch(api).then(resp => resp.json()).then(({ data }: IResponse<any>) => {
        let selectedSeason = data.find((s: any) => s.seasonId === seasonId)
        // console.log("response recieved", selectedSeason?.seasonDrivers)
        dispatch(setOurCars(selectedSeason?.seasonDrivers ?? []))
      })
    }
    fetchDrivers(selectedEvent?.seasonId).then();
  }, [dispatch, selectedEvent])

  useEffect(() => {
    async function fetchConfig(selectedEventID: number) {
      if (!selectedEventID) return;
      let params = new URLSearchParams({ 'configKey': CONFIG_KEY, 'raceEventId': selectedEventID.toString(), 'driverNo': '0' })
      let savedStateAPI = `${NEW_API_BASE}/master/config/generic?${params.toString()}`;
      try {
        const { data: savedStateResponse } = await fetch(savedStateAPI).then(data => data.json());

        if (savedStateResponse?.config?.attackModeConfig?.lapRemainData) {
          setConfigVal(savedStateResponse?.config?.attackModeConfig?.lapRemainData);
        }
        else {
          setConfigVal([])
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchConfig(selectedEvent?.raceEventId);
  }, [selectedEvent])

  useEffect(() => {
    if (!userAuthInfo) return;
    const onSessionUpdate = () => {
      MySwal.fire({
        title: `A new session has been activated. Do you want to switch to it?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        allowOutsideClick: false,
      }).then((result) => {
        // if (result.isConfirmed) dispatch(ManageAllEventsData(socketData)); may consider handling this later
        if (result.isConfirmed) {
          saveRememberedEvent(null, userAuthInfo).then(preferences => dispatch(updateUserPreferences(preferences)));
          dispatch(reloadPage());
        }
      });
    };

    socket.emit("leave_room", { room: "mr_data_current_race" });
    socket.off("mr_data_current_race", onSessionUpdate);
    socket.emit("join_room", { room: "mr_data_current_race" });
    socket.on("mr_data_current_race", onSessionUpdate);

    return () => {
      socket.emit("leave_room", { room: "mr_data_current_race" });
      socket.off("mr_data_current_race", onSessionUpdate);
      socket.io.off("reconnect", onReConnect);
    }
  }, [userAuthInfo, dispatch])

  useEffect(() => {
    const handler = {
      racecontrol: bufferedDispatch((socketData: IRaceControl[]) => {
        dispatch(setRaceControlData(socketData));
      }),
      live_flag: bufferedDispatch((socketData: ILiveFlags[]) => {
        dispatch(setLiveFlagData(socketData));
      }),
      live_finishline: bufferedDispatch((socketData: ILap[]) => {
        dispatch(setLapData(socketData));
      }),
      live_sector: bufferedDispatch((socketData: ISector[]) => {
        dispatch(setSectorData(socketData));
      }),
      live_loop: bufferedDispatch((socketData: ILoop[]) => {
        dispatch(setLoopData(socketData));
      }),
      live_pitin: bufferedDispatch((socketData: IPitInfo[]) => {
        dispatch(setPitInOutData(socketData));
      }),
      live_pitout: bufferedDispatch((socketData: IPitInfo[]) => {
        dispatch(setPitInOutData(socketData));
      }),
      attack_mode: bufferedDispatch((socketData: IAttackMode[]) => {
        dispatch(setAttackModeData(socketData));
      }),
      weather_current: bufferedDispatch((socketData: IWeather[]) => {
        dispatch(setWeatherData(socketData));
      }),
      session_entry: bufferedDispatch((socketData: ISessionEntry[]) => {
        dispatch(setSessionEntryFromSocket(socketData))
      }),
      session_info: bufferedDispatch((socketData: ISessionInfo[]) => {
        dispatch(setSessionInfoDetails(socketData))
      }),
      power_mode: bufferedDispatch((socketData: IPowerMode[]) => {
        dispatch(setPowerModeData(socketData));
      }),
    };

    if (userInfo) {
      const subscribeResubscribeToRooms = () => {
        try {
          for (const [key, value] of Object.entries(handler)) {
            socket.emit("leave_room", { room: key });
            socket.off(key, value);
            socket.emit("join_room", { room: key });
            socket.on(key, value);
          }
        } catch (e) {
          console.log(`Error in 'subscribeResubscribeToRooms'`, e);
        }
      };
      if (selectedEvent) {
        const { raceEventId, isCurrent } = selectedEvent;
        if (raceEventId) {
          if (isCurrent) {
            subscribeResubscribeToRooms();
          }
        }
      }

      socket.io.on("reconnect", onReConnect);

      socket.on("disconnect", () => {
        console.log(socket.id); // undefined
      });

      return () => {
        for (const [key, value] of Object.entries(handler)) {
          socket.emit("leave_room", { room: key });
          socket.off(key, value);
          socket.io.off("reconnect", onReConnect);
        }
      };
    }
  }, [dispatch, userInfo, selectedEvent]);

  return props.children;
}

export default MainSocket;
