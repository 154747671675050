import * as d3 from 'd3'
import { TOOLTIP_DEFAULTS } from '../aleaConstants';
export const tooltipHandlers = (context, style = { ...TOOLTIP_DEFAULTS.style }) => {

  let Tooltip = context.select("#div_tooltip")
  let defaultOpacity, defaultStrokeWidth;
  if (Tooltip.empty()) {
    Tooltip = context.insert("div", ":first-child")
      .attr("id", "div_tooltip")
  }
  Tooltip
    .style("opacity", 0)
    .attr("class", "tooltip")
    .style("background-color", style.backGroundColor)
    .style("border", "solid")
    .style("border-color", style.border)
    .style("border-width", "2px")
    .style("border-radius", "5px")
    .style("padding", "5px")
    .style("pointer-events", "none")

  // Three function that change the tooltip when mouse hovers / moves / leaves

  const mouseover = function (d) {
    defaultOpacity = d3.select(this).style("opacity");
    defaultStrokeWidth = d3.select(this).style("stroke-width");
    Tooltip
      .style("opacity", 1)
    d3.select(this)
      .style("stroke-width", 3)
      .style("opacity", 1)
  }

  const mousemove = function (e, htmlValue) {
    Tooltip
      .html(htmlValue)
      .style('color', style.textColor)
      .style("left", (e.offsetX + 10) + "px")
      .style("top", (e.offsetY - 10) + "px")
  }

  const mouseleave = function (d) {
    Tooltip
      .style("opacity", 0)
      .style("left", "0px")
      .style("top", "0px")
    d3.select(this)
      .style("stroke-width", defaultStrokeWidth)
      .style("opacity", defaultOpacity)
  }

  return { mouseover, mousemove, mouseleave }

}
