import moment from 'moment';
import { cloneDeep } from 'lodash';
import { store } from 'redux/store';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { RGBColorObject } from '@/utils/hexToRgb';

const fetchAPIData = (api: string) => {
  const __token = store.getState()?.auth?.user?.cipher;
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  if (__token) myHeaders.append('sessionId', __token);
  const requestOptions = {
    headers: myHeaders
  };
  return fetch(api, requestOptions).then(data => data.json()).catch(err => {
    console.log('Error in fetchAPIData', api, err);
    return [];
  });
};
const getDiffInHMS = (diffInMS: number) => {
  let delta = diffInMS / 1000;
  let days = Math.floor(delta / 86400);
  delta -= days * 86400;

  let hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  let minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  let seconds = Math.floor(delta % 60);
  return { days, hours, minutes, seconds };
};
const getDisplayTime = (ms: number) => {
  const tempTime = moment.duration(ms);
  return tempTime.minutes() + ':' + tempTime.seconds();
};
const getDisplayHMSTime = (ms: number, format = 'HH:mm:ss') => {
  return moment(ms).format(format);
};

function getFromLS(key: string) {
  let ls = {};
  if (global.localStorage) {
    try {
      // @ts-ignore
      ls = JSON.parse(global.localStorage.getItem(key)) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls;
}

function saveToLS(key: string, value: any) {
  if (global.localStorage) {
    global.localStorage.setItem(key, JSON.stringify(value));
  }
}

function updateGivePropInArray(arr: any, key: string, val: any) {
  // @ts-ignore
  return arr.map(p => {
    p[key] = val;
    return p;
  });
}

function arrayMoveImmutable(array: any, fromIndex: number, toIndex: number) {
  array = [...array];
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
  return array;
}

function convertBase34(base34number: string) {
  let multiplier = [Math.pow(34, 4), Math.pow(34, 3), Math.pow(34, 2), Math.pow(34, 1), Math.pow(34, 0)];
  let base34 = ['2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

  let result = 0;
  let input = base34number.toLowerCase();
  for (let i = 0; i < base34number.length; i++) {
    let idx = base34.indexOf(input.charAt(i));
    result += (idx * multiplier[i]);
  }
  return result;
}
function rgbToHex({ r, g, b }: RGBColorObject) {
  // convert to hexadecimal pad each with zeros and return
  return '#' + padZero(r.toString(16)) + padZero(g.toString(16)) + padZero(b.toString(16));
}
function invertColor(hex: string, bw = false) {
  hex = hex ?? `#000000`;
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  let r: string | number = parseInt(hex.slice(0, 2), 16),
    g: string | number = parseInt(hex.slice(2, 4), 16),
    b: string | number = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 128 ? '#000000' : '#FFFFFF';
  }
  // invert color components and return
  return rgbToHex({ r: (255 - r), g: (255 - g), b: (255 - b) })
}


function padZero(str: string, len?: number) {
  len = len || 2;
  let zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

function trimSpacesAndMakeLower(originalString = '') {
  const cleanedString = originalString.replace(/[^\w\d]/gi, '');
  return cleanedString.toLowerCase().charAt(cleanedString.length - 1);
}

const getUniqueTSByDriver = (arr: any) => {
  let driverTS: any = {};
  let op = [];
  for (let i = 0, iLen = arr.length; i < iLen; i++) {
    let { driverNo, ts } = arr[i];
    if (!driverTS[driverNo]) {
      driverTS[driverNo] = new Set([ts]);
      op.push(arr[i]);
    } else {
      if (!driverTS[driverNo].has(ts)) {
        driverTS[driverNo].add(ts);
        op.push(arr[i]);
      }
    }
  }
  return op;
};
const mapSectorsToLaps = (_sectors: any, _laps: any) => {
  const sectors: any = cloneDeep(_sectors);
  const laps: any = cloneDeep(_laps);
  for (let i = 0, iLen = laps.length; i < iLen; i++) {
    const { lapNumber, lapTime, driverNo, lapTimeValid, ts } = laps[i];
    const lapStartTS = ts - lapTime;
    // @ts-ignore
    let filteredSectors = sectors.filter(s => s.driverNo === driverNo && (s.ts > lapStartTS && s.ts <= ts));
    for (let j = 0, jLen = filteredSectors.length; j < jLen; j++) {
      // @ts-ignore
      const indexInMainArr = sectors.findIndex(s => s.ts === filteredSectors[j].ts && s.driverNo === filteredSectors[j].driverNo);
      sectors[indexInMainArr] = { ...filteredSectors[j], lapNumber, lapTime, lapTimeValid };
    }
    laps[i] = { ...laps[i], sectors: filteredSectors, lapStartTS };
  }
  return { sectors, laps };
};

const breakAndUppercase = (input: string) => {
  if (input !== input.toUpperCase()) {
    // Use a regular expression to find casing switches
    const words = input.split(/(?=[A-Z])/);

    // Uppercase each word
    const uppercaseWords = words.map((word) => (
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ));

    // Join the words back together with spaces
    return uppercaseWords.join(' ');
  } else return input;
};

interface TextToSpeechOptions {
  voice?: string;
  volume?: number;
}

interface SpeakOptions {
  onEnd?: () => void;
}

const useTextToSpeech = (props: TextToSpeechOptions) => {
  const voiceRef = useRef<SpeechSynthesisVoice | undefined>(undefined);
  const [speaking, setSpeaking] = useState(() => window.speechSynthesis.speaking);

  const speak = useCallback((text: string, speakOptions?: SpeakOptions) => {
    console.log(`speak ~ text:`, text);
    let utterance = new SpeechSynthesisUtterance(text);
    if (props?.voice && voiceRef?.current) utterance.voice = voiceRef?.current;
    if (props?.volume) utterance.volume = props.volume;
    utterance.addEventListener("start", () => setSpeaking(true));
    utterance.addEventListener("end", () => {
      setSpeaking(false);
      if(speakOptions?.onEnd instanceof Function) speakOptions.onEnd();
    });
    utterance.addEventListener("error", (event) => {
      setSpeaking(false);
      console.error(`Error speaking: ${text}`, { event });
    });
    window.speechSynthesis.speak(utterance);
  }, [props?.voice, props?.volume]);

  const stopSpeaking = useCallback(() => {
    window.speechSynthesis.cancel();
    setSpeaking(false);
  }, []);

  useLayoutEffect(() => {
    const populateVoices = () => {
      const voicesList = window.speechSynthesis.getVoices();
      if (voicesList.length > 0) {
        if (props?.voice && props?.voice?.length) {
          voiceRef.current = voicesList.find(v => v.name.includes(String(props.voice))) || voicesList.find((v) => v.default);
        }
      }
    };

    if (props?.voice) populateVoices();
    window.speechSynthesis.addEventListener("voiceschanged", populateVoices);

    return () => {
      window.speechSynthesis.removeEventListener("voiceschanged", populateVoices);
    }
  }, [props?.voice]);

  return { speak, stopSpeaking, speaking };
};

function hueToRgb(p: number, q: number, t: number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
};

function hslToRgb(h: number, s: number, l: number) {
  // Convert HSL to RGB
  h = h / 360;
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {


    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
}

function getEquidistantColors(n: number, saturation = 1, lightness = 0.5) {
  const colors = [];

  for (let i = 0; i < n; i++) {
    const hue = (i * 360 / n) % 360; // Equidistant hue
    const rgb = hslToRgb(hue, saturation, lightness);
    colors.push(rgb);
  }

  return colors;
}

const calculateAverage = (values: number[]) => {
  const sum = values.reduce((acc, value) => acc + value, 0);
  return sum / values.length;
};

function rybScale(value: number) {
  let r, g, b;
  if (value <= 0.5) {
    // From red to yellow
    let ratio = value / 0.5;
    g = 255;
    r = Math.round(ratio * 255);
    b = 0;
  } else {
    // From yellow to blue
    let ratio = (value - 0.5) / 0.5;
    r = 255//Math.round((1 - ratio) * 255);
    g = Math.round((1 - ratio) * 255);
    b = 0//Math.round(ratio * 255);
  }
  return '#' + padZero(r.toString(16)) + padZero(g.toString(16)) + padZero(b.toString(16));
}
function getDistinctKeys(arrayOfObjects: [{}]) {
  const keySet = new Set();

  arrayOfObjects.forEach(obj => {
    Object.keys(obj).forEach(key => {
      keySet.add(key);
    });
  });

  return Array.from(keySet);
}
// Example usage:
export {
  fetchAPIData,
  getDiffInHMS,
  getFromLS,
  saveToLS,
  getDisplayTime,
  getDisplayHMSTime,
  updateGivePropInArray,
  arrayMoveImmutable,
  convertBase34,
  invertColor,
  getUniqueTSByDriver,
  trimSpacesAndMakeLower,
  mapSectorsToLaps,
  breakAndUppercase,
  useTextToSpeech,
  getEquidistantColors,
  rgbToHex,
  calculateAverage,
  rybScale,
  getDistinctKeys
};