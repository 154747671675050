import React from "react";

interface SwitchPropTypes extends React.ComponentProps<'input'> {
  label: string;
  labelOn?: string;
}

const Switch: React.FC<SwitchPropTypes> = (props) => {
  const { id, label = '', labelOn = '', ...extraProps } = props;

  return (
    <label className="inline-flex items-center mr-5 cursor-pointer w-full justify-between" htmlFor={id}>
      {label ? <span className="text-lg font-medium select-none">{label}</span> : null}
      <div className="relative mx-2">
        <input {...extraProps} id={id} type="checkbox" role="switch" className="sr-only peer" />
        <div className="w-11 h-6 bg-gray-400 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600" />
      </div>
      {labelOn ? <span className="text-lg font-medium select-none">{labelOn}</span> : null}
    </label>
  );
};

export default Switch;
