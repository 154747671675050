import { Fragment, useCallback, useEffect, useState } from "react";

import { AleaCharts } from "components";
import { ALEA_CHART_TYPES, ALEA_COLOR, AXIS_SCALES } from "components/AleaCharts/aleaConstants";
import { IChartDataItem, IChartDataValue } from "components/AleaCharts/aleaConstants/aleaChartTypes";
import { fn_formatDateTime } from "utils/moment";

import "./WeatherStation.css";
import ChartWindrose from "components/Charts/ChartWindrose/ChartWindrose";
import config from "config";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

const POLLING_INTERVAL = 30000;

const WeatherStation = () => {
  const [rawData, setRawData] = useState([]);
  const [temperatureData, setTemperatureData] = useState<IChartDataItem[]>([]);
  const [humidity, setHumidity] = useState<IChartDataItem[]>([]);
  const [pAir, setPAir] = useState<IChartDataItem[]>([]);
  const [windSpeedData, setWindSpeedData] = useState<IChartDataItem[]>([]);
  const [windDir, setWindDir] = useState<IChartDataItem[]>([]);
  // const [updatedAt, setUpdatedAt] = useState(Date.now());
  const selectedEvent = useSelector((state: RootState) => state.global.selectedEvent);

  const fetchMyAPI = useCallback(async () => {
    // const _currTime = new Date();
    // const currTime = _currTime.toISOString();
    // const _startTime = _currTime.setHours(_currTime.getHours() -360);
    // const startTime = new Date(_startTime).toISOString();
    const startTime = new Date(selectedEvent?.startTs).toISOString();
    const currTime = new Date(selectedEvent?.endTs).toISOString();

    let api = selectedEvent?.eventTypeStr == 'TEST' ? `${config.NEW_API_BASE}/externalData/weather/${startTime}/${currTime}` : `${config.NEW_API_BASE}/weather/logs/${selectedEvent?.startTs}/${selectedEvent?.endTs}`
    fetch(`${api}`)
      .then((data) => data.json())
      .then(({ data: wData = [] }) => {
        const _temperatureData: IChartDataValue[] = [];
        const _humidityData: IChartDataValue[] = [];
        const _pressureData: IChartDataValue[] = [];
        const _pAirData: IChartDataValue[] = [];
        const _windSpeedData: IChartDataValue[] = [];
        const _windSpeedAvgLastMin: IChartDataValue[] = [];
        const _windSpeedAvgLast2Min: IChartDataValue[] = [];
        const _windSpeedAvgLast10Min: IChartDataValue[] = [];
        const _windDirAvgLastMin: IChartDataValue[] = [];
        const _windDirAvgLast2Min: IChartDataValue[] = [];
        const _windDirAvgLast10Min: IChartDataValue[] = [];
        wData.forEach((w: any) => {
          const { weatherOutdoor, atmosphericPreassure: atmosphericPressure, pAir } = w;
          let {
            temp,
            timeStamp: indoorTS,
            humidity,
            windSpeed,
            windSpeedAvgLastMin,
            windSpeedAvgLast2Min,
            windSpeedAvgLast10Min,
            windDirAvgLastMin,
            windDirAvgLast2Min,
            windDirAvgLast10Min,
          } = weatherOutdoor;

          const tempInC: number = ((temp - 32) * 5) / 9;
          const time = new Date(indoorTS);
          _temperatureData.push({
            x: time,
            y: tempInC,
            label: `${fn_formatDateTime(time, "lll")}, ${tempInC.toFixed(2)}°C`,
          });

          _humidityData.push({
            x: time,
            y: humidity,
            label: `${fn_formatDateTime(time, "lll")}, ${humidity}`,
          });

          _pressureData.push({
            x: time,
            y: atmosphericPressure,
            label: `${fn_formatDateTime(time, "lll")}, ${atmosphericPressure}`,
          });

          _pAirData.push({
            x: time,
            y: pAir,
            label: `${fn_formatDateTime(time, "lll")}, ${pAir?.toFixed(1)}`,
          });

          _windSpeedData.push({
            x: time,
            y: windSpeed,
            label: `${fn_formatDateTime(time, "lll")}, ${windSpeed}`,
          });

          _windSpeedAvgLastMin.push({
            x: time,
            y: windSpeedAvgLastMin,
            label: `${fn_formatDateTime(time, "lll")}, ${windSpeedAvgLastMin}`,
          });

          _windSpeedAvgLast2Min.push({
            x: time,
            y: windSpeedAvgLast2Min,
            label: `${fn_formatDateTime(time, "lll")}, ${windSpeedAvgLast2Min}`,
          });

          _windSpeedAvgLast10Min.push({
            x: time,
            y: windSpeedAvgLast10Min,
            label: `${fn_formatDateTime(time, "lll")}, ${windSpeedAvgLast10Min}`,
          });

          _windDirAvgLastMin.push({
            x: time,
            y: windDirAvgLastMin,
            label: `${fn_formatDateTime(time, "lll")}, ${windDirAvgLastMin}`,
          });
          _windDirAvgLast2Min.push({
            x: time,
            y: windDirAvgLast2Min,
            label: `${fn_formatDateTime(time, "lll")}, ${windDirAvgLast2Min}`,
          });
          _windDirAvgLast10Min.push({
            x: time,
            y: windDirAvgLast10Min,
            label: `${fn_formatDateTime(time, "lll")}, ${windDirAvgLast10Min}`,
          });
        });

        setTemperatureData([
          {
            values: _temperatureData,
            color: ALEA_COLOR[0],
            name: "Temprature",
            opacity: 1,
            size: 1,
          },
        ]);

        setHumidity([
          {
            values: _humidityData,
            color: ALEA_COLOR[1],
            name: "Humidity",
            opacity: 1,
            size: 1,
          },
          {
            values: _pressureData,
            color: ALEA_COLOR[2],
            name: "Atmospheric Pressure",
            opacity: 1,
            size: 1,
          },
        ]);

        setPAir([
          {
            values: _pAirData,
            color: ALEA_COLOR[3],
            name: "pAir",
            opacity: 1,
            size: 1,
          },
        ]);

        setWindSpeedData([
          {
            values: _windSpeedData,
            color: ALEA_COLOR[0],
            name: "windSpeedData",
            opacity: 1,
            size: 1,
          },
          {
            values: _windSpeedAvgLastMin,
            color: ALEA_COLOR[1],
            name: "windSpeedAvgLastMin",
            opacity: 1,
            size: 1,
          },
          {
            values: _windSpeedAvgLast2Min,
            color: ALEA_COLOR[2],
            name: "windSpeedAvgLast2Min",
            opacity: 1,
            size: 1,
          },
          {
            values: _windSpeedAvgLast10Min,
            color: ALEA_COLOR[3],
            name: "windSpeedAvgLast10Min",
            opacity: 1,
            size: 1,
          },
        ]);

        setWindDir([
          {
            values: _windDirAvgLastMin,
            color: ALEA_COLOR[1],
            name: "windDirAvgLastMin",
            opacity: 1,
            size: 1,
          },
          {
            values: _windDirAvgLast2Min,
            color: ALEA_COLOR[2],
            name: "windDirAvgLast2Min",
            opacity: 1,
            size: 1,
          },
          {
            values: _windDirAvgLast10Min,
            color: ALEA_COLOR[3],
            name: "windDirAvgLast10Min",
            opacity: 1,
            size: 1,
          },
        ]);

        setRawData(wData);
        // setUpdatedAt(Date.now());
      });
  }, [selectedEvent]);

  useEffect(() => {
    fetchMyAPI();
    const timer = setInterval(fetchMyAPI, POLLING_INTERVAL);
    return () => clearInterval(timer);
  }, [fetchMyAPI, selectedEvent]);

  // console.log("windSpeed", windSpeedData);
  // console.log("temperatureData", temperatureData);
  return (
    <Fragment>
      <div className="flex">
        <div className="wearther-chart-block">
          <AleaCharts
            // key={updatedAt}
            data={temperatureData}
            types={[ALEA_CHART_TYPES.LINE]}
            title="Air Temperature"
            marginY={10}
            xyConfig={{
              tickLabelFontSize: { x: "13px", y: "12px" },
              yTickLine: true,
              xScale: AXIS_SCALES.DATETIME,

              yFormatAfter: "°C",
            }}
            isLegends={false}
          />
        </div>
        <div className="wearther-chart-block">
          <AleaCharts
            // key={updatedAt}
            data={humidity}
            types={[ALEA_CHART_TYPES.LINE]}
            title="Humidity"
            marginY={10}
            xyConfig={{
              tickLabelFontSize: { x: "13px", y: "12px" },
              yTickLine: true,
              xScale: AXIS_SCALES.DATETIME,
              // yFormatAfter: "°C",
            }}
            // isLegends={false}
            legendWidth={250}
          />
        </div>
        <div className="wearther-chart-block">
          <AleaCharts
            // key={updatedAt}
            data={pAir}
            types={[ALEA_CHART_TYPES.LINE]}
            title="pAir"
            marginY={10}
            xyConfig={{
              yTickLine: true,
              xScale: AXIS_SCALES.DATETIME,
            }}
            isLegends={false}
          />
        </div>
      </div>
      <div className="flex">
        <div className="wearther-chart-block">
          <AleaCharts
            // key={updatedAt}
            data={windSpeedData}
            types={[ALEA_CHART_TYPES.LINE]}
            title="Wind Speed"
            marginY={10}
            xyConfig={{
              tickLabelFontSize: { x: "13px", y: "12px" },
              yTickLine: true,
              xScale: AXIS_SCALES.DATETIME,
            }}
            // isLegends={false}
            width={426}
            legendWidth={300}
          />
        </div>
        <div className="wearther-chart-block">
          <AleaCharts
            // key={updatedAt}
            data={windDir}
            types={[ALEA_CHART_TYPES.LINE]}
            title="Wind Direction"
            marginY={10}
            xyConfig={{
              tickLabelFontSize: { x: "13px", y: "12px" },
              yTickLine: true,
              xScale: AXIS_SCALES.DATETIME,
            }}
            // isLegends={false}
            width={426}
            legendWidth={300}
          />
        </div>
        <div className="wearther-chart-block">
          <div className="content-center align-middle">
            <ChartWindrose data={rawData} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WeatherStation;
