import panIcon from "./move.png";
import lineIcon from "./line.png";
import homeIcon from "./home.png";
import zoomIcon from "./zoom.png";
import dotIcon from "./dot.png";
import DownloadIcon from "./download.png";
import panDarkIcon from "./move-dark.png";
import lineDarkIcon from "./line-dark.png";
import homeDarkIcon from "./home-dark.png";
import zoomDarkIcon from "./zoom-dark.png";
import dotDarkIcon from "./dot-dark.png";
import DownloadDarkIcon from "./download-dark.png";
import squareIcon from "./square.png";
import squareDarkIcon from "./square-dark.png";
import verticalLockDarkIcon from "./vertical_lock_dark.png";
import verticalLockLightIcon from "./vertical_lock_light.png";
import horizontalLockDarkIcon from "./horizontal_lock_dark.png";
import horizontalLockLightIcon from "./horizontal_lock_light.png";
import legendIcon from "./legend.png"
import { THEMES } from "../../aleaConstants";

const icons = {
  [THEMES.LIGHT]: {
    pan: panIcon,
    line: lineIcon,
    home: homeIcon,
    zoom: zoomIcon,
    dot: dotIcon,
    dowloand: DownloadIcon,
    rect: squareIcon,
    verticalLock: verticalLockDarkIcon,
    hrizontalLock: horizontalLockDarkIcon,
    legendsIcon: legendIcon
  },
  [THEMES.DARK]: {
    pan: panDarkIcon,
    line: lineDarkIcon,
    home: homeDarkIcon,
    zoom: zoomDarkIcon,
    dot: dotDarkIcon,
    dowloand: DownloadDarkIcon,
    rect: squareDarkIcon,
    verticalLock: verticalLockLightIcon,
    hrizontalLock: horizontalLockLightIcon
  },
};

export default icons;
