import { RootState } from "redux/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function StatusBorder({ children }: { children: React.ReactNode }) {
  const flagMessages = useSelector((state: RootState) => state.global.flagMessages);
  const [borderClass, setBorderClass] = useState<any>('');

  useEffect(() => {
    let currentFlag = '';

    let sector0Flags = flagMessages.filter(d => d.sector === 0);
    if (sector0Flags.length > 0) {
      currentFlag = sector0Flags[sector0Flags.length - 1]?.type ?? '';
    }

    switch (currentFlag) {
      case 'RED': setBorderClass('outline outline-red-600 outline-8 outline-offset-[-2px] outlineFadeInOutAnimation')
        break;
      case 'FULL_YELLOW': setBorderClass('outline outline-yellow-300 outline-8 outline-offset-[-2px] outlineFadeInOutAnimation')
        break;
      case 'GREEN': setBorderClass('outline outline-green-500 outline-8 outline-offset-[-2px] outlineFadeOutAnimation')
        break;
      case 'SAFETY_CAR': setBorderClass('outline outline-blue-600 outline-8 outline-offset-[-2px] outlineFadeInOutAnimation')
        break;
      default: setBorderClass('outline-none')
    }
  }, [flagMessages])

  return <div className={borderClass}>
    {children}
  </div>
}