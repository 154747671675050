import { lazy } from "react";
import { IComponent } from "../types";

const Home = lazy(() => import("../pages/Home/Home"));
const TrackDataEntry = lazy(() => import("../pages/TrackEntry"));
const EventList = lazy(() => import("../pages/EventList"));
const EventEntry = lazy(() => import("../pages/AddEvent"));
const HealthCheck = lazy(() => import("../pages/HealthCheck"));
const ReplayRequest = lazy(() => import("../pages/ReplayRequest"));
const AccessControl = lazy(() => import("../pages/AccessControlList"));
const UnifiedConfig = lazy(() => import("../pages/UnifiedConfig"));
const LapTimes = lazy(() => import("../pages/LapTimes/LapTimes"));
const SectorTimes = lazy(() => import("../pages/SectorTimes/SectorTimes"));
const WeatherStation = lazy(() => import("../pages/WeatherStation/WeatherStation"));
const DriverRadio = lazy(() => import("../pages/DriverRadio/DriverRadio"));
const RemLaps = lazy(() => import("../pages/RemLap"));
const RaceMap = lazy(() => import("../pages/RaceMap"));
const InterventionsMonitor = lazy(() => import("../pages/InterventionsMonitor"));
const EnergyManagement = lazy(() => import("pages/EnergyManagement/EnergyManagement"));
const EngineersPage = lazy(() => import("pages/EngineersPage/EngineersPage"));
const BatteryImages = lazy(() => import("../pages/BatteryImages"));
const TeamColors = lazy(() => import("../pages/TeamColors"));
const TPMSDataDisplay = lazy(() => import("pages/TPMS"));
const RestreamManager = lazy(() => import("pages/RestreamManager"));
const VideoStream = lazy(() => import("pages/VideoStream"));
const RaceJourney = lazy(() => import("pages/RaceJourney/RaceJourney"));
const LapGraphs = lazy(() => import("pages/LapGraphs/LapGraphs"));
const LoopAnalysis = lazy(() => import("pages/LoopAnalysis/LoopAnalysis"));
const TimingGapsViewer = lazy(() => import("pages/TimingGapsViewer/TimingGapsViewer"));
const OCREnergyDeltas = lazy(() => import("pages/OCREnergyDeltas"));
const QualiJourney = lazy(() => import("pages/QualiJourney"));
const QualifyingTimer = lazy(() => import("../pages/Qualifying/Qualifying"));
const RunPlansTableDisplay = lazy(() => import("pages/RunPlansTable"));
const RacePosition = lazy(() => import("pages/RacePosition"));
const CustomLayout = lazy(() => import("components/CustomLayout/CustomLayout"));
const AttackModeStatus = lazy(() => import("pages/AttackModeStatus"));
const TurnTrajectories = lazy(() => import("pages/TurnTrajectories"));
const DownloadShop = lazy(() => import("pages/DownloadShop"));
const Radar = lazy(() => import("pages/Radar"));
const RubiconSpreadsheet = lazy(() => import("pages/RubiconSpreadsheet"));
const TrafficMonitor = lazy(() => import("pages/TrafficMonitor"));
const SSNKinetica = lazy(() => import("pages/SSNKinetica"));
const TrackGaps = lazy(() => import("pages/TrackGaps"));
const TrackGapsTable = lazy(() => import("pages/TrackGaps/TrackGapsTable"));
const AlkamelDiagnostics = lazy(() => import("pages/AlkamelDiagnostics"));
const Position = lazy(() => import("pages/Position/Position"));
const LoopTimes = lazy(() => import("pages/LoopTimes/LoopTimes"));
const RefinedVGPS = lazy(() => import("pages/RefinedVGPS"));
const Settings = lazy(() => import("pages/Settings"));
const CompPage = lazy(() => import("components/CompPage/CompPage"));
const AZSummary = lazy(() => import("../pages/AZSummary/AZSummary"));
const QualifyingV2 = lazy(() => import("../pages/QualifyingV2/Qualifying"));
const QualiPlanGen = lazy(() => import("../pages/QualiPlanGen"));

// const Test = lazy(() => import("../pages/Qualifying/qualiTest"))

const COMPONENTS: IComponent[] = [
  { key: "home", label: "Home", href: "/home", icon: { type: "Home" }, component: Home, },
  { key: "engineers-home", label: "Engineers Page", href: "/", icon: { type: "Engineer" }, component: EngineersPage, },
  { key: "custom-layout", label: "My Page", href: "/custom-layout", icon: { type: "CustomLayout" }, component: CustomLayout, hideInMainNav: false },
  { key: "energy-management", label: "Energy Management", href: "/energy-management", icon: { type: "EnergyManagement" }, component: EnergyManagement, },
  { key: "driver-radio", label: "Driver Radio", href: "/driver-radio", icon: { type: "DriverRadio" }, component: DriverRadio, hideInMainNav: false, },
  { key: "refined-gps", label: "Refined GPS", href: "/refined-gps", icon: { type: "RefinedGPS" }, component: RefinedVGPS, hideInMainNav: false },

  { key: "qualifying-supermenu", label: "Qualifying", icon: { type: "Qualifying" }, subMenu: ["qualifying", "quali-journey", "run-plan-table", "quali-planner", "quali-plan-gen"] },
  { key: "qualifying", label: "Quali", href: "/qualifying", icon: { type: "Quali" }, component: QualifyingTimer, hideInMainNav: false, },
  { key: "quali-planner", label: "Quali Planner", href: "/quali-planner", icon: { type: "Quali" }, component: QualifyingV2, hideInMainNav: false, },
  { key: "quali-journey", label: "Quali Journey", href: "/quali-journey", icon: { type: "QualiJourney" }, component: QualiJourney, hideInMainNav: false },
  { key: "quali-plan-gen", label: "Quali Plan Generator", href: "/quali-plan-gen", icon: { type: "QualiPlanGen" }, component: QualiPlanGen, hideInMainNav: false },
  { key: "run-plan-table", label: "Run Plans", href: "/run-plan-table", icon: { type: "RunPlanTable" }, component: RunPlansTableDisplay, hideInMainNav: false },


  { key: "analytics-supermenu", label: "Analytics", icon: { type: "Analytics" }, subMenu: ["lap-times", "sector-times", "loop-times", "race-journey", "lap-graph", "loop-analysis", "tpms", "weather-station"], },
  { key: "lap-times", label: "Lap Times", href: "/lap-times", icon: { type: "LapTimes" }, component: LapTimes, hideInMainNav: false, },
  { key: "sector-times", label: "Sector Times", href: "/sector-times", icon: { type: "SectorTimes" }, component: SectorTimes, hideInMainNav: false, },
  { key: "loop-times", label: "Lap Loops", href: "/loop-times", icon: { type: "LoopTimes" }, component: LoopTimes, hideInMainNav: false },
  { key: "race-journey", label: "Race Journey", href: "/race-journey", icon: { type: "Track" }, component: RaceJourney, hideInMainNav: false, },
  { key: "lap-graph", label: "Lap Graphs", href: "/lap-graph", component: LapGraphs, icon: { type: "Lap" }, },
  { key: "loop-analysis", label: "Loop Analysis", href: "/loop-analysis", icon: { type: "Loop" }, component: LoopAnalysis, hideInMainNav: false, },
  { key: "tpms", label: "TPMS", href: "/tpms", icon: { type: "TPMS" }, component: TPMSDataDisplay, },
  { key: "weather-station", label: "Weather Station", href: "/weather-station", icon: { type: "WeatherStation" }, component: WeatherStation, hideInMainNav: false, },

  { key: "race-super-menu", label: "Race", icon: { type: "Race" }, subMenu: ["race-position", "attack-mode", "rem-lap", "interventions", "az-summary", "timing-gaps", "position", "traffic-monitor", "ocr-energy-deltas"] },
  { key: "race-position", label: "Race Position", href: "/race-position", icon: { type: "RacePosition" }, component: RacePosition, hideInMainNav: false },
  { key: "attack-mode", label: "Attack Mode Status", href: "/attack-mode", icon: { type: "AttackModeStatus" }, component: AttackModeStatus, hideInMainNav: false },
  { key: "rem-lap", label: "Attack Zone Loss", href: "/remaining-laps", icon: { type: "RemLaps" }, component: RemLaps, },
  { key: "az-summary", label: "AZ Summary", href: "/az-summary", icon: { type: "AZSummary" }, component: AZSummary, },
  { key: "timing-gaps", label: "Timing Gaps Viewer", href: "/timing-gaps-viewer", icon: { type: "TimingGap" }, component: TimingGapsViewer, },
  { key: "position", label: "Position", href: "/position", icon: { type: "TimingGap" }, component: Position, },
  { key: "interventions", label: "Interventions Monitor", href: "/interventions", icon: { type: "Interventions" }, component: InterventionsMonitor, hideInMainNav: false, },
  { key: "traffic-monitor", label: "Traffic Monitor", href: "/traffic-monitor", icon: { type: "TrafficLight" }, component: TrafficMonitor, hideInMainNav: false, },
  { key: "ocr-energy-deltas", label: "OCR Energy Deltas", href: "/ocr-energy-deltas", icon: { type: "OCREnergyDeltas" }, component: OCREnergyDeltas, hideInMainNav: false, },

  { key: "radar", label: "Radar", href: "/radar", icon: { type: "Diagnostic" }, component: Radar, hideInMainNav: false, },

  { key: "gps-super-menu", label: "GPS", icon: { type: "GPS" }, subMenu: ["circuit-map", "turn-trajectories"] },
  { key: "circuit-map", label: "Circuit Map", href: "/circuit-map", icon: { type: "CircuitMap" }, component: RaceMap, canBeAdded: true },
  { key: "turn-trajectories", label: "Turn Trajectories", href: "/turn-trajectories", icon: { type: "TurnTrajectories" }, component: TurnTrajectories, canBeAdded: true },

  { key: "countdown", label: "Countdown", href: "https://rubicon.greenest.team/countdown/", icon: { type: "ExternalLink" }, isExternal: true, },

  { key: "release-notes", label: "Release Notes", href: "https://mahindrafe.atlassian.net/wiki/spaces/OD/pages/78020626/Rubicon+Release+Notes", icon: { type: "Info" }, isExternal: true, },

  { key: "video-stream", label: "Video stream", href: "/video-stream", icon: { type: "VideoStream" }, component: VideoStream, },

  { key: "restream-manager", label: "restream-manager", href: "/restream-manager", component: RestreamManager, hideInMainNav: true, },

  { key: "master-super-menu", label: "Admin", icon: { type: "Admin" }, subMenu: ["replay-request", "access-control-list", "event-list", "event-entry", "track-entry", "health-check", "battery-images", "team-colors", "diagnostics"], },
  { key: "replay-request", label: "Rubicon Replay", href: "/master/replay", icon: { type: "Replay" }, component: ReplayRequest, hideInMainNav: false, },
  { key: "access-control-list", label: "Access Control", href: "/master/access-control", icon: { type: "AccessControl" }, component: AccessControl, hideInMainNav: false, },
  { key: "event-list", label: "Event List", href: "/master/event-list", icon: { type: "EventList" }, component: EventList, hideInMainNav: false, },
  { key: "event-entry", label: "Event Entry", href: "/master/event-entry", icon: { type: "AddEvent" }, component: EventEntry, hideInMainNav: false, },
  { key: "track-entry", label: "Track Entry", href: "/master/track-entry", icon: { type: "Track" }, component: TrackDataEntry, hideInMainNav: false, },
  { key: "health-check", label: "Health Check", href: "/health-check", icon: { type: "HealthCheck" }, component: HealthCheck, },
  { key: "battery-images", label: "OCR Verification", href: "/master/ocr-verification", icon: { type: "BatteryImages" }, component: BatteryImages, hideInMainNav: false, },
  { key: "team-colors", label: "Team Colors", href: "/master/team-colors", icon: { type: "TeamColors" }, component: TeamColors, hideInMainNav: false, },
  { key: "diagnostics", label: "Diagnostics", href: "/diagnostics", icon: { type: "Diagnostic" }, component: AlkamelDiagnostics, hideInMainNav: false },

  { key: "settings-supermenu", label: "Configs", icon: { type: "ConfigSetting" }, subMenu: ["car-configuration", "rubicon-settings"], },
  { key: "rubicon-settings", label: "Rubicon Settings", href: "/rubicon-settings", icon: { type: "Setting" }, component: Settings, hideInMainNav: false },
  { key: "car-configuration", label: "Car Configurations", href: "/car-configuration", icon: { type: "CarConfiguration" }, component: UnifiedConfig, },

  { key: "poc-super-menu", label: "POCs", icon: { type: "Experimental" }, subMenu: ["spreadsheet", "ssn-kinetica", "track-gaps", "track-gaps-table", "test"], },
  { key: "spreadsheet", label: "Spreadsheet", href: "/spreadsheet", icon: { type: "Spreadsheet" }, component: RubiconSpreadsheet, hideInMainNav: false, },
  { key: "ssn-kinetica", label: "SSN Kinetica", href: "/ssn-kinetica", icon: { type: "SSNKinetica" }, component: SSNKinetica, hideInMainNav: false },
  { key: "track-gaps", label: "Track Gaps", href: "/track-gaps", icon: { type: "TrackGaps" }, component: TrackGaps, hideInMainNav: false },
  { key: "track-gaps-table", label: "Track Gaps Table", href: "/track-gaps-table", icon: { type: "TrackGaps" }, component: TrackGapsTable, hideInMainNav: false },
  { key: "test", label: "Test", href: "/test", icon: { type: "Experimental" }, component: () => null, hideInMainNav: false },

  { key: "pop-out", label: "POP-OUT", href: "/pop-out", component: CompPage, hideInMainNav: true },
  { key: "logout", label: "Logout", href: "/logout", icon: { type: "Logout" }, component: () => null, hideInMainNav: true, },

  // { key: "download-shop", label: "Download Shop", href: "/download-shop", icon: { type: "DownloadShop" }, component: DownloadShop, hideInMainNav: false, },
];

export default COMPONENTS;
