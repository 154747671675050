import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid';

import { login } from 'redux/modules/auth';
import config from 'config';
import { AppDispatch, RootState } from 'redux/store';
import { WelcomeUser } from 'RubiconRoutes';
import { useState } from 'react';

const { protocol, host } = window.location;
const loginURL = new URL(`/rubicon/`, `${protocol}//${host}`).toString();
const msalConfig: Configuration = {
  auth: {
    clientId: config.CLIENT_ID,
    authority: config.AUTHORITY_WITH_DOMAIN,
    knownAuthorities: [],
    cloudDiscoveryMetadata: '',
    redirectUri: loginURL,
    navigateToLoginRequestUrl: true,
    clientCapabilities: ['CP1'],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
  telemetry: {
    application: {
      appName: 'Rubicon',
      appVersion: '1.0.0',
    },
  },
};
const msalInstance = new PublicClientApplication(msalConfig);
const loginRequest = {
  scopes: ['user.read'],
};

function Login() {
  const userInfo = useSelector((state: RootState) => state.auth.user);
  const { state } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [message, setMessage] = useState(<></>);
  const initLogin = () => {
    setMessage(<>Waiting for authentication...</>)
    msalInstance
      .loginPopup(loginRequest)
      .then((response) => {
        dispatch(login(response.accessToken))
        setMessage(<></>)
      }, (err: any) => {
        setMessage(<div><div className='text-red-400'>Login Rejected</div> {JSON.stringify(err)}</div>);
      })
      .catch((err) => {
        setMessage(<span className='text-red-400'>Error Encountered: {err}</span>);
      });
  };

  if (userInfo?.cipher) {
    return <Navigate to={state?.path || '/'} replace />;
  }
  return (
    <WelcomeUser>
      <button onClick={initLogin} className="inline-flex items-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
        <span><ArrowRightOnRectangleIcon className="h-6 w-6 mr-2" /></span>
        <span>Login</span>
      </button>
      {message}
    </WelcomeUser>
  );
}

export default Login;