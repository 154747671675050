import { IGroup } from "@mahindraformulae/rubicon-hooks";

const BASE_PATH = process.env.PUBLIC_URL;
const CLIENT_ID = "a0c299b6-9bc4-424f-9c07-c55d6fcb8ea4";
const AUTHORITY_WITH_DOMAIN =
  "https://login.microsoftonline.com/1837626b-02e4-4ab0-a6fc-e4a0d32454d3";
// // const RADIO_STREAM = `https://formulae.pc.cdn.bitgravity.com/hera/videos/{{drFolder}}/{{outFolder}}/prog_index.m3u8`;
// const RADIO_STREAM = `https://rubicon.greenest.team/radio-gravity/hera/videos/{{drFolder}}/{{outFolder}}/prog_index.m3u8`;
// const RACE_EVENT_TYPE_ID = 2;
// //const DR_1 = {number: 23, name: 'FENESTRAZ', shortName: 'FEN', color: '#d5a2a2'};
// //const DR_2 = {number: 7, name: 'GÜNTHER', shortName: 'GUE', color: '#a0a9d7'};
const DR_1 = { number: 11, name: 'DI GRASSI', shortName: 'DIG', color: '#fb923c', colorCls: 'bg-orange-400' };
const DR_2 = { number: 8, name: 'MERHI', shortName: 'MEH', color: '#3b82f6', colorCls: 'bg-blue-500' };
const _OUR_DRIVERS = [DR_1, DR_2];
const OUR_CARS = Object.freeze(_OUR_DRIVERS);

const FLAG_COLOR_MAP = {
  RED: "bg-red-500",
  GREEN: "bg-green-500",
  YELLOW: "bg-yellow-300",
  FULL_YELLOW: "bg-yellow-500",
  SAFETY_CAR: "bg-blue-500",
  FINISH: "bg-gray-200",
  FLAG_INFO: "bg-gray-200",
};

// const OUR_DRIVERS = _OUR_DRIVERS.map(d => d.number);
const BROADCASTERS = [
  "josef holden",
  "matthew crossan",
  "rayon dabre",
  "somyaneel sinha",
  "david van de sanden",
  "ruggero franich",
  "sandeep bhatti",
  "ruchika sharma",
  "aditya mhatre",
  "sanjay goraniya"
];
const ENERGY_CONFIG_UPDATED_KEY = "car_evenrgy_config";
const EP_GLOBAL_PARENT_NAME = 'global';
const FACTORS_MASTER = [{
  key: 'pushLap', value: 'pushLap', label: 'Push', color: '#ADD37C'
}, {
  key: 'garageToPitExit', value: 'garageToPitExit', label: 'Garage To Pit Exit'
}, {
  key: 'inLapToPitEntry', value: 'inLapToPitEntry', label: 'In Lap To Pit Entry'
}, {
  key: 'pitEntryToGarage', value: 'pitEntryToGarage', label: 'Pit Entry To Garage'
}, {
  key: 'outLapFromPitExit', value: 'outLapFromPitExit', label: 'Out Lap From Pit Exit'
}, {
  key: 'outLap', value: 'outLap', label: 'Out', color: '#CD2318'
}, {
  key: 'buildLap', value: 'buildLap', label: 'Build', color: '#E8DB9C'
}, {
  key: 'inLap', value: 'inLap', label: 'In', color: '#884DE3'
}, {
  key: 'minPitTime', value: 'minPitTime', label: 'Minimum PIT time', color: "#5687F4"
}, {
  key: 'safetyMargin', value: 'safetyMargin', label: 'Safety Margin'
}];
const GROUPS_DRIVER_MAP: IGroup = {
  groupA: 1,
  groupB: 2,
};
const TYPE_GROUP_MAP = {
  'a': {
    group: 'groupA', driverID: 1,
  }, 'b': {
    group: 'groupB', driverID: 2
  }
};
// // const RESIZE_CONFIG = {
// //     LAYOUT_CONFIG: {xxl: 12, xl: 12, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2},
// //     RADIO_LAYOUT: {x: 0, y: 0, w: 6, h: 4,},
// //     STATUS_LAYOUT: {x: 6, y: 0, w: 4, h: 2,},
// //     BATTERY_LAYOUT: {x: 10, y: 0, w: 4, h: 4,},
// //     GAP_TO_LEADER_LAYOUT: {x: 0, y: 4, w: 6, h: 4,},
// //     RACE_CONTROL_MESSAGES_LAYOUT: {x: 6, y: 2, w: 2, h: 2,},

// //     GPS_LAYOUT: {x: 0, y: 0, w: 6, h: 2,},

// //     GPS_LAYOUT_2: {x: 0, y: 3, w: 6, h: 2,},

// //     FLAG_LAYOUT: {x: 3, y: 1, w: 5, h: 1,},
// //     RACE_CONTROL_LAYOUT: {x: 8, y: 0, w: 4, h: 2,},
// //     RACE_CONTROL_LAYOUT_: {x: 7, y: 0, w: 3.5, h: 3.5,},
// //     LAP_LAYOUT: {x: 10, y: 8, w: 2, h: 1,},
// //     WEATHER_LAYOUT: {x: 10, y: 4, w: 2, h: 2,},
// //     MINIFIED_REMLAPS_LAYOUT: {x: 4.5, y: 4, w: 4, h: 3,},
// //     CAR_DASH_LAYOUT: {x: 0, y: 0, w: 3, h: 2, isResizable: false},
// //     EM_LAYOUT: {x: 3, y: 0, w: 4, h: 1, isResizable: false},
// //     STARTING_GRID_LAYOUT: {x: 0, y: 10, w: 2, h: 4,},
// //     RADIO_WIDGET: {x: 0, y: 4, w: 5, h: 3,},
// //     RADIO_WIDGET_ENGINEERS_PAGE: {x: 0, y: 4, w: 4, h: 5,},
// //     BATTERY_ENGINEERS_PAGE: {x: 9, y: 3.5, w: 1.7, h: 5,},
// //     CIRCUIT_MAP_LAYOUT:{ x: 5, y: 4, w: 5, h: 3, },
// // };
// const RESIZE_CONFIG = {
//     LAYOUT_CONFIG: {xxl: 12, xl: 12, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2},
//     RADIO_LAYOUT: {x: 0, y: 0, w: 6, h: 4,},
//     STATUS_LAYOUT: {x: 6, y: 0, w: 4, h: 2,},
//     BATTERY_LAYOUT: {x: 10, y: 0, w: 4, h: 4,},
//     GAP_TO_LEADER_LAYOUT: {x: 0, y: 4, w: 6, h: 4,},
//     RACE_CONTROL_MESSAGES_LAYOUT: {x: 6, y: 2, w: 2, h: 2,},

//     GPS_LAYOUT: {x: 0, y: 0, w: 6, h: 2,},

//     GPS_LAYOUT_2: {x: 0, y: 3, w: 6, h: 2,},

//     FLAG_LAYOUT: {x: 3, y: 1, w: 4, h: 2,},
//     RACE_CONTROL_LAYOUT: {x: 7, y: 1, w: 4, h: 2,},
//     RACE_CONTROL_LAYOUT_: {x: 7, y: 4, w: 2, h: 7,},
//     LAP_LAYOUT: {x: 10, y: 8, w: 2, h: 1,},
//     WEATHER_LAYOUT: {x: 10, y: 4, w: 2, h: 2,},
//     MINIFIED_REMLAPS_LAYOUT: { x: 2.8, y: 0, w: 4.2, h: 3, },
//     CAR_DASH_LAYOUT: {x: 0, y: 0, w: 2.5, h: 3, isResizable: false},
//     EM_LAYOUT: {x: 7, y: 0, w: 3.5, h: 1, isResizable: false},//{x: 3, y: 0, w: 4, h: 1,},
//     EM_LAYOUT_ENGINEERS_PAGE: {x: 3.5, y: 5, w: 3.5, h: 3,},
//     STARTING_GRID_LAYOUT: {x: 0, y: 10, w: 2, h: 4,},
//     RADIO_WIDGET: {x: 0, y: 4, w: 5, h: 3,},
//     RADIO_WIDGET_ENGINEERS_PAGE: {x: 0, y: 4.5, w: 3.5, h: 5,},
//     BATTERY_ENGINEERS_PAGE: {x: 9, y: 4, w: 1.4, h: 5,},
//     CIRCUIT_MAP_LAYOUT:{ x: 5, y: 4, w: 5, h: 3, },
//     CIRCUIT_MAP_LAYOUT_ENG:{x: 3.5, y: 6, w: 4, h: 4,},
//     REMINDERS_LAYOUT:{x: 9, y: 0, w: 2, h: 4,},
// };

const FLAG_MAP = {
  RED: { bgColor: '#FF0000', color: '#000' },
  GREEN: { bgColor: '#00FF00', color: '#000' },
  YELLOW: { bgColor: '#FFFF00', color: '#000' },
  FULL_YELLOW: { bgColor: '#FFEA00', color: '#000' },
  SAFETY_CAR: { bgColor: '#0000FF', color: '#ffffff' },
  FINISH: { bgColor: '#E4E3E2', color: '#000' },
  FLAG_INFO: { bgColor: '#E4E3E2', color: '#000' },
};
// const FLAG_COLOR_MAP = {
//     RED: 'bg-danger', GREEN: 'bg-success', YELLOW: 'bg-warning', FULL_YELLOW: 'bd-yellow-500', SAFETY_CAR: 'bg-primary', FINISH: 'bg-light', FLAG_INFO: 'bg-light'
// };

// const EVENT_SELECTOR_HIDDEN = [`/weather-station`, `/weather-station-test`, `/add-panels`, `/login`, '/svg', '/restream-manager', '/video-stream', '/my-svg', '/qualifying', '/audio-player'];

// const LAP_NO_HIDDEN_MODULES = ['/remaining-laps', '/'];
// const FLAG_HIDDEN_MODULES = [];

const VIDEO_STREAMS = [
  { id: 5051, name: 'Cam 1', stream: `http://10.1.30.90:5051/player.html?autoplay=1`, httpsStream: `/video-restream/1/player.html?autoplay=1` },
  { id: 5052, name: 'Cam 2', stream: `http://10.1.30.90:5052/player.html?autoplay=1`, httpsStream: `/video-restream/2/player.html?autoplay=1` },
  // {id: 5053, name: 'Cam 3', stream: `http://10.1.30.90:5053/player.html?autoplay=1`, httpsStream: `/video-restream/3/player.html?autoplay=1`},
  // {id: 5054, name: 'Cam 4', stream: `http://10.1.30.90:5054/player.html?autoplay=1`, httpsStream: `/video-restream/4/player.html?autoplay=1`},
  { id: 5055, name: 'Cam 3', stream: `http://10.1.30.90:5055/player.html?autoplay=1`, httpsStream: `/video-restream/5/player.html?autoplay=1` },
  { id: 5056, name: 'Cam 4', stream: `http://10.1.30.90:5056/player.html?autoplay=1`, httpsStream: `/video-restream/6/player.html?autoplay=1` },
  { id: 5050, name: 'Cam 5', stream: `http://10.1.30.90:5050/player.html?autoplay=1`, httpsStream: `/video-restream/7/player.html?autoplay=1` },
  { id: 5058, name: 'Cam 6', stream: `http://10.1.30.90:5058/player.html?autoplay=1`, httpsStream: `/video-restream/8/player.html?autoplay=1` },
  { id: 5059, name: 'Cam 7', stream: `http://10.1.30.90:5059/player.html?autoplay=1`, httpsStream: `/video-restream/9/player.html?autoplay=1` },
];

const LS_KEY: string = "__user";

const ATTACK_MODE_BLUE = "#1EB2E5";

const EVENTS_WITH_INTERVENTION = ["RACE", "FP"];

const TPMS_CHARTS = [
  { name: 'Air Temp.', smallName: 'AT', color: '#e42432', yLabel: "Temperature", colName: 'airTemp', threshold: 'airTemp', displayUnit: '°C', precision: 1 },
  { name: 'abs. pressure/Temp Ratio', smallName: 'p/T', color: '#008b02', yLabel: "abs. pressure/Temp", colName: 'ptRatio', displayUnit: 'mbar/°C' },
  { name: 'Relative Pressure', smallName: 'RP', color: '#ffc200', yLabel: "Pressure", colName: 'relPressure', threshold: 'pressure', displayUnit: 'bar', precision: 3 }]

const TEMP_LOOP_MAP = ['FL', 'SC2', 'TV1', 'IP1', 'TV2', 'IP2', 'TV3', 'SC1']
const COMMON = {
  OUR_CARS,
  LS_KEY,
  CLIENT_ID,
  AUTHORITY_WITH_DOMAIN,
  BASE_PATH,
  ENERGY_CONFIG_UPDATED_KEY,
  BROADCASTERS,
  ATTACK_MODE_BLUE,
  EVENTS_WITH_INTERVENTION,
  FLAG_COLOR_MAP,
  GROUPS_DRIVER_MAP,
  FACTORS_MASTER,
  TPMS_CHARTS,
  VIDEO_STREAMS,
  TYPE_GROUP_MAP,
  EP_GLOBAL_PARENT_NAME,
  FLAG_MAP,
  TEMP_LOOP_MAP
};

const config: any = {
  DEV: {
    ...COMMON,
    BATTERY_IMAGE_PATH: `https://rubicon.greenest.team/assets/{{IMG}}`,
    API_BASE: 'https://rubicon.greenest.team/apis',
    NEW_API_BASE: 'https://rubicon.greenest.team/apis',
    //API_BASE : `https://localhost:7201`,
    SOCKET_HOST: "https://rubicon.greenest.team",
    PYTHON_HOST: `https://rubicon.greenest.team/flask`,
    RESTREAMER_HOST: `https://rubicon.greenest.team/restreamer`,
    RESTREAMER_STREAMS_HOST: `https://rubicon.greenest.team`,
  },
  PROD: {
    ...COMMON,
    BATTERY_IMAGE_PATH: `/assets/{{IMG}}`,
    API_BASE: `/apis`,
    NEW_API_BASE: `/apis`,
    //API_BASE : `https://localhost:7201`,
    SOCKET_HOST: "",
    PYTHON_HOST: `/flask`,
    RESTREAMER_HOST: `/restreamer`,
    RESTREAMER_STREAMS_HOST: ``,
  },
}[process.env.REACT_APP_ENV || "PROD"];
export default config;
