// import DataTable from 'react-data-table-component';
import { useEffect, useState } from "react";
import moment from "moment";
import { groupBy, sortBy } from "lodash";
import { useSelector } from "react-redux";

import config from "config";
import { fetchAPIData } from "../../common";
import { MRTable } from "components";
import { RootState } from "@/redux/store";
import { ILap } from "@mahindraformulae/rubicon-hooks";

interface IlapData {
  raceEventId: number;
  sessionId: number;
  sessionGroupId: null;
  driverNo: number;
  ts: Date;
  driver: number;
  elapsedTime: number;
  lapNumber: number;
  lapTime: number;
  lapTimeValid: boolean;
}

function LapTimes() {
  const selectedEvent = useSelector((state: RootState) => state.global.selectedEvent);
  const lapsdata = useSelector((state: RootState) => state.global.laps);
  const __driverMap = useSelector((state: RootState) => state.global.sessionInfo);
  const sessionInfo = useSelector((state: RootState) => state.global.sessionInfoDetails);
  const [lapTimeTableData, setLapTimeTableData] = useState<Record<string, { rows: any[], columns: any[] }>>({});
  const [highlightIndex, setHighlightIndex] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = (sessionId: number) => {
      const { raceEventId: selectedEventID, isCurrent }: any = selectedEvent;
      if (selectedEventID && __driverMap) {
        const laps: number[] = [];
        const colsData = [];
        const rowData: any = [];
        const filteredLapsdata = lapsdata.filter(lap => lap.sessionId === sessionId);

        colsData.push({
          name: "Lap Number",
          selector: "lapNumber",
          isSortable: false,
        });


        const overallFastestLap = sortBy(filteredLapsdata.filter(lap => lap.lapNumber !== 0), [(lap) => lap.lapTime])[0];
        const driversLaps = groupBy(filteredLapsdata, 'driverNo');
        const findFastestLap = (driverLaps: any) => {
          return driverLaps.filter((lap: any) => lap.lapNumber).reduce((fastest: any, lap: any) => (lap.lapTime < fastest.lapTime ? lap : fastest), { lapTime: Number.POSITIVE_INFINITY });
        };

        // Iterate through the grouped object and filter out the fastest lapTime object for each driver
        const fastestLapsByDriver: any = {};
        for (const driverNo in driversLaps) {
          const driverLaps = driversLaps[driverNo];
          const fastestLap = findFastestLap(driverLaps);
          fastestLapsByDriver[driverNo] = fastestLap;
        }
        filteredLapsdata.forEach((IDataItem: ILap) => {
          if (!laps.includes(IDataItem.lapNumber)) {
            laps.push(IDataItem.lapNumber);
            const lapArr = filteredLapsdata.filter((i: ILap) => i.lapNumber === IDataItem.lapNumber);
            const rowDataItem: any = {
              lapNumber: {
                value: IDataItem.lapNumber,
              },
            };
            const fastestLap = sortBy(lapArr, [(lap) => lap.lapTime])[0];
            lapArr.forEach((driverLapData: ILap) => {
              const driver = __driverMap[driverLapData.driverNo];
              if (driver) {
                rowDataItem[driver.driverNo] = {
                  value: moment.utc(driverLapData?.lapTime).format("mm:ss.SSS"),
                };
                if (fastestLap.driverNo === driverLapData.driverNo) {
                  rowDataItem[driver.driverNo] = {
                    ...rowDataItem[driver.driverNo],
                    isHighLight: true,
                    style: {
                      backgroundColor: "#ffffff90",
                      color: "#000000",
                    },
                  };
                }
                if (overallFastestLap?.driverNo === driverLapData.driverNo && overallFastestLap.lapTime === driverLapData.lapTime) {
                  rowDataItem[driver.driverNo] = {
                    ...rowDataItem[driver.driverNo],
                    isHighLight: true,
                    style: {
                      backgroundColor: "#884DE3",
                      color: "#ffffff",
                    },
                  };
                }
                if (fastestLapsByDriver[driver?.driverNo]?.lapNumber === driverLapData?.lapNumber && overallFastestLap.driverNo !== driverLapData.driverNo && overallFastestLap.lapTime !== driverLapData.lapTime) {
                  rowDataItem[driver.driverNo] = {
                    ...rowDataItem[driver.driverNo],
                    isHighLight: true,
                    style: {
                      backgroundColor: "#18f30790",
                      color: "#ffffff",
                    },
                  };
                }
              }
            });
            rowData.push(rowDataItem);
          }
        });
        // console.log(Object.keys(Object.keys(rowData?.[0] ?? {})).filter(d => Number(d)));
        Object.keys(__driverMap).filter(driverNo => rowData?.[0]?.[driverNo]).forEach((driveNo) => {
          colsData.push({
            name: __driverMap[+driveNo].name,
            selector: __driverMap[+driveNo].driverNo,
            isSortable: false,
          });
        });

        return ({
          columns: colsData,
          rows: rowData,
        });
      }
    };
    let tableData: Record<string, { rows: any[], columns: any[] }> = {};
    sessionInfo.forEach((session) => {
      let fetchedData = fetchData(session.sessionId);
      if (fetchedData) {
        tableData[session.name] = fetchedData;
      }
    })
    setLapTimeTableData(tableData)
  }, [lapsdata, __driverMap, selectedEvent, highlightIndex, sessionInfo]);

  return (
    <>
      <div className="text-center">
        <h3>Lap Times</h3>
        <p>
          <span className="mr-6 text-green-600"> Green - Driver's Best Lap</span>
          <span className="mr-6 text-gray-400"> Grey - Lap's best driver</span>
          <span className="text-purple-400"> Purple -Session Best Driver</span>
        </p>
      </div>
      <div className="bg-gray-900 w-full rounded-lg p-4 grid grid-flow-row gap-4">
        {Object.entries(lapTimeTableData).map(([sessionName, data], index) => <MRTable key={index} narrowRows={true} title={sessionName} data={data} highlightIndex={highlightIndex} setHighlightIndex={setHighlightIndex} />)}
      </div>
    </>);
}

export default LapTimes;
