import { useState, useRef, useEffect } from 'react';
import { orderBy } from 'lodash';
import ReactToPrint from 'react-to-print';
import { PrinterIcon, ChevronUpIcon, ChevronDownIcon, FunnelIcon } from '@heroicons/react/24/outline';
import useOutsideAlerter from 'components/Common/useOutsideAlerter';
import ExportDropDown from './ExportDropDown';
import './MRTable.css';

export interface IMRTableColumn {
  name: string;
  selector: string;
  isSortable?: boolean;
  isFilter?: boolean;
}

export interface IMRTableRow {
  [key: string]: {
    value: number | string | JSX.Element;
    isHighLight?: boolean;
    style?: any;
  };
}

export interface IMRTableData {
  rows: IMRTableRow[];
  columns: IMRTableColumn[];
}

export interface IMRTableProps {
  title?: string;
  data: IMRTableData;
  narrowRows?: boolean;
  highlightIndex?: any;
  setHighlightIndex?: any;
}

const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function updateArray(A: any[], B: any[]) {
  // Create a map from Array B for quick lookup
  const bMap = new Map(B.map(item => [item.value, item.checked]));

  // Update Array A based on the map created from Array B
  A.forEach(item => {
    if (bMap.has(item.value)) {
      item.checked = bMap.get(item.value);
    }
  });

  return A;
}

function groupFiltersByColumn(filters: any[]) {
  return filters.reduce((acc, filter) => {
    if (filter.checked) {
      if (!acc[filter.column]) {
        acc[filter.column] = [];
      }
      acc[filter.column].push(filter.value);
    }
    return acc;
  }, {});
}


function mergeArrays(arrA: any[], arrB: any[], col: string) {
  let arrayC: any[] = [];
  let cloneA = arrA;
  if (arrA?.length > 0) {
    if (arrB?.length > 0) {
      arrB.forEach((a) => {
        let columnExist = arrA?.filter((b) => b?.column === a?.column);
        let differentCols = arrA?.filter((b) => b?.column !== a?.column);
        if (columnExist?.length > 0) {
          cloneA = differentCols;
        } else {
          cloneA = cloneA;
        }
        arrayC = [...arrB, ...cloneA]
      })
    } else {
      arrayC = arrA.filter((a) => a?.column !== col);
    }
  } else if (arrB?.length > 0) {
    arrayC = arrB;
  } else {

  }

  return arrayC;
}

const FilterPopup = ({ options, onSave, onCancel, filtersSelected }: any) => {
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const handleCheckboxChange = (option: any) => {
    setSelectedOptions((prevOptions: any) =>
      prevOptions.map((opt: any) =>
        opt.value === option.value ? { ...opt, checked: !opt.checked } : opt
      )
    );
  };

  const handleReset = () => {
    onSave(options.map((o: any) => ({ ...o, checked: false })), options);
    onCancel();
  }

  useEffect(() => {
    if (filtersSelected.length > 0) {
      let opts = updateArray(options, filtersSelected)
      setSelectedOptions(opts)
    } else {
      setSelectedOptions(options);
    }
  }, [options, filtersSelected])

  return (
    <div className="filter-popup">
      <div className='filter-opt'>
        {selectedOptions?.length > 0 && selectedOptions.map((option: any) => (
          <div key={option?.value} className="filter-option">
            <input
              type="checkbox"
              checked={option.checked}
              onChange={() => handleCheckboxChange(option)}
            />
            <p>
              {option.label}
            </p>
          </div>
        ))}
      </div>
      <div className="filter-actions">
        <button onClick={() => onSave(selectedOptions, options)}>Save</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
      <div className='flex justify-center'>
        <button className='reset' onClick={handleReset}>Clear Filter</button>
      </div>
    </div>
  );
};

const MRTable = ({
  title,
  data,
  narrowRows = false,
  highlightIndex,
  setHighlightIndex,
}: IMRTableProps) => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<string | null>(SORT_DIRECTIONS.ASC);
  const [rowsData, setRowsData] = useState<IMRTableRow[]>([]);
  const [columnsData, setColumnsData] = useState<IMRTableColumn[]>([]);
  const [filterColumn, setFilterColumn] = useState<any[]>([]);
  const [filterOptions, setFilterOptions] = useState<any>([]);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [filtersSelected, setFiltersSelected] = useState<any>([]);

  const tableRef = useRef(null);

  useOutsideAlerter(tableRef, function () {
    if (setHighlightIndex) {
      setHighlightIndex(null);
    }
    setShowFilterPopup(false);
  });

  useEffect(() => {
    let initData = data?.rows;
    let checkedVals = filtersSelected?.filter((s: any) => s?.checked);
    if (filterColumn?.length > 0 && checkedVals?.length > 0) {
      const filtersByColumn = groupFiltersByColumn(filtersSelected);
      initData = data?.rows.filter(item => {
        if (filtersSelected?.length > 0) {
          return Object.keys(filtersByColumn).every(column => {
            const columnValues = filtersByColumn[column];
            const itemValue = item[column]?.value;
            return columnValues.includes(itemValue);
          });
        } else {
          return data?.rows;
        }
      });

    }
    setRowsData(initData);
    setColumnsData(data.columns);
    if (sortDir === SORT_DIRECTIONS.DESC && sortColumn) {
      let newData = orderBy(initData, [(item) => item[sortColumn]?.value], ['desc']);
      setRowsData(newData);
    } else if (sortDir === SORT_DIRECTIONS.ASC && sortColumn) {
      let newData = orderBy(initData, [(item) => item[sortColumn]?.value], ['asc']);
      setRowsData(newData);
    }
  }, [data]);

  const handleSort = (column: string) => {
    let newData = [...rowsData];
    setSortColumn(column);
    if (sortDir === SORT_DIRECTIONS.DESC && column === sortColumn) {
      newData = orderBy(newData, [(item) => item[column]?.value], ['asc']);
      setSortDir(SORT_DIRECTIONS.ASC);
      setRowsData(newData);
    } else {
      let changed = orderBy(newData, [(item) => item[column]?.value], ['desc']);
      setSortDir(SORT_DIRECTIONS.DESC);
      setRowsData(changed);
    }
  };

  useEffect(() => {
    if (sortDir === SORT_DIRECTIONS.DESC && sortColumn) {
      let newData = orderBy(rowsData, [(item) => item[sortColumn]?.value], ['desc']);
      setRowsData(newData);
    } else if (sortDir === SORT_DIRECTIONS.ASC && sortColumn) {
      let newData = orderBy(rowsData, [(item) => item[sortColumn]?.value], ['asc']);
      setRowsData(newData);
    }
  }, [sortDir, sortColumn]);

  const handleFilterIconClick = (column: any) => {
    const uniqueValues = new Set();
    const options = data.rows.reduce((acc: any, row) => {
      const value = row[column]?.value;
      if (value && !uniqueValues.has(value)) {
        uniqueValues.add(value);
        acc.push({ value, label: value, checked: false, column: column });
      }
      return acc;
    }, []);
    setFilterOptions(options);
    setFilterColumn(prevValue => [...prevValue, column]);
    setShowFilterPopup(true);
  };

  const handleFilterSave = (selectedOptions: any, options: any) => {
    let col = options?.[0]?.column;
    const filteredOpts = selectedOptions.filter((s: any) => s?.checked)
    let selectedOpts = mergeArrays(filtersSelected, filteredOpts, col)
    const filtersByColumn = groupFiltersByColumn(selectedOpts);

    let filteredData = data?.rows.filter(item => {
      if (selectedOpts?.length > 0) {
        return Object.keys(filtersByColumn).every(column => {
          const columnValues = filtersByColumn[column];
          const itemValue = item[column]?.value;
          return columnValues.includes(itemValue);
        });
      } else {
        return data?.rows;
      }
    });

    if (sortDir === SORT_DIRECTIONS.DESC && sortColumn) {
      filteredData = orderBy(filteredData, [(item) => item[sortColumn]?.value], ['desc']);

    } else if (sortDir === SORT_DIRECTIONS.ASC && sortColumn) {
      filteredData = orderBy(filteredData, [(item) => item[sortColumn]?.value], ['asc']);
    }

    setFiltersSelected(selectedOpts)
    setRowsData(filteredData);
    setShowFilterPopup(false);
  };

  return (
    <div className="mr-table-block flex flex-col">
      <div className="flex relative pb-1">
        <div className="me-auto ms-auto">
          <h3 className={title ? "opacity-100 select-none text-center" : "opacity-0 select-none"}><i>{title ?? "Placeholder Title"}</i></h3>
        </div>
        <div className="absolute top-0 right-0">
          <ReactToPrint
            trigger={() => (
              <button className="py-2">
                <PrinterIcon />
              </button>
            )}
            content={() => tableRef.current}
          />
          <ExportDropDown
            rowsData={rowsData}
            columnsData={columnsData}
            tableRef={tableRef}
          />
        </div>
      </div>
      <div ref={tableRef}>
        <table className="min-w-full text-center text-sm font-light">
          <thead className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900 sticky top-[-10px]">
            <tr>
              {columnsData?.map((columnsDataItem, i) => (
                <th
                  key={`th-${Date.now()}-${i}`}
                  scope="col"
                  className={`mr-table-head-th${narrowRows ? "px-2 py-1" : "px-6 py-4"}`}
                  style={{ background: highlightIndex === columnsDataItem.name && highlightIndex !== 'Lap Number' ? 'black' : '' }}
                >
                  <div className='flex'>
                    <span className="mr-table-head-title">{columnsDataItem.name}</span>
                    {sortColumn === columnsDataItem.selector && sortDir === SORT_DIRECTIONS.DESC && <label className='cursor-pointer' onClick={() => {
                      if (columnsDataItem.isSortable) {
                        handleSort(columnsDataItem.selector);
                      }
                      if (setHighlightIndex) {
                        setHighlightIndex(columnsDataItem.name);
                      }
                    }}><ChevronDownIcon /></label>}
                    {((sortColumn === columnsDataItem.selector && sortDir === SORT_DIRECTIONS.ASC) || (columnsDataItem?.isSortable && sortColumn !== columnsDataItem.selector)) &&
                      <label className='cursor-pointer' onClick={() => {
                        if (columnsDataItem.isSortable) {
                          handleSort(columnsDataItem.selector);
                        }
                        if (setHighlightIndex) {
                          setHighlightIndex(columnsDataItem.name);
                        }
                      }}>
                        <ChevronUpIcon />
                      </label>
                    }
                    {columnsDataItem?.isFilter && (
                      <label onClick={() => handleFilterIconClick(columnsDataItem.selector)} >
                        <FunnelIcon />
                      </label>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowsData?.map((rowDataItem: any, i: number) => (
              <tr key={`tr-${Date.now()}-${i}`} className="border-b dark:border-neutral-500">
                {columnsData.map((columnsDataItem, j) => (
                  <td
                    key={`td-${Date.now()}-${j}`}
                    className={`whitespace-nowrap ${narrowRows ? "px-2 py-1" : "px-6 py-4"}`}
                    style={
                      rowDataItem[columnsDataItem.selector]?.isHighLight
                        ? rowDataItem[columnsDataItem.selector]?.style
                        : { background: highlightIndex === columnsDataItem?.name && highlightIndex !== 'Lap Number' ? 'black' : '' }
                    }
                  >
                    {rowDataItem[columnsDataItem.selector]?.value || "--"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {showFilterPopup && (
          <div className='absolute top-52 left-52'>
            <FilterPopup
              options={filterOptions}
              onSave={handleFilterSave}
              onCancel={() => { setShowFilterPopup(false); }}
              filtersSelected={filtersSelected}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MRTable;
