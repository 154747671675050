import { useEffect, useMemo, useRef } from "react";
import { useWebWorker } from "./useWebWorker";
import { IAlkamelDiagnostics, IDriverPositionMini, ITimingCombined } from "@mahindraformulae/rubicon-hooks";
import { ITimingRankingWorkerRequest } from "./timingRankingScript";
import { ITimingCombinedWorkerRequest } from "./timingCombinedScript";
import { AlkamelDiagnosticsWorkerRequest } from "./alkamelDiagnosticsScript";
import { INotificationMsg, INotificationsWorkerRequest } from "./notificationsScript";

const DEFAULT_EMPTY_ARRAY = [] as any;
const DEFAULT_EMPTY_OBJECT = {} as any;

function useTimingRanking({ _laps, _loops, _currRace }: ITimingRankingWorkerRequest): [IDriverPositionMini[], boolean, string | undefined] {
  const workerTimingRanking = useMemo(() => new Worker(new URL('timingRankingScript.ts', import.meta.url)), []);
  const timingRankingFromWorker = useWebWorker<IDriverPositionMini[], ITimingRankingWorkerRequest>(workerTimingRanking);
  const timingRankingProcessRef = useRef(timingRankingFromWorker.startProcessing);
  useEffect(() => {
    timingRankingProcessRef.current({ _laps, _loops, _currRace });
  }, [timingRankingProcessRef, _laps, _loops, _currRace,]);

  const { result = DEFAULT_EMPTY_ARRAY, running, error } = timingRankingFromWorker
  return [result, running, error]
}

function useTimingCombined({ _sectorTimes, _lapSpeeds, _currentRace, _sessionInfo, }: ITimingCombinedWorkerRequest): [ITimingCombined, boolean, string | undefined] {
  const workerTimingCombined = useMemo(() => new Worker(new URL('timingCombinedScript.ts', import.meta.url)), []);
  const timingCombinedFromWorker = useWebWorker<ITimingCombined, ITimingCombinedWorkerRequest>(workerTimingCombined);

  const timingCombinedProcessRef = useRef(timingCombinedFromWorker.startProcessing)
  useEffect(() => {
    timingCombinedProcessRef.current({ _sectorTimes, _lapSpeeds, _currentRace, _sessionInfo, });
  }, [timingCombinedProcessRef, _sectorTimes, _lapSpeeds, _currentRace, _sessionInfo,]);

  const { result = DEFAULT_EMPTY_OBJECT, running, error } = timingCombinedFromWorker
  return [result, running, error]
}

function useAlkamelDiagnostics({ laps, loops, raceInfo, __driverMap }: AlkamelDiagnosticsWorkerRequest): [IAlkamelDiagnostics, boolean, string | undefined] {
  const workerAlkamelDiagnostics = useMemo(() => new Worker(new URL('alkamelDiagnosticsScript.ts', import.meta.url)), []);
  const alkamelDiagnosticsWorker = useWebWorker<IAlkamelDiagnostics, AlkamelDiagnosticsWorkerRequest>(workerAlkamelDiagnostics);
  const alkamelDiagnosticsProcessRef = useRef(alkamelDiagnosticsWorker.startProcessing);
  useEffect(() => {
    alkamelDiagnosticsProcessRef.current({ laps, loops, raceInfo, __driverMap });
  }, [alkamelDiagnosticsProcessRef, laps, loops, raceInfo, __driverMap]);
  const { result = DEFAULT_EMPTY_OBJECT, running, error } = alkamelDiagnosticsWorker
  return [result, running, error]

}
function useNotifications({ laps, selectedEvent, attackSummary, interventionState, lapsAddedFromDB, OUR_CARS, attackModeTaken, AMLapThreshold }: INotificationsWorkerRequest): [INotificationMsg[], boolean, string | undefined] {
  const workerNotifications = useMemo(() => new Worker(new URL('notificationsScript.ts', import.meta.url)), []);
  const notificationsWorker = useWebWorker<INotificationMsg[], INotificationsWorkerRequest>(workerNotifications);
  const notificationsProcessRef = useRef(notificationsWorker.startProcessing);
  useEffect(() => {
    notificationsProcessRef.current({ laps, selectedEvent, attackSummary, interventionState, lapsAddedFromDB, OUR_CARS, attackModeTaken, AMLapThreshold });
  }, [notificationsProcessRef, laps, selectedEvent, attackSummary, interventionState, lapsAddedFromDB, OUR_CARS, attackModeTaken]);
  const { result = DEFAULT_EMPTY_ARRAY, running, error } = notificationsWorker
  return [result, running, error]
}
export {
  useTimingRanking,
  useTimingCombined,
  useAlkamelDiagnostics,
  useNotifications,
}