import useWindowSize from './useWindowSize';

const DIMENSIONS = {
  height: 1080,
  width: 1920
}

const useWindowZoom = () => {
  const { width } = useWindowSize();  
  return width >= DIMENSIONS.width ? undefined : 0.75;
}

export default useWindowZoom;
