
export const RotatingWheel =
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="h-64 w-64 rotating">
    <path fill="white" strokeLinejoin="round" d="M 10 8.457 C 9.151 8.457 8.457 9.151 8.457 10 C 8.457 10.849 9.151 11.543 10 11.543 C 10.849 11.543 11.543 10.849 11.543 10 C 11.543 9.151 10.849 8.457 10 8.457 L 10 8.457 Z M 10 9.075 C 10.515 9.075 10.925 9.485 10.925 10 C 10.925 10.515 10.515 10.925 10 10.925 C 9.485 10.925 9.075 10.515 9.075 10 C 9.075 9.485 9.485 9.075 10 9.075 Z"></path>
    <path fill="white" strokeLinejoin="round" d="M 10 0.739 C 4.889 0.739 0.739 4.889 0.739 10 C 0.739 15.111 4.889 19.261 10 19.261 C 15.111 19.261 19.261 15.111 19.261 10 C 19.261 4.889 15.111 0.739 10 0.739 Z M 10 1.357 C 14.777 1.357 18.643 5.223 18.643 10 C 18.643 14.777 14.777 18.643 10 18.643 C 5.223 18.643 1.357 14.777 1.357 10 C 1.357 5.223 5.223 1.357 10 1.357 L 10 1.357 Z"></path>
    <path fill="white" strokeLinejoin="round" d="M 10 3.21 C 6.253 3.21 3.21 6.253 3.21 10 C 3.21 13.747 6.253 16.79 10 16.79 C 13.747 16.79 16.79 13.747 16.79 10 C 16.79 6.253 13.747 3.21 10 3.21 L 10 3.21 Z M 10 3.827 C 13.413 3.827 16.173 6.587 16.173 10 C 16.173 13.413 13.413 16.173 10 16.173 C 6.587 16.173 3.827 13.413 3.827 10 C 3.827 6.587 6.587 3.827 10 3.827 L 10 3.827 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 9.69 1.086 C 9.69 1.086 9.691 1.27 9.736 1.494 C 9.78 1.718 9.864 2.003 10.091 2.23 C 10.212 2.349 10.406 2.349 10.526 2.23 C 10.646 2.109 10.646 1.915 10.526 1.794 C 10.445 1.713 10.374 1.535 10.342 1.373 C 10.309 1.211 10.308 1.086 10.308 1.086 L 9.69 1.086 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 9.474 17.77 C 9.354 17.891 9.354 18.085 9.474 18.206 C 9.555 18.287 9.626 18.465 9.658 18.627 C 9.691 18.789 9.69 18.914 9.69 18.914 L 10.308 18.914 C 10.308 18.914 10.309 18.73 10.264 18.506 C 10.22 18.282 10.136 17.997 9.909 17.77 C 9.788 17.651 9.594 17.651 9.474 17.77 Z"></path>
    <path fill="white" strokeLinejoin="round" d="M 10 4.445 C 6.935 4.445 4.445 6.935 4.445 10 C 4.445 13.065 6.935 15.555 10 15.555 C 13.065 15.555 15.555 13.065 15.555 10 C 15.555 6.935 13.065 4.445 10 4.445 L 10 4.445 Z M 10 5.06 C 12.731 5.06 14.938 7.269 14.938 10 C 14.938 12.731 12.731 14.938 10 14.938 C 7.269 14.938 5.06 12.731 5.06 10 C 5.06 7.269 7.269 5.06 10 5.06 L 10 5.06 Z"></path>
    <path fill="white" strokeLinejoin="round" d="M 10 7.222 C 8.469 7.222 7.222 8.469 7.222 10 C 7.222 11.531 8.469 12.778 10 12.778 C 11.531 12.778 12.778 11.531 12.778 10 C 12.778 8.469 11.531 7.222 10 7.222 Z M 10 7.84 C 11.197 7.84 12.16 8.803 12.16 10 C 12.16 11.197 11.197 12.16 10 12.16 C 8.803 12.16 7.84 11.197 7.84 10 C 7.84 8.803 8.803 7.84 10 7.84 L 10 7.84 Z"></path>
    <path fill="white" d="M 11.547 5.01 C 11.386 5.953 10.969 6.496 10.595 6.821 C 10.221 7.146 9.913 7.234 9.913 7.234 L 10.087 7.826 C 10.087 7.826 10.533 7.692 10.998 7.288 C 11.463 6.885 11.97 6.194 12.156 5.112 L 11.547 5.01 Z"></path>
    <path fill="white" d="M 9.913 12.174 C 9.913 12.174 9.467 12.308 9.002 12.712 C 8.537 13.115 8.03 13.806 7.844 14.888 L 8.453 14.99 C 8.614 14.047 9.031 13.504 9.405 13.179 C 9.779 12.854 10.087 12.766 10.087 12.766 L 9.913 12.174 Z"></path>
    <path fill="white" d="M 14.623 7.564 C 13.843 8.117 13.16 8.207 12.666 8.172 C 12.172 8.138 11.894 7.983 11.894 7.983 L 11.6 8.526 C 11.6 8.526 12.008 8.744 12.623 8.788 C 13.237 8.831 14.085 8.704 14.981 8.07 L 14.623 7.564 Z"></path>
    <path fill="white" d="M 7.377 11.212 C 6.763 11.169 5.915 11.296 5.019 11.93 L 5.377 12.434 C 6.157 11.881 6.837 11.793 7.332 11.828 C 7.826 11.862 8.106 12.017 8.106 12.017 L 8.4 11.474 C 8.4 11.474 7.992 11.256 7.377 11.212 Z"></path>
    <path fill="white" d="M 12.766 9.913 L 12.174 10.087 C 12.174 10.087 12.308 10.533 12.712 10.998 C 13.115 11.463 13.806 11.97 14.888 12.156 L 14.99 11.547 C 14.047 11.386 13.504 10.969 13.179 10.595 C 12.854 10.221 12.766 9.913 12.766 9.913 Z"></path>
    <path fill="white" d="M 5.112 7.844 L 5.01 8.453 C 5.953 8.614 6.496 9.031 6.821 9.405 C 7.146 9.779 7.234 10.087 7.234 10.087 L 7.826 9.913 C 7.826 9.913 7.692 9.467 7.288 9.002 C 6.885 8.537 6.194 8.03 5.112 7.844 Z"></path>
    <path fill="white" d="M 11.474 11.6 C 11.474 11.6 11.256 12.008 11.212 12.623 C 11.169 13.237 11.296 14.085 11.93 14.981 L 12.434 14.623 C 11.881 13.843 11.793 13.16 11.828 12.666 C 11.862 12.172 12.017 11.894 12.017 11.894 L 11.474 11.6 Z"></path>
    <path fill="white" d="M 8.07 5.019 L 7.564 5.377 C 8.117 6.157 8.207 6.837 8.172 7.332 C 8.138 7.826 7.983 8.106 7.983 8.106 L 8.526 8.4 C 8.526 8.4 8.744 7.992 8.788 7.377 C 8.831 6.763 8.704 5.915 8.07 5.019 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 14.189 2.125 C 14.189 2.125 14.097 2.285 14.024 2.501 C 13.951 2.718 13.881 3.006 13.964 3.316 C 14.009 3.48 14.177 3.577 14.341 3.534 C 14.505 3.489 14.602 3.321 14.559 3.157 C 14.529 3.046 14.557 2.856 14.61 2.7 C 14.662 2.543 14.724 2.434 14.724 2.434 L 14.189 2.125 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 5.659 16.466 C 5.495 16.511 5.398 16.679 5.441 16.843 C 5.471 16.954 5.443 17.144 5.39 17.3 C 5.338 17.457 5.275 17.565 5.275 17.565 L 5.809 17.874 C 5.809 17.874 5.903 17.715 5.976 17.499 C 6.049 17.282 6.119 16.994 6.036 16.684 C 5.991 16.52 5.823 16.423 5.659 16.466 L 5.659 16.466 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 17.565 5.275 C 17.565 5.275 17.406 5.368 17.234 5.518 C 17.063 5.669 16.858 5.884 16.774 6.194 C 16.731 6.358 16.828 6.527 16.992 6.571 C 17.156 6.615 17.325 6.517 17.369 6.353 C 17.399 6.242 17.518 6.092 17.642 5.983 C 17.766 5.874 17.873 5.81 17.873 5.81 L 17.565 5.275 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 3.007 13.43 C 2.843 13.386 2.675 13.483 2.63 13.647 C 2.6 13.759 2.481 13.909 2.357 14.018 C 2.233 14.127 2.125 14.189 2.125 14.189 L 2.433 14.724 C 2.433 14.724 2.593 14.633 2.765 14.482 C 2.937 14.332 3.142 14.117 3.225 13.807 C 3.268 13.643 3.171 13.474 3.007 13.43 L 3.007 13.43 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 18.913 9.691 C 18.913 9.691 18.729 9.691 18.505 9.736 C 18.281 9.781 17.996 9.865 17.769 10.092 C 17.65 10.212 17.65 10.407 17.769 10.527 C 17.89 10.647 18.084 10.647 18.205 10.527 C 18.286 10.445 18.464 10.375 18.626 10.342 C 18.788 10.31 18.913 10.308 18.913 10.308 L 18.913 9.691 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 2.229 9.474 C 2.108 9.355 1.914 9.355 1.793 9.474 C 1.712 9.556 1.534 9.626 1.372 9.659 C 1.21 9.691 1.085 9.691 1.085 9.691 L 1.085 10.308 C 1.085 10.308 1.269 10.31 1.493 10.265 C 1.717 10.22 2.002 10.137 2.229 9.909 C 2.348 9.789 2.348 9.595 2.229 9.474 L 2.229 9.474 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 17.874 14.189 C 17.874 14.189 17.714 14.098 17.498 14.025 C 17.282 13.952 16.993 13.882 16.683 13.965 C 16.519 14.009 16.422 14.177 16.465 14.342 C 16.51 14.505 16.678 14.603 16.842 14.559 C 16.953 14.529 17.143 14.557 17.299 14.61 C 17.456 14.663 17.565 14.724 17.565 14.724 L 17.874 14.189 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 3.533 5.66 C 3.488 5.496 3.32 5.398 3.156 5.442 C 3.045 5.472 2.855 5.444 2.699 5.391 C 2.542 5.338 2.434 5.275 2.434 5.275 L 2.125 5.81 C 2.125 5.81 2.284 5.903 2.5 5.976 C 2.717 6.05 3.005 6.12 3.315 6.036 C 3.479 5.992 3.576 5.824 3.533 5.66 L 3.533 5.66 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 14.724 17.565 C 14.724 17.565 14.631 17.406 14.481 17.235 C 14.33 17.063 14.115 16.858 13.805 16.775 C 13.641 16.732 13.472 16.829 13.428 16.993 C 13.384 17.157 13.482 17.325 13.646 17.37 C 13.757 17.399 13.907 17.518 14.016 17.642 C 14.125 17.766 14.189 17.874 14.189 17.874 L 14.724 17.565 Z"></path>
    <path fill="white" strokeLinecap="round" strokeLinejoin="round" d="M 6.569 3.008 C 6.613 2.844 6.516 2.675 6.352 2.631 C 6.24 2.601 6.09 2.482 5.981 2.358 C 5.872 2.234 5.81 2.125 5.81 2.125 L 5.275 2.434 C 5.275 2.434 5.366 2.594 5.517 2.766 C 5.667 2.937 5.882 3.142 6.192 3.225 C 6.356 3.269 6.525 3.172 6.569 3.008 L 6.569 3.008 Z"></path>
  </svg>

function LoadingScreen() {
  return <div className="absolute top-0 left-0 flex flex-col justify-center items-center h-full w-full before:bg-black before:bg-opacity-50 before:h-screen before:w-screen before:absolute before:top-0 before:left-0">
    <div>{RotatingWheel}</div>
    <h4 className=" text-white select-none z-10">Loading Rubicon..</h4>
  </div>
}

export default LoadingScreen;