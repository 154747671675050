import { MutableRefObject, useEffect } from 'react';

/**
 * Hook that alerts clicks outside the passed ref
 */
export default function useOutsideAlerter(ref: any, action: any, elemsToIgnore: MutableRefObject<any>[] = []) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (ref?.current && !ref.current.contains(event.target)) {
        const isExcludedElem = elemsToIgnore.some(el => el.current.contains(event.target));
        if (!isExcludedElem && (typeof action === 'function')) action();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [action, elemsToIgnore, ref]);
}