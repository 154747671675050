import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from 'services/auth';
import config from 'config';
import { IUser } from '@mahindraformulae/rubicon-hooks';

const LSUserData = localStorage.getItem(config.LS_KEY);
const user: IUser | null = LSUserData ? JSON.parse(LSUserData) : null;

export const login = createAsyncThunk('auth/login', async (token: string, thunkAPI) => {
  try {
    const data = await AuthService.login(token);
    return { user: data };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const logout = createAsyncThunk('auth/logout', async () => AuthService.logout());

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: user,
    isLoggedIn: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isLoggedIn = true;
      state.user = payload.user;
    });
    builder.addCase(login.rejected, (state) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoggedIn = false;
      state.user = null;
    });

  },
});

export default authSlice.reducer;