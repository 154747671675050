import React, { useState, useEffect, useRef, MouseEvent, ChangeEvent, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Icon from 'components/svg';
import { AppDispatch, isLinkedSession, RootState, store } from 'redux/store';
import { setSelectedEvent, getAllEvents, getFilteredEvents, getSessionEntry, flushGlobalData, getSessionInfoDetails, reloadPage } from 'redux/modules/global';
import useOutsideAlerter from 'components/Common/useOutsideAlerter';
import { IRaceEvent, IUserAuthData } from '@mahindraformulae/rubicon-hooks';
import { updateUserPreferences } from "redux/modules/global";
import { cloneDeep } from 'lodash';
import config from "config";
import './event-selector.css';
import { LinkIcon, LinkSlashIcon } from '@heroicons/react/24/solid';
import { IResponse } from '@/types';

const { NEW_API_BASE } = config;
export const REMEMBERED_EVT_PREFERENCE_MODULE_KEY = 'rememberedEvent'

function EventSelector({ isPopup }: any) {
  const dispatch = useDispatch<AppDispatch>();
  const EventSelectorWrapperElem = useRef<HTMLDivElement>(null);
  const EventSelectorInputElem = useRef<HTMLInputElement>(null);
  const allEvents = useSelector((state: RootState) => state.global.allEvents);
  const selectedEvent = useSelector((state: RootState) => state.global.selectedEvent);
  const userAuthInfo = useSelector((state: RootState) => state.global.userAuthData);
  const [isEventSelectorOpen, setEventSelectorOpen] = useState<boolean>(false);
  const [expandedEvent, setExpandedEvent] = useState(0);
  const [selectedEventInputText, setSelectedEventInputText] = useState('');
  const globalSettings = useMemo(() => userAuthInfo?.preferences?.preferences?.find((pref) => pref.module === REMEMBERED_EVT_PREFERENCE_MODULE_KEY)?.settings, [userAuthInfo]);

  useOutsideAlerter(EventSelectorWrapperElem, function () { setEventSelectorOpen(false); }, [EventSelectorInputElem]);
  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);

  useEffect(() => {
    if (selectedEvent?.raceEventId) {
      dispatch(getSessionEntry(selectedEvent))
      dispatch(getSessionInfoDetails(selectedEvent))
      setSelectedEventInputText(selectedEvent.raceEventName);
      if (selectedEvent?.parentId && typeof selectedEvent?.parentId === 'number')
        setExpandedEvent(selectedEvent.parentId);
    }
  }, [dispatch, selectedEvent]);
  const onEventFocus = () => {
    setEventSelectorOpen(true);
  };
  const onEventInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currVal = event.currentTarget.value;
    setSelectedEventInputText(currVal);
    dispatch(getFilteredEvents(currVal));
  };
  const onParentEventClick = (e: MouseEvent, event: IRaceEvent) => {
    e.stopPropagation();
    setExpandedEvent(currExpandedEv => {
      return event.raceEventId === currExpandedEv ? 0 : event.raceEventId;
    });
  };

  const onEventClick = (event: IRaceEvent) => {
    if (selectedEvent.raceEventId === event.raceEventId) return;
    if (((!globalSettings?.defaultEventId) || event.raceEventId !== globalSettings.defaultEventId) && isLinkedSession) {
      saveRememberedEvent(event.raceEventId, userAuthInfo).then(preferences => {
        dispatch(updateUserPreferences(preferences))
      });
    }
    dispatch(flushGlobalData());
    dispatch(setSelectedEvent(event));
    setEventSelectorOpen(false);
  };

  function getEventCodeColor() {
    let { startTs, isCurrent } = selectedEvent;
    if (isCurrent) {
      return "#08BE51"
    } else {
      if (startTs > new Date().valueOf()) {
        return "#fcdb03"
      } else {
        return "#909999"
      }
    }
  }

  const toggleLinking = useCallback(() => {
    if (isLinkedSession) {
      localStorage.setItem('linkedSessionFlag', "not-linked")
    } else {
      localStorage.removeItem('linkedSessionFlag')
      saveRememberedEvent(selectedEvent.raceEventId, userAuthInfo).then(preferences => dispatch(updateUserPreferences(preferences)));
    }
    dispatch(reloadPage())
  }, [dispatch, selectedEvent.raceEventId, userAuthInfo])

  const handleClick = () => {
    EventSelectorInputElem?.current?.select();
  }

  return (
    <>
      <div onClick={toggleLinking} title={isLinkedSession ? "Click to Unlink All Tabs" : "Click to Link All Tabs"} className='border-2 rounded-lg px-0.5 flex justify-center items-center hover:bg-gray-600 cursor-pointer' style={isLinkedSession ? { borderColor: "limegreen" } : { borderColor: "yellow" }}>
        {isLinkedSession ? <LinkIcon className='small-icon' /> : <LinkSlashIcon className='small-icon' />}
      </div>
      {!isPopup && (
        <div className="w-85 desktop:w-96 h-9 flex rounded-lg border border-[#252730]">
          <div className="flex justify-center items-center flex-none w-12 rounded-l-lg" style={{ backgroundColor: getEventCodeColor() }}>
            <b className='text-white' style={{ textShadow: "1px 1px 2px black" }}>{selectedEvent.raceEventId}</b>
          </div>
          <div className="flex-col flex-grow relative inline-block">
            <div className="flex h-full cursor-pointer">
              {/*<div className="flex justify-start items-center">
                <Icon className="w-10 h-4" type="Search"/>
              </div>*/}
              <div className="flex flex-grow items-center">
                <input
                  type="search"
                  className={classNames("w-full h-full p-0 pl-2 bg-transparent outline-none", { "border-yellow-700": selectedEvent.raceEventId === globalSettings?.defaultEventId })}
                  value={selectedEventInputText}
                  onFocus={onEventFocus}
                  onChange={onEventInputChange}
                  ref={EventSelectorInputElem}
                  onClick={handleClick}
                />
              </div>
              <div className={classNames("flex justify-end items-center rounded-e-lg", { "bg-yellow-700": selectedEvent.raceEventId === globalSettings?.defaultEventId })} onClick={onEventFocus}>
                <Icon className="w-10 h-4" type="ChevronDown" />
              </div>
            </div>
            {isEventSelectorOpen ?
              <div className="origin-top-right absolute left-0 min-w-fit w-full max-h-96 overflow-y-scroll rounded-md shadow-lg event-selector-wrapper z-50" ref={EventSelectorWrapperElem}>
                {allEvents.map(ev => {
                  const isCurrEvExpanded = ev.raceEventId === expandedEvent;
                  const eventListWrapperClasses = classNames('pt-0 overflow-hidden', { 'max-h-0': !isCurrEvExpanded });
                  const parentCls = classNames('cursor-pointer flex px-3 items-center h-10 transition hover:bg-slate-700');
                  const wrapperCls = classNames({ 'event-expanded': isCurrEvExpanded });
                  const iconType = isCurrEvExpanded ? 'ChevronDown' : 'ChevronRight';
                  return <div className={wrapperCls} key={ev.raceEventId}>
                    <div className={parentCls} onClick={(event) => onParentEventClick(event, ev)}>
                      <span className="flex grow text-base min-w-fit text-nowrap">{ev.raceEventName}</span>
                      <Icon type={iconType} className="w-4 h-4" />
                    </div>
                    <div className={eventListWrapperClasses}>
                      <ul>
                        {ev?.events?.map(childEv => {
                          const isEvSelected = selectedEvent?.raceEventId === childEv.raceEventId;
                          const isDefaultEvent = globalSettings?.defaultEventId === childEv.raceEventId;
                          const isEvLive = childEv.isCurrent;
                          const cNames = classNames('flex px-3 cursor-pointer py-2 text-sm list-event', { 'event-selected': isEvSelected, 'event-default': isDefaultEvent, 'event-live': isEvLive });
                          return <li key={childEv.raceEventId} onClick={() => onEventClick(childEv)} className={cNames}>
                            <span className="flex grow ms-2 min-w-fit text-nowrap">{childEv.raceEventName}</span>
                          </li>;
                        })}
                      </ul>
                    </div>
                  </div>;
                })}
              </div> : null}
          </div>
        </div>
      )}
    </>
  );
}

export async function saveRememberedEvent(raceEventId: number | null, userAuthInfo: IUserAuthData | null) {
  const globalSettings = userAuthInfo?.preferences?.preferences?.find((pref) => pref.module === REMEMBERED_EVT_PREFERENCE_MODULE_KEY)?.settings;
  const updatedGlobalSettings = { ...globalSettings, rememberSelectedEvent: !!raceEventId, defaultEventId: raceEventId };
  const __token = store.getState()?.auth?.user?.cipher ?? '';
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('sessionId', __token);
  const savedPref = cloneDeep(userAuthInfo?.preferences?.preferences) || [];
  const oldPref = savedPref.find(p => p.module === REMEMBERED_EVT_PREFERENCE_MODULE_KEY);

  if (oldPref) {
    oldPref.settings = updatedGlobalSettings;
  } else {
    savedPref.push({ module: REMEMBERED_EVT_PREFERENCE_MODULE_KEY, settings: updatedGlobalSettings });
  }
  const postBody = { preferences: savedPref };
  const requestOptions = { method: 'POST', headers: myHeaders, body: JSON.stringify(postBody) };
  return fetch(NEW_API_BASE + '/user/preferences', requestOptions).then(response => response.json()).then((resp: IResponse<any>) => {
    if (resp.error) {
      throw resp.error
    }
    return postBody
  }).catch((error) => {
    console.log('Error in Rubicon Settings:', error);
  })
}
export default EventSelector;

