import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from 'config';
import EventSelector from './EventSelector';
import { RootState } from 'redux/store';
import Icon from 'components/svg';
import DriverSelector from 'components/Common/driverSelection';
import './header.css';
import Replayer from './Replayer';
import FlagIndicator from './FlagIndicator';
import DriversStatusInfo from 'components/DriversStatusInfo';
import useWindowZoom from 'utils/useWindowZoom';

const SINGLE_SELECTOR_PAGES = ['/energy-management', '/quali-planner'];

export default function Header({ isPopUp }: any) {
  const navigate = useNavigate();
  const windowZoom = useWindowZoom();

  const userInfo = useSelector((state: RootState) => state.auth.user);
  const isSelectedCurrentEvent = useSelector((state: RootState) => state.global.selectedEvent?.isCurrent || false);
  const location = useLocation();
  const onLogoClick = () => {
    navigate('/');
  };

  const onLogoutClick = () => {
    navigate('/logout');
  };

  if (!userInfo?.cipher) return null;

  return (
    <header className="shadow py-1 px-2 desktop:px-4" style={{ zoom: windowZoom }}>
      <div className="flex items-center flex-row gap-1 desktop:gap-4">
        <div className="flex items-center justify-center h-6" onClick={onLogoClick}>
          <img src={`${config.BASE_PATH}/images/wing-logo.svg`} alt="Logo" className="h-full min-w-min" />
          <img src={`${config.BASE_PATH}/images/mahindra.svg`} alt="Logo" className="h-1/2 pl-2 hidden laptop:block" />
        </div>
        {!isPopUp && (<DriverSelector isRadioButton={SINGLE_SELECTOR_PAGES.includes(location.pathname)} onDriversSelectionChange={() => { }} parentName="global" className={"justify-center"} />)}
        <div className="flex justify-start min-w-fit">
          <EventSelector isPopup={isPopUp} />
        </div>
        <div className='grow'>
          {isSelectedCurrentEvent ? <DriversStatusInfo isPopUp={isPopUp} /> : <Replayer isPopUp={isPopUp} />}
        </div>
        <div className="flex">
          {!isPopUp && (<FlagIndicator />)}
          <div className="flex flex-row items-center">
            {userInfo?.raw?.['profilePic'] && !isPopUp ?
              <img
                src={`data:image/png;base64, ${userInfo?.raw?.['profilePic']}`}
                className="h-10 w-10 bg-gray-200 border rounded-full"
                style={{ maxWidth: '60px' }}
                alt={userInfo?.raw?.displayName}
              /> : null}
            {!isPopUp && (<span className="flex flex-col ml-2">
              <span className="truncate font-semibold tracking-wide leading-none">{userInfo?.raw?.displayName}</span>
            </span>)}
            <span role="button" className="ml-2 logout-btn" onClick={onLogoutClick}>
              {!isPopUp && (<Icon type="Power" />)}
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};
