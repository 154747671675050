import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import MENUS from 'config/compo-config';
import { RootState } from 'redux/store';
import Collapse from './collapse-menu';
import Icon from 'components/svg';
import './nav.css';
import { IComponent } from '@/types';
import Search from 'components/Search/Search';
import classNames from 'classnames';

interface NavPropTypes {
  isMenuOpen: boolean;
  isSearching: boolean;
  setisSearching: React.Dispatch<React.SetStateAction<boolean>>;
  handleMenuStateChange: React.MouseEventHandler<HTMLButtonElement>;
}

const Nav: React.FC<NavPropTypes> = ({ isMenuOpen, handleMenuStateChange, isSearching, setisSearching }) => {
  let location = useLocation();
  const userAuthInfo = useSelector((state: RootState) => state.global.userAuthData);
  const userInfo = useSelector((state: RootState) => state.auth.user);
  let _menus = cloneDeep(MENUS.filter(m => m.hideInMainNav !== true));
  const [isCtrlSPressed, setIsCtrlSPressed] = useState(false);
  const [foundItems, setFoundItems] = useState<IComponent[]>([]);
  const [value, setValue] = useState<string>('');
  const [isHoverOnMenu, setIsHoverOnMenu] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onMouseEnter = useCallback(() => {
    setIsHoverOnMenu(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsHoverOnMenu(false);
  }, []);

  useEffect(() => {
    let isToggle = false
    const handleKeyDown = (event: any) => {
      // Check if Ctrl and S keys are pressed simultaneously
      if (event.ctrlKey && (event.key === 's' || event.key === 'S')) {
        setIsCtrlSPressed(true);
        event.preventDefault();
      }
      if (event.ctrlKey && (event.key === 'm' || event.key === 'M')) {
        if (isToggle) {
          window.history.back()
        } else {
          window.history.forward()
        }
        isToggle = !isToggle
      }
    };

    // Add event listeners
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listeners on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (isCtrlSPressed) {
      setisSearching(true)
    }
  }, [isCtrlSPressed, setisSearching])

  _menus = _menus.filter(menu => {
    if (menu.subMenu) {
      const _subMenu = menu.subMenu.filter(m => userAuthInfo?.pages?.includes(m.toUpperCase()));
      if (_subMenu.length) {
        menu.subMenu = _subMenu;
        return true;
      }
    }
    return userAuthInfo?.pages?.includes(menu.key.toUpperCase());
  });

  useEffect(() => {
    if (isSearching) {
      inputRef?.current?.focus();
    } else {
      setIsCtrlSPressed(false)
    }
  }, [isSearching]);

  return userInfo?.cipher && userAuthInfo?.user ? (
    <>
      <aside className={classNames("dark:bg-black w-24 transition-all duration-500 ease-in-out", { 'z-40 absolute opacity-0': !isMenuOpen, '!opacity-100': !isMenuOpen && (isHoverOnMenu || isSearching) })} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className={`overflow-visible flex relative z-50`}>
          <SearchButton isSearching={isSearching} setisSearching={setisSearching} setValue={setValue} />
          <div className={`overflow-hidden flex justify-start items-center transition-all duration-500 ease-in-out ${isSearching ? 'min-w-[36rem]' : 'min-w-0'}`}>
            <Search setFoundItems={setFoundItems} value={value} setValue={setValue} inputRef={inputRef} />
          </div>
        </div>
        <div className="pt-2 pb-24 overflow-y-auto overflow-x-clip h-[calc(100vh-78px)] laptop:h-[calc(100vh-98px)] rub-main-side-nav">
          <ul className="flex flex-col w-full">
            {foundItems.length === 0 && _menus.map(({ key, label, href, icon, isExternal, subMenu, grouped, isSearch }) => {
              if (subMenu) {
                let activeSuperMenu = subMenu.find(subKey => _menus.find(({ key }) => subKey === key)?.href === location.pathname);
                return <Collapse label={label} icon={icon} key={key} _key={key} title={label} activeSuperMenu={activeSuperMenu}>
                  {subMenu.map((subKey) => {
                    let foundIndex = _menus.findIndex((item) => item.key === subKey);
                    _menus[foundIndex].grouped = true;
                    const { key, label, href, icon, isExternal } = _menus[foundIndex];
                    return NavElem({ key, label, href, icon, isExternal });
                  })}
                </Collapse>;
              } else {
                if (grouped) {
                  return null; //it is a submenu already grouped under a super-menu
                }
                return NavElem({ key, label, href, icon, isExternal });
              }
            })}
            {foundItems.length !== 0 && foundItems.map(({ key, label, href, icon, isExternal, subMenu, grouped, isSearch }: any) => {
              if (subMenu) {
                let activeSuperMenu = subMenu.find((subKey: any) => _menus.find(({ key }) => subKey === key)?.href === location.pathname);
                return <Collapse label={label} icon={icon} key={key} _key={key} title={label} activeSuperMenu={activeSuperMenu}>
                  {subMenu.map((subKey: any) => {
                    let foundIndex = _menus.findIndex((item) => {
                      return item.key === subKey;
                    });
                    _menus[foundIndex].grouped = true;
                    const { key, label, href, icon, isExternal } = _menus[foundIndex];
                    return NavElem({ key, label, href, icon, isExternal });
                  })}
                </Collapse>;
              } else {
                if (grouped) {
                  return null; //it is a submenu already grouped under a super-menu
                }
                return NavElem({ key, label, href, icon, isExternal });
              }
            })}
          </ul>
        </div>
        <div className='w-24 pt-2 pb-1 flex flex-col justify-center items-center dark:bg-black absolute bottom-0 left-0 z-50'>
          <button className='outline-none flex flex-col justify-center items-center' title={isMenuOpen ? 'Hide' : 'Show'} onClick={handleMenuStateChange}>
            <Icon type={isMenuOpen ? 'WindowCollapseLeft' : 'PinList'} className="w-8 h-8" />
          </button>
          <VersionNumber />
        </div>
      </aside>
    </>
  ) : null;
}

export default Nav;

const VersionNumber = () => {
  const [version, setVersion] = useState('');

  useEffect(() => {
    fetch('./version.json')
      .then(response => response.json())
      .then(data => setVersion(data.version))
      .catch(error => {
        // console.error('Error fetching version:', error);
      });
  }, []);

  return version ? <span className='text-gray-400 text-xs font-bold font-mono'>{version}</span> : null;
}

function SearchButton({ isSearching, setisSearching, setValue }: { isSearching: boolean, setisSearching: React.Dispatch<React.SetStateAction<boolean>>, setValue: React.Dispatch<React.SetStateAction<string>> }) {
  return (
    <span className={classNames("nav-link min-w-full", { "active": isSearching })} title="Search">
      <div className="side-item flex flex-col align-middle justify-center text-center" onClick={() => { setisSearching(!isSearching); setValue('') }}>
        <Icon type="Search" className="w-4 h-4 3xl:w-6 3xl:h-6" />
        <span className="text-xs select-none">Search</span>
      </div>
    </span>
  );
};

function NavElem({ key, label, href = '/', icon, isExternal }: IComponent) {
  const innerElem = (
    <div className="side-item flex flex-col align-middle justify-center text-center">
      <Icon type={icon.type} className="w-4 h-4 3xl:w-6 3xl:h-6" />
      <span className="text-xs select-none">{label}</span>
    </div>
  );

  return isExternal ? (
    <a href={href} key={key} data-key={key} className="nav-link" title={label} target="_blank" rel="noreferrer">{innerElem}</a>
  ) : (
    <NavLink to={href} key={key} data-key={key} className="nav-link" title={label} end={href === '/'}>{innerElem}</NavLink>
  );
}