import React from "react";
import { useSelector } from "react-redux";
import { FlagIcon, ClockIcon } from "@heroicons/react/24/outline";
import config from "config";
import { getDisplayHMSTime } from "common";
import { RootState } from "redux/store";
import "./flags-timeline.css";

let DRIVER_ID;
if (!DRIVER_ID) DRIVER_ID = "29";

function FlagTimeline() {
  const flagMessages = useSelector(
    (state: RootState) => state.global.flagMessages
  );

  return (
    <div>
      <h4 className="font-bold border-b border-gray-600 mb-2 pb-2">Live flags</h4>
      <div className="flex flex-wrap justify-center h-40 mx-0 py-2 overflow-y-auto">
        {flagMessages.map(({ type, sector, ts }) => {
          const bgColorCls = config.FLAG_COLOR_MAP[type];
          const cName = "inner-circle " + bgColorCls;
          return (
            <div
              className="flex flex-col items-center text-center m-2 mx-4 space-y-2"
              key={ts}
            >
              <div className={`w-6 h-6 rounded-full ${cName}`}></div>
              <div className="text-sm inline-flex items-center">
                <FlagIcon className="w-4 h-4 mr-1" />{" "}
                <span>
                  {type} | Sector: {sector}
                </span>
              </div>
              <p className="text-sm text-gray-500 inline-flex items-center">
                <ClockIcon className="w-4 h-4 mr-1" />{" "}
                {getDisplayHMSTime(ts, "HH:mm:ss")}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FlagTimeline;
