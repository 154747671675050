import { useLayoutEffect, useMemo, useState } from "react";
import { useTextToSpeech } from "common";
import { INotificationMsg } from "worker/notificationsScript";
import { ISessionDrivers } from "@mahindraformulae/rubicon-hooks";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import useLeaderTab from "utils/useLeaderTab";

const SPEAK_MSGS_MINUTES = 1 * 60 * 1000;
const PREFERENCE_MODULE_KEY = 'global';
const DEFAULT_VOLUME = 0.08;

const useAudioNotifications = (messages: INotificationMsg[], driverMap: ISessionDrivers | null) => {
  const [spokenMsgs, setSpokenMsgs] = useState<string[]>([]);
  const newMsgSound = useMemo<HTMLAudioElement>(() => new Audio('/rubicon/sounds/alert_sound.wav'), []);
  const userAuthInfo = useSelector((state: RootState) => state.global.userAuthData);
  const globalSettings = useMemo(() => userAuthInfo?.preferences?.preferences?.find((pref) => pref.module === PREFERENCE_MODULE_KEY)?.settings || { useAudioAlert: false }, [userAuthInfo]);  
  const { speak, speaking, stopSpeaking } = useTextToSpeech({ voice: "Google US English", volume: DEFAULT_VOLUME });
  const isLeader = useLeaderTab();

  const result = useMemo(() => ([
    (isLeader && globalSettings?.useAudioAlert)
  ]), [isLeader, globalSettings?.useAudioAlert]);

  useLayoutEffect(() => {
    if((!globalSettings?.useAudioAlert || !isLeader) && speaking) stopSpeaking();
  }, [globalSettings?.useAudioAlert, speaking, stopSpeaking, isLeader]);

  const pendingMsgsToSpeak = useMemo(() => {
    if(!globalSettings?.useAudioAlert || !isLeader) return [];
    let momentNow = moment.now();
    return messages.filter((msg) => !spokenMsgs.includes(msg.id) && (momentNow - msg.dayTime < SPEAK_MSGS_MINUTES));
  }, [messages, spokenMsgs, globalSettings, isLeader]);

  useLayoutEffect(() => {
    if(!globalSettings?.useAudioAlert || !isLeader) return;
    if(!document.title.endsWith('🔊')) document.title += " 🔊";
    return () => {
      if (document.title.endsWith('🔊')) {
        document.title = document.title.replace(/^🔊/, '').trim();
      }
    }
  }, [globalSettings?.useAudioAlert, isLeader]);

  useLayoutEffect(() => {
    if(globalSettings?.useAudioAlert && pendingMsgsToSpeak?.length && isLeader) {
      let msgResult = pendingMsgsToSpeak.reduce((acc = { text: [], msgIds: [] }, msg) => {
        if (msg.text) {
          let driver = driverMap?.[msg.car];
          let driverName = driver?.lastName ? `${driver.lastName.toLocaleLowerCase()}: ` : '';
          let msgText = `${driverName}${msg.text}`.replace(/([A-Z])(?=[A-Z])/g, '$1.');
          acc.text.push(msgText);
        };
        acc.msgIds.push(msg.id);
        return acc;
      }, { text: [], msgIds: [] } as Record<"text"|"msgIds", string[]>);
  
      if(msgResult.msgIds.length) {
        setSpokenMsgs((prevSpokenMsgs = []) => prevSpokenMsgs.concat(msgResult.msgIds));
      }

      if(msgResult.text.length) {
        let speakNewMsgs = () => {
          msgResult.text.map((text) => speak(text));
          newMsgSound.removeEventListener('ended', speakNewMsgs);
        };
        if(newMsgSound) {
          newMsgSound.volume = DEFAULT_VOLUME;
          newMsgSound.play();
          newMsgSound.addEventListener('ended', speakNewMsgs);
        } else {
          setTimeout(speakNewMsgs, 2 * 1000);
        }
      }
    }
  }, [speak, setSpokenMsgs, newMsgSound, pendingMsgsToSpeak, speaking, driverMap, globalSettings?.useAudioAlert, isLeader]);

  return result;
}

export default useAudioNotifications;