import { useState } from "react";
import { CSVLink } from "react-csv";
import { useDownloadExcel } from "react-export-table-to-excel";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

import { IMRTableColumn } from "./MRTable";

import "./ExportDropDown.css";

const ExportDropDown = ({ rowsData, columnsData, tableRef }: PropsType) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const csvData = rowsData.map((r: any) => {
    const obj: any = {};
    columnsData.forEach((c) => {
      obj[c.selector] = r[c.selector]?.value;
    });
    return obj;
  });

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={toggleDropdown}
        type="button"
        className="px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-darker focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75"
      >
        <ArrowDownTrayIcon />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg w-20">
          <div className="py-1">
            <span className="ep-dp-m-item">
              <CSVLink
                headers={columnsData.map((c) => ({
                  label: c.name,
                  key: c.selector,
                }))}
                data={csvData}
                onClick={() => setIsOpen(false)}
              >
                CSV
              </CSVLink>
            </span>
            <span className="ep-dp-m-item">
              <button
                onClick={() => {
                  onDownload();
                  setIsOpen(false);
                }}
              >
                Excel
              </button>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportDropDown;

interface PropsType {
  rowsData: any[];
  columnsData: IMRTableColumn[];
  tableRef: any;
}
